import { AnyAction, configureStore, Middleware } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import api from 'redux/API';
import openStreetMap from 'redux/API/openstreetmap';
import authenticationReducer, { AuthenticationState } from 'redux/authenticationReducer';

export interface ApplicationState {
  readonly authentication: AuthenticationState;
}

export interface APIs {
  readonly [api.reducerPath]: any;
  readonly [openStreetMap.reducerPath]: any;
}

const store = configureStore<
  ApplicationState & APIs,
  AnyAction,
  ReadonlyArray<Middleware<{}, ApplicationState & APIs>>
>({
  reducer: {
    authentication: authenticationReducer,
    // Redux toolkit such a stupid thing
    [api.reducerPath]: api.reducer,
    [openStreetMap.reducerPath]: openStreetMap.reducer,
  },
  middleware: (
    getDefaultMiddleware: CurriedGetDefaultMiddleware<ApplicationState & APIs>,
  ): ReadonlyArray<Middleware<{}, ApplicationState & APIs>> =>
    getDefaultMiddleware().concat(api.middleware).concat(openStreetMap.middleware),
});

export default store;
