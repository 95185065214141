import { ExpandMore, InfoOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { Procedure } from '@s3comsecurity/user-configuration';
import classes from 'components/ApplicationMenu/components/CardMenu/cardMenu.module.scss';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  readonly item: Procedure;
  readonly url: string;
}

const Title: React.FC<{ readonly text: string }> = (props: {
  readonly text: string;
}): React.ReactElement => {
  const { text } = props;

  return (
    <Box mb={0.5}>
      <Typography variant="subtitle1" fontSize={20} lineHeight={1.25} className={classes.title}>
        {text}
      </Typography>
    </Box>
  );
};

const CardMenu: React.FC<Props> = (props: Props): React.ReactElement => {
  const { item, url } = props;

  const handleExpand = React.useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Card sx={styles.card} component={NavLink} to={url}>
      <CardHeader
        avatar={<Avatar sx={styles.avatar}>{item.etiqueta[0]}</Avatar>}
        title={<Title text={item.etiqueta} />}
        subheader="0 solicitudes"
        sx={styles.cardHeader}
      />
      <CardContent sx={styles.cardContent}>
        <InfoOutlined color="info" />
        <Typography variant="body2" className={classes.paragraph} sx={styles.description}>
          {item.nombre}
        </Typography>
      </CardContent>
      <CardActions disableSpacing={true}>
        <IconButton sx={styles.expandMore} onClickCapture={handleExpand}>
          <ExpandMore />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default CardMenu;

const styles: Record<string, SxProps<Theme>> = {
  card: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'stretch',
    flexDirection: 'column',
    height: 260,
    border: 'none',
    width: '100%',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  cardHeader: {
    alignItems: 'flex-start',
    height: 110,
    backgroundColor: 'info.dark',
    color: 'primary.contrastText',
  },
  cardContent: {
    display: 'flex',
    gap: 1,
    flex: 1,
    color: 'text.primary',
    opacity: 0.8,
  },
  avatar: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
  description: {
    overflow: 'hidden',
    fontSize: {
      xs: '80%',
      sm: '90%',
      md: '100%',
    },
  },
  expandMore: {
    marginLeft: 'auto',
  },
};
