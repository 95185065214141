export interface Page<T> {
  readonly elementos: readonly T[];
  readonly pagina: number;
  readonly total: number;
  readonly totalPagina: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class Page<T> {
  public static empty<T>(): Page<T> {
    return {
      elementos: [],
      pagina: 1,
      totalPagina: 0,
      total: 0,
    };
  }
}
