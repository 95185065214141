import { StepProps } from 'components/Stepper/stepProps';
import React from 'react';

type Props<T> = StepProps<T>;

function Step<T>(_: Props<T>): React.ReactElement {
  return <></>;
}

export default Step;
