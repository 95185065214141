import { Box } from '@mui/material';
import RequestsTable from 'components/ServicesCollectionsForms/RequestsTable';
import React from 'react';

const RequestsOverview: React.FC = (): React.ReactElement => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      height="100%"
      borderTop="1px solid"
      borderColor="secondary.light"
    >
      <RequestsTable />
    </Box>
  );
};

export default RequestsOverview;
