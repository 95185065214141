import classes from 'components/DocumentPreview/documentPreview.module.scss';
import Spinner from 'components/Spinner';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useSelector } from 'react-redux';
import { authenticationSelector } from 'redux/authenticationReducer';

interface Props {
  readonly url: string;
  readonly name: string;
}

const DocumentPreview: React.FC<Props> = (props: Props): React.ReactElement => {
  const [root, setRoot] = React.useState<ReactDOM.Root | null>(null);
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const { token } = useSelector(authenticationSelector);
  const { name, url } = props;

  React.useEffect((): void => {
    if (root === null && container !== null) {
      setRoot(ReactDOM.createRoot(container));
    }
  }, [root, container]);

  const render = React.useCallback(
    (type: string, result: string): void => {
      if (root && type.startsWith('image/')) {
        root.render(<img src={result} alt={name} />);
      } else if (root && type === 'application/pdf') {
        const idealHeight = window.innerHeight - 120;
        const idealWidth = idealHeight / 1.294;

        const style = {
          width: idealWidth,
          height: idealHeight,
        };

        root.render(
          <object data={result} name={name} title={name} type="application/pdf" style={style}>
            No se puede mostrar el PDF
          </object>,
        );
      }
      setLoading(false);
    },
    [name, root],
  );

  React.useEffect((): VoidFunction | void => {
    const controller = new AbortController();
    const delay = 800;

    const execute = async (): Promise<void> => {
      const response: Response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal: controller.signal,
      });
      const start = Date.now();
      const blob = await response.blob();
      await new Promise<void>((resolve: VoidFunction): void => {
        setTimeout(resolve, Math.max(0, delay - (Date.now() - start)));
      });

      const { type } = blob;
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>): void => {
        if (event.target === null) {
          console.warn('Event target is null');
          return;
        } else {
          const { result } = event.target;

          if (typeof result !== 'string') {
            console.warn('bad response from server, expected a data url');
            return;
          }

          render(type, result);
        }
      };

      reader.readAsDataURL(blob);
    };

    execute().catch((error: any): void => {
      if (typeof error === 'string' && error === 'Document preview unmounted') {
        return;
      }

      console.warn(error);
    });

    return (): void => {
      controller.abort('Document preview unmounted');
      setLoading(true);
    };
  }, [token, url, render]);

  React.useEffect((): void => {
    if (!root) {
      return;
    }

    if (loading) {
      root.render(spinner);
    }
  }, [loading, root]);

  return (
    <div ref={setContainer} className={classes.container}>
      {spinner}
    </div>
  );
};

export default DocumentPreview;

const spinner = (
  <div className={classes.spinner}>
    <Spinner spinning={true} size={60} thickness={4} />
  </div>
);
