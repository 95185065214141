import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

type Variant = 'previous' | 'current' | 'next' | 'selected';

interface Props {
  readonly value: number;
  readonly variant: Variant;

  onClick?(value: number): void;
}

const Day: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, variant, onClick } = props;

  const color = React.useMemo((): 'main' | 'secondary' => {
    switch (variant) {
      case 'previous':
      case 'next':
        return 'secondary';
      case 'current':
        return 'main';
      case 'selected':
        return 'main';
    }
  }, [variant]);

  const handleClick = React.useCallback((): void => {
    onClick?.(value);
  }, [onClick, value]);

  return (
    <Grid sx={styles.container} onClick={handleClick} item>
      <Box sx={[styles.content, styles[variant]]}>
        <Typography color={color} align="center" lineHeight="32px">
          {value}
        </Typography>
      </Box>
    </Grid>
  );
};

export default Day;

const styles = {
  container: {
    minWidth: 32,
    height: 32,
  },
  content: {
    width: 32,
    height: 32,
    cursor: 'pointer',
    borderRadius: 16,
  },
  current: {
    '&:hover': {
      backgroundColor: 'secondary.main',
    },
  },
  next: {
    pointerEvents: 'none',
  },
  previous: {
    pointerEvents: 'none',
  },
  selected: {
    color: 'primary.contrastText',
    backgroundColor: 'primary.main',
  },
};
