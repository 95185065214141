import { FormHelperText } from '@mui/material';
import React from 'react';
import { FieldError } from 'types/fieldError';

interface Props {
  readonly error: FieldError | undefined;
}

const FormErrorHelperText: React.FC<Props> = (props: Props): React.ReactElement => {
  const { error } = props;
  if (!error) {
    return <></>;
  }

  return <FormHelperText error={true}>{error.message}</FormHelperText>;
};

export default FormErrorHelperText;
