import { SolicitanteDireccionForm } from 'components/CompleteRegistration/forms/PasosComunes/SolicitanteDireccion/schema';
import { Attribute, AttributeType } from 'components/Form/types';
import { useInmueblesQuery, useLocalidadesQuery } from 'redux/API';

const fields: readonly Attribute<SolicitanteDireccionForm>[] = [
  {
    name: 'idLocalidad',
    label: 'Localidad',
    type: AttributeType.dropdown,
    useQuery: useLocalidadesQuery,
  },
  {
    name: 'nombreLocalidad',
    label: 'Nombre Localidad',
    type: AttributeType.text,
  },
  {
    name: 'idInmueble',
    label: 'Inmueble',
    type: AttributeType.dropdown,
    useQuery: useInmueblesQuery,
  },
  {
    name: 'nombreInmueble',
    label: 'Nombre Inmueble',
    type: AttributeType.text,
  },
];

export default fields;
