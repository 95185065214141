import { Alert, Box } from '@mui/material';
import React from 'react';

interface Props {
  readonly error: any /* Whatever it is, javascript developers don't care */;
}

const AuthErrorBox: React.FC<Props> = (props: Props): React.ReactElement => {
  const { error } = props;
  if (!error) {
    return <></>;
  }

  switch (error.status) {
    case 401:
      return (
        <Box minWidth="100%" marginBottom={2}>
          <Alert severity="error">Credenciales inválidas</Alert>
        </Box>
      );
    case 400:
      return (
        <Box minWidth="100%" marginBottom={2}>
          <Alert severity="error">
            Error desconocido <a href="mailto:soporte@intt.org">Soporte técnico.</a>
          </Alert>
        </Box>
      );
    case 409:
      return (
        <Box minWidth="100%" marginBottom={2}>
          <Alert severity="error">{error.data.error}</Alert>
        </Box>
      );
    default:
      return <></>;
  }
};

export default AuthErrorBox;
