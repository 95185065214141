import { AccountCircle, Logout } from '@mui/icons-material';
import { Box, ButtonBase, Menu, MenuItem, SxProps, Theme, Typography } from '@mui/material';
import { User } from '@s3comsecurity/user-auth';
import ProfileModal from 'components/UserMenu/components/profileModal';
import ProfileTooltip from 'components/UserMenu/components/profileTooltip';
import React from 'react';

interface Props {
  readonly user: User | undefined;

  onLogout(): void;
}

const UserMenu: React.FC<Props> = ({ user, onLogout }: Props): React.ReactElement => {
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const anchorEl = React.useRef<HTMLButtonElement>(null);
  const { current: anchor } = anchorEl;

  const toggleMenu = React.useCallback((): void => {
    setMenuIsOpen((menuIsOpen: boolean): boolean => !menuIsOpen);
  }, []);

  const handleProfileClick = () => {
    setModalOpen(true);
    setMenuIsOpen(false);
  };

  const menuStyle = React.useMemo((): SxProps<Theme> => {
    return {
      width: anchor?.offsetWidth,
    };
  }, [anchor?.offsetWidth]);

  if (!user) {
    return <></>;
  }

  return (
    <Box borderRadius={3}>
      <ButtonBase ref={anchorEl} sx={styles.button} onClick={toggleMenu}>
        <Box textAlign="start">
          {user?.nombre ? (
            <ProfileTooltip>
              <Typography
                color="text.primary"
                fontFamily="Montserrat"
                lineHeight={1.25}
                fontWeight={600}
              >
                {user?.nombre}
              </Typography>
            </ProfileTooltip>
          ) : (
            <Typography
              variant="subtitle1"
              lineHeight={1.25}
              fontWeight={600}
              fontStyle="italic"
              color="text.disabled"
            >
              Registro Incompleto
            </Typography>
          )}
          <Typography color="text.secondary" fontSize={12} lineHeight={1.5}>
            {user?.email}
          </Typography>
        </Box>
      </ButtonBase>
      <Menu
        open={menuIsOpen}
        slotProps={{ paper: { sx: menuStyle } }}
        anchorEl={anchorEl?.current}
        onClose={toggleMenu}
      >
        {user?.nombre && (
          <MenuItem onClick={handleProfileClick}>
            <AccountCircle />
            <Box width="8px" />
            <Typography>Mi Perfil</Typography>
          </MenuItem>
        )}
        <MenuItem sx={styles.logout} onClick={onLogout}>
          <Logout />
          <Box width="8px" />
          <Typography>Cerrar Sessión</Typography>
        </MenuItem>
      </Menu>
      <ProfileModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Box>
  );
};

export default UserMenu;

const styles: Record<string, SxProps<Theme>> = {
  button: {
    display: 'flex',
    padding: '2px 12px',
    gap: 1,
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 1,
    py: 0.5,
    my: 0.5,
    color: 'secondary.light',
  },
  logout: {
    color: 'crimson',
  },
};
