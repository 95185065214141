import { Box, SxProps, Theme } from '@mui/material';
import { Status } from '@s3comsecurity/requests';
import React from 'react';
import { colors, icons } from 'types/requestStatusStaticData';

interface Props {
  readonly status: Status;
  readonly size?: number;
}

const StatusIcon: React.FC<Props> = (props: Props): React.ReactElement => {
  const { status, size = 20 } = props;

  const sx = React.useMemo(
    (): SxProps<Theme> => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: size,
      height: size,
      borderRadius: size / 2,
      backgroundColor: colors[status],
    }),
    [size, status],
  );

  const iconSx = React.useMemo(
    (): SxProps<Theme> => ({
      width: size - 4,
      height: size - 4,
      color: 'white',
    }),
    [size],
  );

  if (!icons[status]) {
    throw new Error(`Invalid status: ${status}`);
  }

  return <Box sx={sx}>{React.cloneElement(icons[status], { sx: iconSx })}</Box>;
};

export default StatusIcon;
