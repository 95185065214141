import * as yup from 'yup';
import { validarIdentificador } from 'yup/validators/validarIdentificador';

export interface SolicitanteJuridicoRepresentanteLegal {
  readonly rif: string;
  readonly nombre: string;
}

export default yup.object<SolicitanteJuridicoRepresentanteLegal>().shape({
  rif: yup
    .string() //
    .test('rifRepresentanteLegal', '', validarIdentificador)
    .required(),
  nombre: yup
    .string() //
    .required('El nombre del representante legal es obligatorio'),
});
