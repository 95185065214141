import {
  isRemoteListAttribute,
  isStaticListAttribute,
  RemoteListAttribute,
  StaticListAttribute,
} from '@s3comsecurity/foundations';
import DropdownWithOptions from 'components/RequestLayout/components/RequestComponentRenderer/components/DropdownWithOptions';
import DropdownWithSource from 'components/RequestLayout/components/RequestComponentRenderer/components/DropdownWithSource';
import { ControlData } from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import React from 'react';

interface Props {
  readonly definition: StaticListAttribute | RemoteListAttribute;
  readonly name: string;
  readonly data?: ControlData;
  readonly defaultValue?: string;
}

const Dropdown: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, definition, data, defaultValue } = props;

  if (isRemoteListAttribute(definition)) {
    const { fuente, parametros } = definition.propiedades;

    return (
      <DropdownWithSource
        name={name}
        source={fuente}
        parametros={parametros}
        data={data}
        defaultValue={defaultValue}
      />
    );
  } else if (isStaticListAttribute(definition)) {
    const { opciones: items } = definition.propiedades;

    return (
      <DropdownWithOptions name={name} items={items} data={data} defaultValue={defaultValue} />
    );
  } else {
    throw new Error('Invalid definition for Dropdown');
  }
};

export default Dropdown;
