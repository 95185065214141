import { Box, Typography } from '@mui/material';
import { Status } from '@s3comsecurity/requests';
import StatusIcon from 'components/ServicesCollectionsForms/RequestsTable/StatusIcon';
import React from 'react';
import { labels } from 'types/requestStatusStaticData';

const StatusLegend: React.FC = (): React.ReactElement => {
  return (
    <Box sx={styles.container}>
      {Object.values(Status).map(
        (status: Status): React.ReactElement => (
          <Box key={status} display="flex" alignItems="center" gap={0.75} mr={4}>
            <StatusIcon size={24} status={status} />
            <Typography variant="body2">{labels[status]}</Typography>
          </Box>
        ),
      )}
    </Box>
  );
};

export default StatusLegend;

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
};
