import { Step } from '@s3comsecurity/user-configuration';
import React from 'react';

export interface StepInformation {
  readonly previousStepId: number | null;
  readonly currentStepId: number | null;
  readonly nextStepId: number | null;
  readonly currentStepIndex: number;
}

export const useStepInformation = (
  steps: readonly Step[] | null,
  stepId?: number,
): StepInformation => {
  const nonNullSteps = React.useMemo((): readonly Step[] => steps ?? [], [steps]);

  const currentStepId = React.useMemo(
    (): number => stepId ?? nonNullSteps[0]?.id ?? null,
    [stepId, nonNullSteps],
  );

  const currentStepIndex = React.useMemo(
    (): number => nonNullSteps.findIndex((step: Step): boolean => step.id === currentStepId),
    [nonNullSteps, currentStepId],
  );

  const previousStepId = React.useMemo(
    (): number | null => (currentStepIndex > 0 ? nonNullSteps[currentStepIndex - 1].id : null),
    [currentStepIndex, nonNullSteps],
  );

  const nextStepId = React.useMemo(
    (): number | null =>
      currentStepIndex < nonNullSteps.length - 1 ? nonNullSteps[currentStepIndex + 1].id : null,
    [currentStepIndex, nonNullSteps],
  );

  return { previousStepId, currentStepId, nextStepId, currentStepIndex };
};
