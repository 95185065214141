import { Box, FormLabel, OutlinedInput, SxProps, Theme } from '@mui/material';
import { AttributeType } from '@s3comsecurity/foundations';
import LocationPicker, { GeographicalLocation } from 'components/LocationPicker';
import MapMarker from 'components/MapMarker';
import MapEvents from 'components/MapMarker/events';
import Spinner from 'components/Spinner';
import { MapControllerProvider } from 'context/map';
import { defaultCenter, useMapControllerProvider } from 'hooks/useMapController';
import { FitBoundsOptions, LatLngBoundsLiteral, LatLngLiteral } from 'leaflet';
import React, { CSSProperties } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useGetParroquiaQuery } from 'redux/API';

export enum MapComponentFeatures {
  none = 0,
  locationInput = 2,
  all = MapComponentFeatures.none | MapComponentFeatures.locationInput,
}

interface Props {
  readonly defaultValues: any;
  readonly orientation: 'vertical' | 'horizontal';
  readonly features?: MapComponentFeatures;
}

const MapComponent: React.FC<Props> = (props: Props): React.ReactElement => {
  const { defaultValues, orientation, features = MapComponentFeatures.all } = props;

  const [mapState, mapController] = useMapControllerProvider();
  const [lat, setLat] = React.useState<string>('');
  const [lng, setLng] = React.useState<string>('');
  const [currentLocation, setCurrentLocation] = React.useState<GeographicalLocation>(
    GeographicalLocation.none(),
  );
  const { parishId, cityId } = currentLocation;

  const initialLocation = React.useMemo((): {
    readonly idParroquia: number;
    readonly idCiudad: number;
  } => {
    try {
      return defaultValues;
    } catch {
      return { idParroquia: 0, idCiudad: 0 };
    }
  }, [defaultValues]);

  const {
    data: selectedParish = { idEstado: 0, idMunicipio: 0 },
    isFetching: fetchingInitialLocation,
    isSuccess,
  } = useGetParroquiaQuery(
    {
      idParroquia: initialLocation.idParroquia,
    },
    {
      skip: initialLocation.idParroquia === 0,
    },
  );

  React.useEffect((): void => {
    if (isSuccess) {
      setCurrentLocation({
        stateId: selectedParish.idEstado,
        municipalityId: selectedParish.idMunicipio,
        parishId: initialLocation.idParroquia,
        cityId: initialLocation.idCiudad,
      });
    }
  }, [
    initialLocation.idCiudad,
    initialLocation.idParroquia,
    isSuccess,
    selectedParish.idEstado,
    selectedParish.idMunicipio,
  ]);

  const handleMapClick = React.useCallback((latitude: number, longitude: number): void => {
    setLat(latitude.toFixed(9));
    setLng(longitude.toFixed(9));
  }, []);

  const handleLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const latValue = Number(value + '0');
    if (!isNaN(latValue)) {
      setLat(value);
    }
  };

  const handleLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const lngValue = Number(value + '0');
    if (!isNaN(lngValue)) {
      setLng(value);
    }
  };

  const marker = React.useMemo((): LatLngLiteral => {
    const numericLat = Number(lat);
    const numericLng = Number(lng);

    if (isNaN(numericLat) && isNaN(numericLng)) {
      return { lat: defaultCenter[0], lng: defaultCenter[1] };
    } else if (isNaN(numericLat)) {
      return { lat: defaultCenter[0], lng: numericLng };
    } else if (isNaN(numericLng)) {
      return { lat: numericLat, lng: defaultCenter[1] };
    } else {
      return { lat: numericLat, lng: numericLng };
    }
  }, [lat, lng]);

  const bounds = React.useMemo((): LatLngBoundsLiteral => {
    const { boundingBox } = mapState;
    if (!boundingBox) {
      return [];
    }

    return [
      [boundingBox[0], boundingBox[2]],
      [boundingBox[1], boundingBox[3]],
    ];
  }, [mapState]);

  React.useEffect((): void => {
    const latStr = defaultValues['latitud'];
    const lngStr = defaultValues['longitud'];
    if (!latStr || !lngStr) {
      return;
    }
    setLat(latStr);
    setLng(lngStr);

    const lat = Number(latStr);
    const lng = Number(lngStr);

    setTimeout((): void => {
      mapController.setBoundingBox([
        (lat - 0.002).toPrecision(10),
        (lat + 0.002).toPrecision(10),
        (lng - 0.002).toPrecision(10),
        (lng + 0.002).toPrecision(10),
      ]);
    }, 0);
  }, [defaultValues, mapController]);

  const handleLocationChange = React.useCallback(
    (key: keyof GeographicalLocation, value: number): void => {
      setLat('');
      setLng('');
      setCurrentLocation((previousLocation: GeographicalLocation): GeographicalLocation => {
        switch (key) {
          case 'stateId':
            return {
              stateId: value,
              municipalityId: 0,
              parishId: 0,
              cityId: 0,
            };
          case 'municipalityId':
            return {
              ...previousLocation,
              municipalityId: value,
              parishId: 0,
            };
          case 'parishId':
            return {
              ...previousLocation,
              parishId: value,
            };
          case 'cityId':
            return {
              ...previousLocation,
              cityId: value,
            };
        }
      });
    },
    [],
  );

  const handleBoundingBoxChange = React.useCallback(
    (boundingBox: readonly string[]): void => {
      mapController.setBoundingBox(boundingBox);
    },
    [mapController],
  );

  const mapWrapperStyle = React.useMemo(
    (): SxProps<Theme> =>
      orientation === 'vertical' ? styles.mapWrapperVertical : styles.mapWrapperHorizontal,
    [orientation],
  );

  const containerStyle = React.useMemo(
    (): SxProps<Theme> =>
      orientation === 'vertical' ? styles.containerVertical : styles.containerHorizontal,
    [orientation],
  );

  const formWrapperStyle = React.useMemo(
    (): SxProps<Theme> =>
      orientation === 'vertical'
        ? styles.formWrapperVerticalStyle
        : styles.formWrapperHorizontalStyle,
    [orientation],
  );

  return (
    <MapControllerProvider controller={mapController}>
      <input name="latitud" type="hidden" value={lat} data-type={AttributeType.numeric} />
      <input name="longitud" type="hidden" value={lng} data-type={AttributeType.numeric} />
      <input type="hidden" name="idParroquia" value={parishId} data-type={AttributeType.numeric} />
      <input type="hidden" name="idCiudad" value={cityId} data-type={AttributeType.numeric} />

      <Box sx={containerStyle}>
        <Box sx={formWrapperStyle}>
          <LocationPicker
            stateId={currentLocation.stateId}
            municipalityId={currentLocation.municipalityId}
            parishId={currentLocation.parishId}
            cityId={currentLocation.cityId}
            onBoundingBoxChange={handleBoundingBoxChange}
            onChange={handleLocationChange}
          />
          {(features & MapComponentFeatures.locationInput) ===
            MapComponentFeatures.locationInput && (
            <Box display="flex" gap={1} mt={1} ml={0.25} mr={0.75}>
              <Box flex={1}>
                <FormLabel>Latitud</FormLabel>
                {/* FIXME: use a numeric input here */}
                <OutlinedInput value={lat} fullWidth={true} onChange={handleLatitudeChange} />
              </Box>
              <Box flex={1}>
                <FormLabel>Longitud</FormLabel>
                {/* FIXME: use a numeric input here */}
                <OutlinedInput value={lng} fullWidth={true} onChange={handleLongitudeChange} />
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={mapWrapperStyle}>
          <MapContainer
            key={bounds.toString()}
            style={mapContainerStyle}
            bounds={bounds}
            boundsOptions={boundsOptions}
            attributionControl={false}
            doubleClickZoom={false}
          >
            <TileLayer
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution={attribution}
            />
            <MapEvents onClick={handleMapClick} />
            <MapMarker position={marker} />
          </MapContainer>
        </Box>

        <Spinner spinning={fetchingInitialLocation} color="primary" size={64} thickness={5} />
      </Box>
    </MapControllerProvider>
  );
};

export default MapComponent;

const attribution =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

const styles: Record<string, SxProps<Theme>> = {
  containerVertical: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    gap: 1,
  },
  containerHorizontal: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    gap: 1,
  },
  mapWrapperVertical: {
    flex: 1,
    width: '100%',
    marginTop: 1,
    borderRadius: 2,
    mx: 1.5,
    mt: 1,
    overflow: 'hidden',
    zIndex: 0,
  },
  mapWrapperHorizontal: {
    flex: 2,
    height: '100%',
    marginTop: 1,
    borderRadius: 2,
    mx: 1.5,
    mt: 0,
    overflow: 'hidden',
    zIndex: 0,
  },
  formWrapperVerticalStyle: {
    margin: 0,
    flex: 1,
    pt: 1,
    px: 1.25,
    mx: 0.25,
    borderRadius: 2,
  },
  formWrapperHorizontalStyle: {
    margin: 0,
    flex: 1,
    pt: 1,
    px: 1.25,
    mx: 0.25,
    borderRadius: 2,
  },
};

const mapContainerStyle: CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

const boundsOptions: FitBoundsOptions = {
  padding: [0, 0],
};
