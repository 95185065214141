import { ArrowRight, CloseOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, SxProps, Theme, Typography } from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { Request, Status } from '@s3comsecurity/requests';
import Render from 'components/Render';
import formatearFecha from 'components/ServicesCollectionsForms/RequestsTable/formatearFecha';
import RequestStatus from 'components/ServicesCollectionsForms/RequestsTable/RequestStatus';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useCancelRequestMutation } from 'redux/API';

interface Props {
  readonly solicitud: Request;
}

const RequestItemHeader: React.FC<Props> = (props: Props): React.ReactElement => {
  const [cancel, cancelMutation] = useCancelRequestMutation();
  const { solicitud } = props;

  const linkUrl = React.useMemo((): string => {
    return `/tramites/${solicitud.idGrupoTramites}/${solicitud.idTramite}/${solicitud.id}`;
  }, [solicitud.id, solicitud.idGrupoTramites, solicitud.idTramite]);

  const cancelRequest = React.useCallback(
    (event: React.MouseEvent): void => {
      event.stopPropagation();
      event.preventDefault();

      cancel(solicitud.id);
      return;
    },
    [cancel, solicitud.id],
  );

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box flex={1}>
        <Typography fontSize={16} fontWeight="medium" lineHeight="20px">
          {solicitud.tramite}
        </Typography>
        <Typography fontSize={13} color="text.secondary" fontWeight={500} lineHeight="20px">
          {solicitud.id} &mdash; {formatearFecha(solicitud.fecha)}
        </Typography>
      </Box>

      <Render when={solicitud.estatus === Status.running}>
        <Box display="flex" alignItems="center" justifyContent="flex-end" mx={4}>
          <Button
            sx={styles.cancelButton}
            size="small"
            variant="outlined"
            color="error"
            startIcon={<CloseOutlined />}
            disabled={cancelMutation.status === QueryStatus.pending}
            onClick={cancelRequest}
          >
            Cancelar
          </Button>
          <Button
            sx={styles.continueButton}
            component={NavLink}
            size="small"
            variant="outlined"
            color="success"
            endIcon={<ArrowRight />}
            disabled={cancelMutation.status === QueryStatus.pending}
            to={linkUrl}
          >
            Siguiente
          </Button>
        </Box>
      </Render>
      <Render when={cancelMutation.status === QueryStatus.pending}>
        <CircularProgress size={20} />
      </Render>

      <Box ml="auto">
        <RequestStatus status={solicitud.estatus} />
      </Box>
    </Box>
  );
};

const styles: Record<string, SxProps<Theme>> = {
  cancelButton: {
    borderRadius: 8,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    mr: '-1px',
  },
  continueButton: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

export default RequestItemHeader;
