import { TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Enumerable } from '@s3comsecurity/foundations';
import React from 'react';
import { DropdownItem } from 'types/dropdownItem';

export type FormControl = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | Element;

export enum AttributeType {
  text,
  rif,
  email,
  dropdown,
  date,
  checkbox,
  custom,
}

export interface BaseField<T> {
  readonly type: AttributeType;
  readonly name: keyof T;
  readonly help?: string;

  readonly columnSpan?: number;
  readonly required?: boolean;
}

export interface TextField<T> extends BaseField<T> {
  readonly type: AttributeType.text | AttributeType.rif | AttributeType.email;
  readonly label: string;
}

export interface DateField<T> extends BaseField<T> {
  readonly type: AttributeType.date;
  readonly label: string;
}

export interface BooleanField<T> extends BaseField<T> {
  readonly type: AttributeType.checkbox;
  readonly label: string;
}

export interface CustomField<T> extends BaseField<T> {
  readonly type: AttributeType.custom;
}

interface DropdownField<T> extends BaseField<T> {
  readonly type: AttributeType.dropdown;
  readonly label: string;
}

export interface DropdownFieldWithStaticData<T> extends DropdownField<T> {
  readonly data: readonly DropdownItem[];
}

export interface DropdownFieldWithFetchHook<T> extends DropdownField<T> {
  useQuery(): TypedUseQueryHookResult<readonly Enumerable[], void, any>;
}

export interface CustomFieldWithRenderFunction<T> extends CustomField<T> {
  render(name: keyof T): React.ReactElement | React.ReactNode;
}

export interface CustomFieldWithElement<T> extends CustomField<T> {
  readonly element: React.ReactElement;
}

export interface CustomFieldWithComponent<T> extends CustomField<T> {
  readonly component: React.FunctionComponent;
}

export type Attribute<T> =
  | TextField<T>
  | CustomFieldWithRenderFunction<T>
  | CustomFieldWithComponent<T>
  | CustomFieldWithElement<T>
  | DateField<T>
  | BooleanField<T>
  | DropdownFieldWithStaticData<T>
  | DropdownFieldWithFetchHook<T>;

export const isCustomFieldWithRenderFunction = <T>(
  field: CustomField<T>,
): field is CustomFieldWithRenderFunction<T> => {
  return 'render' in field && typeof field.render === 'function';
};

export const isDropdownFieldWithFetchHook = <T>(
  field: DropdownField<T>,
): field is DropdownFieldWithFetchHook<T> => {
  return 'useQuery' in field && typeof field.useQuery === 'function';
};

export const isDropdownFieldWithStaticData = <T>(
  field: DropdownField<T>,
): field is DropdownFieldWithStaticData<T> => {
  return 'data' in field && Array.isArray(field.data);
};
