import { Box } from '@mui/material';
import fields from 'components/CompleteRegistration/forms/PasosComunes/SolicitanteDireccion/fields';
import {
  createSchema,
  SolicitanteDireccionForm,
} from 'components/CompleteRegistration/forms/PasosComunes/SolicitanteDireccion/schema';
import Form from 'components/Form';
import Fieldset from 'components/Form/components/Fieldset';
import MapComponent, {
  MapComponentFeatures,
} from 'components/ServicesCollectionsForms/MapComponent';
import { StepProps } from 'components/Stepper/stepProps';
import React from 'react';
import { FieldError } from 'types/fieldError';
import * as yup from 'yup';

type Props = StepProps<SolicitanteDireccionForm>;

const SolicitanteDireccion: React.FC<Props> = (props: Props): React.ReactElement => {
  const [errors, setErrors] = React.useState<
    Partial<Record<keyof SolicitanteDireccionForm, FieldError>>
  >({});

  const { name, onSubmit } = props;

  const handleClearErrors = React.useCallback((): void => {
    setErrors({});
  }, []);

  const handleSubmission = React.useCallback(
    (data: SolicitanteDireccionForm): void => {
      onSubmit?.(name, data);
    },
    [name, onSubmit],
  );

  const schema = React.useMemo((): yup.Schema => createSchema(), []);

  return (
    <Form<SolicitanteDireccionForm>
      id={name}
      schema={schema}
      errors={errors}
      autoComplete={false}
      onError={setErrors}
      onClearErrors={handleClearErrors}
      onSubmit={handleSubmission}
    >
      <Box display="flex" height="100%" flexDirection="column">
        <Fieldset fields={fields} />
        <Box flex={1} mt={0.75} mx={-1.75}>
          <MapComponent
            defaultValues={{}}
            features={MapComponentFeatures.none}
            orientation="vertical"
          />
        </Box>
      </Box>
    </Form>
  );
};

export default SolicitanteDireccion;
