const formatearFecha = (fechaISO: string): string => {
  const fecha = new Date(fechaISO);
  const opcionesDeFormato: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formatoFecha = new Intl.DateTimeFormat('es-VE', opcionesDeFormato);
  return formatoFecha.format(fecha);
};

export default formatearFecha;
