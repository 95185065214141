import { ExitToApp, Save } from '@mui/icons-material';
import { Box, Button, SxProps, Theme } from '@mui/material';
import { Attribute } from '@s3comsecurity/foundations';
import { formToObject } from 'components/Form/helpers';
import ModalContainer from 'components/ModalContainer';
import DynamicFormRenderer from 'components/RequestLayout/components/DynamicFormRenderer';
import React from 'react';
import { generateGuid } from 'utils/uuid';

interface Props {
  readonly fields: readonly Attribute[];
  readonly action: string | undefined;

  readonly selectedItem: any;

  onSubmit(item: any): void;
  onClose(): void;
}

const EditForm: React.FC<Props> = (props: Props): React.ReactElement => {
  const { action, fields, selectedItem } = props;
  const { onSubmit, onClose } = props;

  const handleItemSubmitted = React.useCallback((): void => {
    const { forms } = document;

    const form = forms.namedItem(formName);
    if (!form) {
      throw new Error('No se encontró el formulario');
    }

    onSubmit({ ...formToObject<any>(form), id: generateGuid() });
  }, [onSubmit]);

  const formTitle = React.useMemo((): string => {
    if (action === undefined) {
      return 'Typescript/Javascript is seriously a stupid language';
    }

    return `${action} un elemento ${action === 'editar' ? 'de' : 'a'} la lista`;
  }, [action]);

  return (
    <ModalContainer
      title={formTitle}
      footer={
        <Box sx={styles.buttons}>
          <Button variant="outlined" type="button" startIcon={<ExitToApp />} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={<Save />}
            onClick={handleItemSubmitted}
          >
            Aceptar
          </Button>
        </Box>
      }
      onClose={onClose}
    >
      <DynamicFormRenderer
        fields={fields}
        formName={formName}
        defaultValues={selectedItem}
        autoComplete={false}
        uploadPrefix="inventarios"
      />
    </ModalContainer>
  );
};

export default EditForm;

const styles: Record<string, SxProps<Theme>> = {
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: 'background.paper',
    padding: 2,
    gap: 1,
  },
};

const formName = 'inventory:item';
