import { Attribute } from '@s3comsecurity/foundations';
import InventoryScreen from 'components/Inventory/components/Screen';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

interface Props {
  readonly fields: readonly Attribute[];
  readonly defaultValues: Readonly<Record<string, any>>;
}

const Inventory: React.FC<Props> = (props: Props): React.ReactElement => {
  const { fields, defaultValues } = props;

  return (
    <Routes>
      <Route
        path="/:action?/:itemId?"
        element={<InventoryScreen fields={fields} defaultValues={defaultValues} />}
      />
    </Routes>
  );
};

export default Inventory;
