import { Check } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  readonly status: 'passed' | 'active' | 'pending';
  readonly position: number;
}

const StepIcon: React.FC<Props> = (props: Props): React.ReactElement => {
  const { status, position } = props;

  switch (status) {
    case 'passed':
      return (
        <Typography
          color="primary.contrastText"
          component={Box}
          display="flex"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Check />
        </Typography>
      );
    case 'active':
      return (
        <Typography variant="subtitle2" lineHeight="inherit">
          {position}
        </Typography>
      );
    case 'pending':
      return (
        <Typography variant="subtitle2" color="text.secondary" lineHeight="inherit">
          {position}
        </Typography>
      );
  }
};

export default StepIcon;
