import { Box } from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { Step } from '@s3comsecurity/user-configuration';
import DynamicStepper from 'components/DynamicStepper';
import Render from 'components/Render';
import ActiveStep from 'components/RequestLayout/components/ActiveStep';
import Components from 'components/RequestLayout/components/NewRequest/Components';
import StepComponent from 'components/RequestLayout/components/Step';
import Steps from 'components/RequestLayout/components/Steps';
import Spinner from 'components/Spinner';
import { useStepInformation } from 'hooks/useStepInformation';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCurrentTimelinePointQuery,
  useStepsQuery,
  useSubmitStageMutation,
  useUpdateRequestStepDataMutation,
} from 'redux/API';

interface Props {
  readonly requestId: string;
  readonly serviceId: number;
  readonly procedureId: number;

  readonly stepId?: number;
}

const Content: React.FC<Props> = (props: Props): React.ReactElement => {
  const { requestId, stepId, serviceId, procedureId } = props;
  const { data: { idLineaTiempo = -1 } = { idLineaTiempo: -1 } } = useCurrentTimelinePointQuery({
    idSolicitud: requestId,
  });
  const { data: { pasos: steps = [] } = { pasos: [] }, isLoading } = useStepsQuery({
    idServicio: serviceId,
    idTramite: procedureId,
    idLineaTiempo: idLineaTiempo,
  });

  const [submitStage, submitStageMutation] = useSubmitStageMutation();
  const [recordStep, recordStepMutation] = useUpdateRequestStepDataMutation();
  const navigate = useNavigate();
  const { previousStepId, currentStepId, nextStepId, currentStepIndex } = useStepInformation(
    steps,
    stepId,
  );

  const goTo = React.useCallback(
    (stepId: number | null): void => {
      // There is one case where this happens and is completely valid!
      if (stepId === null) {
        return;
      }

      navigate(`/tramites/${serviceId}/${procedureId}/${requestId}/${stepId}`);
    },
    [navigate, procedureId, requestId, serviceId],
  );

  const handleNext = React.useCallback(
    async (data?: any): Promise<void> => {
      if (currentStepId === undefined || currentStepId === null) {
        console.warn('trying to navigate to a non-existing step');
        return;
      }

      recordStep({
        idSolicitud: requestId,
        idPaso: currentStepId,
        datos: data,
      });
    },
    [currentStepId, recordStep, requestId],
  );

  const handleDone = React.useCallback((): void => {
    submitStage({ idSolicitud: requestId });
  }, [requestId, submitStage]);

  const handleBack = React.useCallback((): void => {
    goTo(previousStepId);
  }, [goTo, previousStepId]);

  const handleCancel = React.useCallback((): void => {
    navigate(`/tramites/${serviceId}/${procedureId}`);
  }, [navigate, procedureId, serviceId]);

  React.useEffect((): void => {
    if (submitStageMutation.status === QueryStatus.fulfilled) {
      submitStageMutation.reset();
      navigate(`/tramites/${serviceId}/${procedureId}`);
    }
  }, [navigate, procedureId, serviceId, submitStageMutation, submitStageMutation.status]);

  React.useEffect((): void => {
    if (recordStepMutation.status === QueryStatus.fulfilled) {
      recordStepMutation.reset();
      goTo(nextStepId);
    }
  }, [goTo, nextStepId, recordStepMutation, recordStepMutation.status]);

  const spinning = React.useMemo((): boolean => {
    if (isLoading) {
      return true;
    }

    return (
      recordStepMutation.status === QueryStatus.pending ||
      recordStepMutation.status === QueryStatus.fulfilled ||
      submitStageMutation.status === QueryStatus.pending ||
      submitStageMutation.status === QueryStatus.fulfilled
    );
  }, [isLoading, recordStepMutation.status, submitStageMutation.status]);

  if (currentStepId === undefined || currentStepId === null) {
    return <></>;
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} height="100%" position="relative">
      <DynamicStepper
        currentStep={currentStepIndex}
        form={`step:${currentStepId}`}
        onCancel={handleCancel}
        onNext={handleNext}
        onBack={handleBack}
        onDone={handleDone}
      >
        {(steps?.length ?? 0) > 1 ? (
          <Steps>
            {steps?.map(
              (step: Step): React.ReactElement => (
                <StepComponent key={step.id} name={step.nombre} title={step.etiqueta} />
              ),
            )}
          </Steps>
        ) : (
          <></>
        )}
        <ActiveStep>
          <Render when={!isLoading}>
            <Components
              serviceId={serviceId}
              procedureId={procedureId}
              requestId={requestId}
              stepId={currentStepId}
              steps={steps}
            />
          </Render>
        </ActiveStep>
      </DynamicStepper>

      <Spinner spinning={spinning} size={64} thickness={5} color="primary" />
    </Box>
  );
};

export default Content;
