import { DocumentType } from '@s3comsecurity/foundations';
import {
  GovernmentRequesterBasicInformation,
  RequesterAddressWithCoordinates,
} from '@s3comsecurity/requests';
import {
  UpdateGovernmentRequesterRequest,
  UpdateGovernmentRequesterRequestGazette,
  UpdateLegalRequesterRequestDocuments,
  UpdateLegalRequesterRequestLegalRepresentative,
  UpdateRequesterRequestPhoneNumber,
} from '@s3comsecurity/user-accounts';
import { SolicitanteJuridicoGobiernoGacetaForm } from 'components/CompleteRegistration/forms/JuridicoGobierno/JuridicoGobiernoGaceta/schema';
import { SolicitanteJuridicoDatos } from 'components/CompleteRegistration/forms/PasosComunes/JuridicoDatosBasicos/schema';
import { SolicitanteJuridicoObjeto } from 'components/CompleteRegistration/forms/PasosComunes/JuridicoObjetos/schema';
import { SolicitanteJuridicoRepresentanteLegal } from 'components/CompleteRegistration/forms/PasosComunes/JuridicoRepresentanteLegal/schema';
import { SolicitanteDireccionForm } from 'components/CompleteRegistration/forms/PasosComunes/SolicitanteDireccion/schema';

export interface JuridicoGobiernoForm {
  readonly datos: GovernmentRequesterBasicInformation;
  readonly representanteLegal: UpdateLegalRequesterRequestLegalRepresentative;
  // FIXME: find a more elegant way to do this (maybe directly in the protobufs)
  readonly objetos: SolicitanteJuridicoObjeto | readonly SolicitanteJuridicoObjeto[];
  readonly direccion: RequesterAddressWithCoordinates;
  // FIXME: find a more elegant way to do this (maybe directly in the protobufs)
  readonly telefonos: readonly UpdateRequesterRequestPhoneNumber[];
  readonly gaceta: UpdateGovernmentRequesterRequestGazette;
  readonly documentos: UpdateLegalRequesterRequestDocuments;
}

export class JuridicoGobiernoPayload {
  public static fromForm(request: JuridicoGobiernoForm): UpdateGovernmentRequesterRequest {
    const { datos, representanteLegal, objetos, direccion, telefonos, gaceta, documentos } =
      request;

    return {
      tipoDocumento: DocumentType.government,
      datos: datos,
      representanteLegal: representanteLegal,
      direccion: direccion,
      gaceta,
      telefonos: Array.isArray(telefonos) ? telefonos : [telefonos],
      objetos: Array.isArray(objetos) ? objetos : [objetos],
      documentos: documentos,
    };
  }
}

export interface JuridicoGobiernoPayload {
  readonly datos: SolicitanteJuridicoDatos;
  readonly representanteLegal: SolicitanteJuridicoRepresentanteLegal;
  readonly direccion: SolicitanteDireccionForm<number>;
  readonly telefonos: readonly string[];
  readonly objetos: readonly SolicitanteJuridicoObjeto[];
  readonly gaceta: SolicitanteJuridicoGobiernoGacetaForm;
}
