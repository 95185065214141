import { DocumentType } from '@s3comsecurity/foundations';
import {
  IndividualRequester,
  IndividualRequesterBasicInformation,
  RequesterAddressWithoutCoordinates,
} from '@s3comsecurity/requests';
import {
  UpdateIndividualRequesterRequest,
  UpdateRequesterRequestPhoneNumber,
  UpdateUserProfileRequest,
} from '@s3comsecurity/user-accounts';
import { SolicitanteTelefonosForm } from 'components/CompleteRegistration/forms/PasosComunes/SolicitanteTelefonos/schema';

export interface NaturalForm {
  readonly datos: IndividualRequester;
  readonly direccion: RequesterAddressWithoutCoordinates;
  readonly telefonos: SolicitanteTelefonosForm;
}

export interface NaturalPayload extends UpdateIndividualRequesterRequest {
  readonly datos: IndividualRequesterBasicInformation;
  readonly direccion: RequesterAddressWithoutCoordinates;
  readonly telefonos: readonly UpdateRequesterRequestPhoneNumber[];
}

export class NaturalPayload implements UpdateIndividualRequesterRequest {
  public static fromForm(form: NaturalForm): UpdateUserProfileRequest {
    const {
      datos,
      direccion,
      telefonos: { telefonos },
    } = form;

    return {
      tipoDocumento: DocumentType.individual,
      datos: datos,
      direccion: direccion,
      telefonos: telefonos,
    };
  }
}
