import { RemoveRedEye, SwapVert, UploadFile } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  FormLabel,
  IconButton,
  OutlinedInput,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import CircularProgressWrapper from 'components/CircularProgressWrapper';
import DocumentPreview from 'components/DocumentPreview';
import Icon from 'components/FileInput/icon';
import { FileInputAction, FileInputActionType, reducer, State } from 'components/FileInput/reducer';
import { MimeTypes } from 'components/FileInput/types';
import DateFieldInput from 'components/Form/components/DateFieldInput';
import FormErrorHelperText from 'components/FormError';
import ModalContainer from 'components/ModalContainer';
import Render from 'components/Render';
import { ControlData } from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import { BASE_URL } from 'globalConstants';
import React, { Reducer } from 'react';
import { useSelector } from 'react-redux';
import { useFileMetadataQuery } from 'redux/API';
import { authenticationSelector } from 'redux/authenticationReducer';
import { FieldError } from 'types/fieldError';
import { prettySize } from 'utils/prettySize';

interface Props {
  readonly label: string;
  readonly name: string;
  readonly mimeTypes?: readonly MimeTypes[];
  readonly error?: FieldError;
  readonly data?: ControlData;
  readonly expires?: boolean;
  readonly allowNote?: boolean;
  readonly uploadPrefix: string;
  readonly defaultValue?: string;

  onChange?(): void;
}

const FileInput: React.FC<Props> = (props: Props): React.ReactElement => {
  const {
    label,
    name,
    mimeTypes = [],
    expires = false,
    allowNote = false,
    error,
    data,
    defaultValue,
    uploadPrefix,
    onChange,
  } = props;
  const authentication = useSelector(authenticationSelector);
  const { data: fileMetadata } = useFileMetadataQuery(
    {
      idArchivo: defaultValue!,
    },
    {
      skip: !defaultValue,
    },
  );

  const [input, setInput] = React.useState<HTMLInputElement | null>(null);
  const [expiryDate, setExpiryDate] = React.useState<Date | null>(null);
  const [note, setNote] = React.useState<string>('');
  const [state, dispatch] = React.useReducer<Reducer<State, FileInputAction>>(
    reducer,
    State.initial(),
  );

  const { progress, status, fileId, fileName, fileSize, file } = state;

  const handleUpload = React.useCallback((): void => {
    const { body } = document;

    const fileInput = document.createElement('input');
    const style = { position: 'absolute', visibility: 'hidden' };

    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', mimeTypes?.join(', '));
    fileInput.setAttribute('style', JSON.stringify(style));

    const eventHandler = (event: Event): void => {
      const { files } = event.target as HTMLInputElement;

      if (files?.length === 1) {
        dispatch({ type: FileInputActionType.fileSelected, data: files[0] });
      }
      fileInput.removeEventListener('change', eventHandler);
      fileInput.removeEventListener('cancel', eventHandler);
      fileInput.remove();
    };

    fileInput.addEventListener('change', eventHandler);
    fileInput.addEventListener('cancel', eventHandler);

    body.append(fileInput);

    fileInput.click();
  }, [mimeTypes]);

  const description = React.useMemo((): string | undefined => {
    if (fileName === '') {
      return undefined;
    }

    if (fileSize === 0) {
      return fileName;
    }

    return `${fileName} - ${prettySize(fileSize)}`;
  }, [fileName, fileSize]);

  React.useEffect((): VoidFunction | void => {
    if (!file || !input || !input.form) {
      return;
    }

    const { name: formName } = input.form;
    const { name } = input;

    const form = new FormData();
    const uploadKey = [uploadPrefix, formName, name].join('/');

    form.append('uploadKey', uploadKey);
    form.append('file', file);
    form.append(
      'metadata',
      JSON.stringify({
        nota: allowNote ? note : null,
        fechaVencimiento: expires ? expiryDate : null,
      }),
    );

    const request = new XMLHttpRequest();

    request.open('POST', [BASE_URL, 'api', 'v1', 'cdn'].join('/'));
    request.setRequestHeader('Authorization', `Bearer ${authentication.token}`);

    const { upload } = request;

    upload.addEventListener('progress', (event: ProgressEvent): void => {
      dispatch({
        type: FileInputActionType.updateProgress,
        data: (100 * event.loaded) / event.total,
      });
    });

    request.addEventListener('readystatechange', (): void => {
      switch (request.readyState) {
        case 0: // Unset
        case 1: // Opened
        case 2: // Headers received
        case 3: // Loading
          break;
        case 4: // Done
          if (request.status === 200) {
            dispatch({ type: FileInputActionType.uploadingSucceeded, data: request.responseText });
          } else {
            dispatch({ type: FileInputActionType.uploadingFailed });
          }
      }
    });
    dispatch({ type: FileInputActionType.uploadingStarted });

    request.send(form);

    return (): void => {
      return;
    };
  }, [authentication.token, expires, expiryDate, file, name, input, uploadPrefix, allowNote, note]);

  React.useEffect((): void => {
    onChange?.();
  }, [fileId, onChange]);

  const handleNoteChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setNote(value);
  }, []);

  React.useEffect((): void => {
    if (fileMetadata) {
      dispatch({ type: FileInputActionType.initialize, data: fileMetadata });
    }
  }, [fileMetadata]);

  const handleClosePreview = React.useCallback((): void => {
    dispatch({ type: FileInputActionType.closePreview });
  }, []);

  const handlePreview = React.useCallback((): void => {
    dispatch({ type: FileInputActionType.preview });
  }, []);

  return (
    <>
      <input ref={setInput} type="hidden" name={name} value={fileId} {...data} />

      <Box sx={styles.container}>
        <Box flexShrink={0}>
          <CircularProgressWrapper progress={progress}>
            <Box sx={styles.iconBox}>
              <Icon status={status} />
            </Box>
          </CircularProgressWrapper>
        </Box>

        <Box flex={1} minWidth={0}>
          <Box width="100%" height={50} textAlign="left" pb={0.5}>
            <Typography
              title={label}
              variant="subtitle1"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {label}
            </Typography>
            {error ? (
              <Box display="flex" alignItems="center" height={24}>
                <FormErrorHelperText error={error} />
              </Box>
            ) : (
              <Box height={24}>
                <Typography
                  title={description}
                  color={description ? 'text.secondary' : 'text.disabled'}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {description ? description : '{Seleccionar archivo}'}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" flexShrink={0} ml="auto" px={1} gap={1}>
          <Render when={!!description}>
            <IconButton onClick={handlePreview}>
              <RemoveRedEye />
            </IconButton>
          </Render>
          <Button
            sx={styles.button}
            variant="contained"
            startIcon={description ? <SwapVert /> : <UploadFile />}
            onClick={handleUpload}
          >
            {description ? 'Cambiar' : 'Subir'}
          </Button>
        </Box>
      </Box>

      <Render when={expires || allowNote}>
        <Box display="flex" gap={2} borderTop="1px solid" borderColor="secondary.light" pt={1}>
          <Box width={50} />
          <Render when={expires}>
            <Box>
              <FormLabel>Fecha de Vencimiento</FormLabel>
              <DateFieldInput value={expiryDate} onChange={setExpiryDate} />
            </Box>
          </Render>
          <Render when={allowNote}>
            <Box flex={1}>
              <FormLabel>Nota</FormLabel>
              <OutlinedInput
                value={note}
                multiline={true}
                fullWidth={true}
                onChange={handleNoteChange}
              />
            </Box>
          </Render>
        </Box>
      </Render>

      <Dialog open={state.preview} maxWidth={false}>
        <ModalContainer title="Vista Previa" onClose={handleClosePreview}>
          <DocumentPreview url={state.fileUrl} name={state.fileName} />
        </ModalContainer>
      </Dialog>
    </>
  );
};

export default FileInput;

export const isMimeType = (value: string): value is MimeTypes => {
  return Object.values(MimeTypes).includes(value as MimeTypes);
};

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    py: 1,
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 50,
    backgroundColor: 'rgba(0, 0, 0, 0.025)',
    borderRadius: 25,
  },
  button: {
    width: 120,
  },
};
