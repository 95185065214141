import { Box } from '@mui/material';
import classes from 'components/MapMarker/mapMarker.module.scss';
import { DivIcon, divIcon, LatLngLiteral } from 'leaflet';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Marker, useMap } from 'react-leaflet';

interface Props {
  readonly position: LatLngLiteral | null;
}

const MapMarker: React.FC<Props> = (props: Props): React.ReactElement => {
  const [container] = React.useState<HTMLDivElement>(document.createElement('div'));
  const { position } = props;

  const root = React.useMemo((): ReactDOM.Root => ReactDOM.createRoot(container), [container]);
  const icon = React.useMemo((): DivIcon | null => {
    return divIcon({
      html: container ?? false,
      className: classes.marker,
      iconAnchor: [24, 48],
      iconSize: [48, 48],
    });
  }, [container]);

  const map = useMap();

  React.useEffect(() => {
    if (!container) {
      return undefined;
    }

    const mapContainer = map.getContainer();
    mapContainer.style.cursor = 'default';

    document.body.append(container);
    root.render(<Box />);

    return (): void => {
      container.remove();
    };
  }, [container, root, map]);

  if (icon === null || position === null) {
    return <></>;
  }

  return <Marker position={position} icon={icon} />;
};

export default MapMarker;
