import { StepperState, StepperStatus } from 'hooks/StepperManager/index';

export enum ActionType {
  back = 'back',
  next = 'next',
  done = 'done',
}

interface ActionBase {
  readonly type: ActionType;
  readonly payload?: any;
}

interface NextOrDoneActionPayload {
  readonly id: string;
  readonly data: any;
}

interface NextAction extends ActionBase {
  readonly type: ActionType.next;
  readonly payload: NextOrDoneActionPayload;
}

interface DoneAction extends ActionBase {
  readonly type: ActionType.done;
  readonly payload: NextOrDoneActionPayload;
}

interface BackAction extends ActionBase {
  readonly type: ActionType;
}

export type Action = BackAction | NextAction | DoneAction;

export const reducer = (state: StepperState, action: Action): StepperState => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.back:
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case ActionType.done:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: payload.data,
        },
        status: StepperStatus.completed,
      };
    case ActionType.next:
      return {
        ...state,
        activeStep: state.activeStep + 1,
        data: {
          ...state.data,
          [payload.id]: payload.data,
        },
      };
    default:
      return state;
  }
};
