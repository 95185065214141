import { StepperApi, StepperState } from 'hooks/StepperManager';
import React from 'react';

export type StepperManager = StepperState & StepperApi;

interface Props {
  readonly manager: StepperManager;
}

const StepperProvider: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const { manager, children } = props;

  return <StepperContext.Provider value={manager}>{children}</StepperContext.Provider>;
};

export default StepperProvider;
export const StepperContext = React.createContext<StepperManager | null>(null);
