import {
  AccessTime,
  Check,
  Clear,
  Commit,
  MoreHoriz,
  NotInterestedOutlined,
  Rule,
} from '@mui/icons-material';
import { Status } from '@s3comsecurity/requests';
import React from 'react';

export const icons: { [key in Status]: React.ReactElement } = {
  [Status.pending]: <AccessTime />,
  [Status.approved]: <Check />,
  [Status.running]: <Commit />,
  [Status.rejected]: <Clear />,
  [Status.cancelled]: <NotInterestedOutlined />,
  [Status.underReview]: <Rule />,
  [Status.completed]: <Check />,
  [Status.processing]: <MoreHoriz />,
};

export const colors: { [key in Status]: string } = {
  [Status.pending]: 'LightSteelBlue',
  [Status.approved]: '#31a181',
  [Status.running]: 'LightSlateGray',
  [Status.rejected]: 'Crimson',
  [Status.cancelled]: 'DarkGray',
  [Status.underReview]: 'DarkOrange',
  [Status.completed]: '#31a181',
  [Status.processing]: '#445060',
};

export const labels: { [key in Status]: string } = {
  [Status.pending]: 'En Espera',
  [Status.approved]: 'Aprobado',
  [Status.running]: 'En Curso',
  [Status.rejected]: 'Rechazado',
  [Status.cancelled]: 'Cancelado',
  [Status.underReview]: 'En Revisión',
  [Status.completed]: 'Completado',
  [Status.processing]: 'Procesando',
};
