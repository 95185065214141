import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'redux/store';
import { StorageKey } from 'storageKeys';

export interface AuthenticationState {
  token: string | null;
}

const initialState: AuthenticationState = {
  token: sessionStorage.getItem(StorageKey.accessToken),
};

const slice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    setAccessToken: (state: AuthenticationState, action: PayloadAction<string>): void => {
      state.token = action.payload;
    },
    logout: (state: AuthenticationState): void => {
      state.token = null;
    },
  },
});

export default slice.reducer;
export const { setAccessToken, logout } = slice.actions;

export const authenticationSelector = (state: ApplicationState): AuthenticationState =>
  state.authentication;
