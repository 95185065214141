import { Help } from '@mui/icons-material';
import { IconButton, OutlinedInput } from '@mui/material';
import DateFieldInput from 'components/Form/components/DateFieldInput';
import DropdownFieldWithFetchHookRenderer from 'components/Form/components/DropdownFieldWithFetchHookRenderer';
import DropdownFieldWithStaticDataRenderer from 'components/Form/components/DropdownFieldWithStaticDataRenderer';
import {
  Attribute,
  AttributeType,
  isCustomFieldWithRenderFunction,
  isDropdownFieldWithFetchHook,
  isDropdownFieldWithStaticData,
} from 'components/Form/types';
import RIFInput from 'components/RIFInput';
import React from 'react';

interface Props<T> {
  readonly defaultValue?: T[keyof T];
  readonly field: Attribute<T>;

  onHelpRequested?(help: string): void;
}

function FieldRenderer<T>(props: Props<T>): React.ReactElement {
  const { field, defaultValue } = props;
  const { onHelpRequested } = props;

  const handleShowHelp = React.useCallback((): void => {
    if (!field.help) {
      throw new Error('field with no help is requesting help, please help her');
    }

    onHelpRequested?.(field.help);
  }, [field.help, onHelpRequested]);

  switch (field.type) {
    case AttributeType.text:
      return (
        <OutlinedInput
          name={String(field.name)}
          defaultValue={defaultValue}
          fullWidth={true}
          endAdornment={
            field.help ? (
              <IconButton onClick={handleShowHelp}>
                <Help color="primary" />
              </IconButton>
            ) : undefined
          }
        />
      );
    case AttributeType.rif:
      if (typeof defaultValue === 'string') {
        return <RIFInput name={String(field.name)} help={field.help} defaultValue={defaultValue} />;
      } else {
        return <RIFInput name={String(field.name)} help={field.help} />;
      }
    case AttributeType.date:
      const dateValue = parseDateString(defaultValue as string);
      return <DateFieldInput name={String(field.name)} defaultValue={dateValue} />;
    case AttributeType.custom:
      if (isCustomFieldWithRenderFunction(field)) {
        return <>{field.render(field.name)}</>;
      }

      return <></>;
    case AttributeType.dropdown:
      if (isDropdownFieldWithFetchHook(field)) {
        return <DropdownFieldWithFetchHookRenderer field={field} />;
      } else if (isDropdownFieldWithStaticData(field)) {
        return <DropdownFieldWithStaticDataRenderer field={field} />;
      }

      return <></>;
    case AttributeType.email:
    default:
      return <OutlinedInput name={String(field.name)} fullWidth={true} />;
  }
}

export default FieldRenderer;

function parseDateString(dateString: string | undefined): Date | undefined {
  if (!dateString) {
    return undefined;
  }
  const parts = dateString.split('/');
  if (parts.length === 3) {
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }
  return undefined;
}
