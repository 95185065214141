import { FormLabel, Grid } from '@mui/material';
import { Enumerable } from '@s3comsecurity/foundations';
import EnumerableSelect from 'components/EnumerableSelect';
import React from 'react';
import {
  useCitiesQuery,
  useMunicipalitiesQuery,
  useParishesQuery,
  useStatesQuery,
} from 'redux/API';
import { GeoQuery, useLazySearchQuery } from 'redux/API/openstreetmap';

interface Props extends GeographicalLocation {
  onBoundingBoxChange(boundingBox: string[]): void;
  onChange(key: keyof GeographicalLocation, value: number): void;
}

const LocationPicker: React.FC<Props> = (props: Props): React.ReactElement => {
  const { stateId, municipalityId, parishId, cityId } = props;
  const { onChange, onBoundingBoxChange } = props;

  // List states
  const { data: estados = [], isFetching: loadingEstados } = useStatesQuery();

  // List municipalities
  const { data: municipios = [], isFetching: loadingMunicipios } = useMunicipalitiesQuery({
    idEstado: stateId,
  });

  // List parishes
  const { data: parroquias = [], isFetching: loadingParroquias } = useParishesQuery({
    idEstado: stateId,
    idMunicipio: municipalityId,
  });

  // List cities
  const { data: ciudades = [], isFetching: loadingCiudades } = useCitiesQuery({
    idEstado: stateId,
  });

  const [geoSearch, geoSearchResult] = useLazySearchQuery();

  const computeBoundingBox = React.useCallback((): void => {
    const getQuery = (): GeoQuery | null => {
      const state = estados?.find((each: Enumerable): boolean => each.id === stateId);
      if (!state) {
        return null;
      }
      const county = municipios?.find((each: Enumerable): boolean => each.id === municipalityId);
      const city = parroquias?.find((each: Enumerable): boolean => each.id === parishId);

      return {
        state: state.nombre,
        county: county?.nombre,
        city: city?.nombre,
      };
    };

    const query = getQuery();

    if (query !== null) {
      geoSearch(query);
    }
  }, [estados, geoSearch, municipalityId, municipios, parishId, parroquias, stateId]);

  React.useEffect((): void => {
    const { data } = geoSearchResult;
    if (!data) {
      return;
    }

    const item = data[0];
    if (!item) {
      return;
    }

    onBoundingBoxChange(item.boundingbox);
  }, [geoSearchResult, onBoundingBoxChange]);

  const setStateId = React.useCallback(
    (stateId: number): void => {
      onChange('stateId', stateId);
    },
    [onChange],
  );

  const setMunicipalityId = React.useCallback(
    (municipalityId: number): void => {
      onChange('municipalityId', municipalityId);
    },
    [onChange],
  );

  const setParishId = React.useCallback(
    (parishId: number): void => {
      onChange('parishId', parishId);
    },
    [onChange],
  );

  const setCityId = React.useCallback(
    (cityId: number): void => {
      onChange('cityId', cityId);
    },
    [onChange],
  );

  const handleSetEstado = React.useCallback(
    (value: number): void => {
      setStateId(value);
      computeBoundingBox();
    },
    [computeBoundingBox, setStateId],
  );

  const handleSetMunicipio = React.useCallback(
    (value: number): void => {
      setMunicipalityId(value);
      computeBoundingBox();
    },
    [computeBoundingBox, setMunicipalityId],
  );

  const handleSetParroquia = React.useCallback(
    (value: number): void => {
      setParishId(value);
      computeBoundingBox();
    },
    [computeBoundingBox, setParishId],
  );

  return (
    <>
      <Grid spacing={1} mx={-0.75} mt={-1} container>
        <Grid xs={6} item>
          <FormLabel>Estado</FormLabel>
          <EnumerableSelect
            items={estados}
            loading={loadingEstados}
            value={stateId}
            onChange={handleSetEstado}
          />
        </Grid>
        <Grid xs={6} item>
          <FormLabel>Municipio</FormLabel>
          <EnumerableSelect
            items={municipios}
            loading={loadingMunicipios}
            value={municipalityId}
            onChange={handleSetMunicipio}
          />
        </Grid>
        <Grid xs={12} item>
          <FormLabel>Parroquia</FormLabel>
          <EnumerableSelect
            items={parroquias}
            loading={loadingParroquias}
            value={parishId}
            onChange={handleSetParroquia}
          />
        </Grid>
        <Grid xs={12} item>
          <FormLabel>Ciudad</FormLabel>
          <EnumerableSelect
            items={ciudades}
            loading={loadingCiudades}
            value={cityId}
            onChange={setCityId}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LocationPicker;

export interface GeographicalLocation {
  readonly stateId: number;
  readonly municipalityId: number;
  readonly parishId: number;
  readonly cityId: number;
}

export class GeographicalLocation {
  public static none(): GeographicalLocation {
    return {
      stateId: 0,
      municipalityId: 0,
      parishId: 0,
      cityId: 0,
    };
  }
}
