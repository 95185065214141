import { ParameterCannotConvertToTypeErr } from 'parameters/errors';
import { ProcedureGroupParameters } from 'parameters/service';

export interface RequestParameters extends ProcedureGroupParameters {
  readonly requestId: string;
  readonly stepId?: number;
  readonly procedureId: number;
}

export class RequestParameters {
  public static fromUnknown(value: unknown): RequestParameters {
    if (typeof value === 'object') {
      const parameters = value as any;
      const serviceId = Number(parameters.serviceId);
      const procedureId = Number(parameters.procedureId);
      const requestId = parameters.requestId;
      const stepId = Number(parameters.stepId);

      if (isNaN(serviceId) || isNaN(procedureId) || requestId === '') {
        throw ParameterCannotConvertToTypeErr;
      }

      return {
        serviceId: serviceId,
        procedureId: procedureId,
        requestId: requestId,
        stepId: isNaN(stepId) ? undefined : stepId,
      };
    } else {
      throw ParameterCannotConvertToTypeErr;
    }
  }
}
