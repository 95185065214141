import { CheckCircleOutlined, CreditCard, ErrorOutlineRounded, Payment } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import Disable from 'components/Disable';
import FormattedAmount from 'components/FormattedAmount';
import Render from 'components/Render';
import { useTypedParams } from 'hooks/useTypedParams';
import { RequestParameters } from 'parameters/procedure';
import React from 'react';
import { useGetFeeQuery } from 'redux/API';

enum PaymentStatus {
  idle = 'idle',
  processing = 'processing',
  success = 'success',
  error = 'error',
}

const PaymentForm: React.FC = (): React.ReactElement => {
  const params = useTypedParams<RequestParameters>(RequestParameters.fromUnknown);

  const [status, setStatus] = React.useState<PaymentStatus>(PaymentStatus.idle);
  const [attempts, setAttempts] = React.useState<string[]>([]);

  const { data, isLoading } = useGetFeeQuery({ idSolicitud: params.requestId });

  const simulatePayment = React.useCallback((): void => {
    setStatus(PaymentStatus.processing);
    setTimeout((): void => {
      if (attempts.length > 0) {
        setStatus(PaymentStatus.success);
        setAttempts([]);
      } else {
        setStatus(PaymentStatus.error);
        setAttempts([...attempts, 'attempt']);
      }
    }, 1670);
  }, [attempts]);

  const handleReset = React.useCallback((): void => {
    setStatus(PaymentStatus.idle);
  }, []);

  return (
    <Box height="100%" width="50%" mx="auto" py={3}>
      <Paper elevation={2} sx={styles.paper}>
        <Box display="flex" flexDirection="column" height="100%" p={3}>
          <Box flex={1}>
            <form>
              <Disable when={status !== PaymentStatus.idle}>
                <Grid gap={1} container>
                  <Grid alignItems="center" container>
                    <Grid xs={6} item>
                      <FormLabel>Total a pagar</FormLabel>
                    </Grid>
                    <FormattedAmount amount={data?.montos[0]} />
                  </Grid>
                  <Grid alignItems="center" container>
                    <Grid xs={6} item></Grid>
                    <FormattedAmount amount={data?.montos[1]} />
                  </Grid>
                  <Grid alignItems="center" container>
                    <Grid xs={6} item>
                      <FormLabel>Seleccione un método de pago</FormLabel>
                    </Grid>
                    <Grid xs={6} item>
                      <Select value="card" fullWidth={true}>
                        <MenuItem value="card">Tarjeta de Crédito/Débito</MenuItem>
                        <MenuItem value="quick">Pago Móvil/Dinero Rápido</MenuItem>
                        <MenuItem value="wire">Transferencia Bancaria</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Box mt={3}>
                    <Grid container>
                      <Grid xs={12} item>
                        <Box mb={2}>
                          <FormLabel htmlFor="cardNumber">Número de Tarjeta</FormLabel>
                          <OutlinedInput
                            id="cardNumber"
                            placeholder="4444 4444 4444 4444"
                            readOnly={true}
                            startAdornment={<CreditCard />}
                            fullWidth={true}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={3} item>
                        <Box mb={2} pr={1}>
                          <FormLabel htmlFor="expMonth">Vencimiento</FormLabel>
                          <OutlinedInput
                            id="expYearMonth"
                            placeholder="MM/AA"
                            readOnly={true}
                            fullWidth={true}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={3} item />
                      <Grid xs={3} item>
                        <Box mb={2}>
                          <FormLabel htmlFor="cvv">CVV</FormLabel>
                          <OutlinedInput
                            id="cvv"
                            placeholder="123"
                            readOnly={true}
                            fullWidth={true}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Disable>
            </form>
          </Box>
          <Box display="flex" alignItems="center" gap={1} width="100%">
            <Button variant="contained" color="error" disabled>
              Exoneración del Pago
            </Button>
            <Button variant="contained" color="secondary" disabled>
              Solicitar Convenimiento de Pago
            </Button>
            <Box ml="auto">
              <Button
                variant="contained"
                startIcon={<Payment />}
                disabled={status !== PaymentStatus.idle}
                onClick={simulatePayment}
              >
                Pagar
              </Button>
            </Box>
          </Box>
        </Box>
        <Render when={status === PaymentStatus.error}>
          <Box sx={styles.notificationBox}>
            <ErrorOutlineRounded color="error" sx={styles.statusIcon} />
            <Box width={300} mb={5}>
              <Typography variant="subtitle2" color="text.secondary" textAlign="center">
                Ha ocurrido un error, por favor intente de nuevo.
              </Typography>
            </Box>
            <Button onClick={handleReset}>Cerrar</Button>
          </Box>
        </Render>
        <Render when={status === PaymentStatus.success}>
          <Box sx={styles.notificationBox}>
            <CheckCircleOutlined color="success" sx={styles.statusIcon} />
            <Box width={300}>
              <Typography variant="subtitle2" color="text.secondary" textAlign="center">
                Hemos procesado el pago correctamente, por favor presione siguiente para continuar.
              </Typography>
            </Box>
          </Box>
        </Render>
        <Render when={isLoading}>
          <Box sx={styles.notificationBox}>
            <CircularProgress size={100} />
            <Typography variant="subtitle2" color="text.secondary">
              Calculando monto para el pago, por favor espere&hellip;
            </Typography>
          </Box>
        </Render>
        <Render when={status === PaymentStatus.processing}>
          <Box sx={styles.notificationBox}>
            <CircularProgress size={100} />
            <Typography variant="subtitle2" color="text.secondary">
              Procesando, por favor espere&hellip;
            </Typography>
          </Box>
        </Render>
      </Paper>
    </Box>
  );
};

export default PaymentForm;

const styles = {
  paper: {
    position: 'relative',
    height: '100%',
  },
  notificationBox: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(5px)',
    mt: 2,
    mx: 'auto',
    flex: 1,
    gap: 1,
  },
  statusIcon: {
    fontSize: 120,
  },
};
