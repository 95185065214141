import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

interface Props {
  readonly open: boolean;

  onClick(): void;
}

const Eye: React.FC<Props> = (props: Props): React.ReactElement => {
  if (props.open) {
    return (
      <IconButton tabIndex={-1} onClick={props.onClick}>
        <VisibilityOff cursor="pointer" />
      </IconButton>
    );
  } else {
    return (
      <IconButton tabIndex={-1} onClick={props.onClick}>
        <Visibility cursor="pointer" />
      </IconButton>
    );
  }
};

export default Eye;
