import { OutlinedInput } from '@mui/material';
import Eye from 'components/Eye';
import React from 'react';

interface Props {
  readonly placeholder: string;
  readonly name: string;
  readonly newPassword?: boolean;
  readonly autoFocus?: boolean;
}

const PasswordInput: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, newPassword = false, placeholder, autoFocus = false } = props;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const toggleShowPassword = React.useCallback((): void => {
    setShowPassword((showPassword: boolean): boolean => !showPassword);
  }, []);

  const type = React.useMemo((): 'text' | 'password' => {
    if (showPassword) {
      return 'text';
    } else {
      return 'password';
    }
  }, [showPassword]);

  const autoComplete = React.useMemo((): 'current-password' | 'new-password' => {
    if (newPassword) {
      return 'new-password';
    } else {
      return 'current-password';
    }
  }, [newPassword]);

  const endAdornment = React.useMemo(
    (): React.ReactElement => <Eye open={showPassword} onClick={toggleShowPassword} />,
    [showPassword, toggleShowPassword],
  );

  return (
    <OutlinedInput
      type={type}
      name={name}
      autoComplete={autoComplete}
      endAdornment={endAdornment}
      placeholder={placeholder}
      autoFocus={autoFocus}
      fullWidth={true}
    />
  );
};

export default PasswordInput;
