import { ArrowBack, Check } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { AuthenticationPaths, isAuthenticationPath } from 'constants/routes';
import React from 'react';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';

interface AuthenticationTextSet {
  readonly title: string;
  readonly submit: string;
  readonly form?: string;
}

const AuthenticationCommonLayout: React.FC = (): React.ReactElement => {
  const location = useLocation();

  const settings = React.useMemo((): AuthenticationTextSet => {
    const { pathname } = location;
    const key: AuthenticationPaths | string = pathname.slice(1);

    if (!isAuthenticationPath(key)) {
      throw new Error(`Invalid path: ${pathname}`);
    }

    return configuration[key];
  }, [location]);

  return (
    <Box display="flex" flexDirection="column" height="100%" paddingTop={3}>
      <Box textAlign="center">
        <Typography variant="h5">{settings.title}</Typography>
      </Box>
      <Box flexGrow="1" padding={3}>
        <Outlet />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        paddingLeft={3}
        paddingRight={3}
        justifyContent="space-between"
      >
        <Button
          component={RouterLink}
          to="/"
          startIcon={<ArrowBack />}
          variant="text"
          role="button"
        >
          Atr&aacute;s
        </Button>
        <Button form={settings.form} variant="contained" type="submit" startIcon={<Check />}>
          {settings.submit}
        </Button>
      </Box>
    </Box>
  );
};

export default AuthenticationCommonLayout;

const configuration: { [key in AuthenticationPaths]: AuthenticationTextSet } = {
  [AuthenticationPaths.signUp]: {
    title: 'Creare Cuenta',
    submit: 'Crear',
    form: 'register',
  },
  [AuthenticationPaths.recoverPassword]: {
    title: 'Recuperar Contraseña',
    submit: 'Recuperar',
    form: 'recover',
  },
};
