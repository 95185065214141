import { Grid, Typography } from '@mui/material';
import { Amount } from '@s3comsecurity/requests';
import React from 'react';

interface Props {
  readonly amount?: Amount;
}

const FormattedAmount: React.FC<Props> = (props: Props): React.ReactElement => {
  const { amount } = props;

  const formatter = React.useMemo((): Intl.NumberFormat | undefined => {
    if (!amount) {
      return undefined;
    }

    switch (amount.divisa) {
      case 'VES':
      case 'USD':
        return formatters[amount.divisa];
      default:
        throw new Error(`Unknown currency: ${amount.divisa}`);
    }
  }, [amount]);

  if (!amount || !formatter) {
    return (
      <Grid xs={6} item>
        <Typography variant="subtitle1" textAlign="right">
          -
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid xs={6} item>
      <Typography variant="subtitle1" textAlign="right">
        {formatter.format(amount.monto / 100)}
      </Typography>
    </Grid>
  );
};

export default FormattedAmount;

const formatters: Record<'VES' | 'USD', Intl.NumberFormat> = {
  VES: new Intl.NumberFormat('es-VE', {
    style: 'currency',
    currency: 'VES',
  }),
  USD: new Intl.NumberFormat('es-VE', {
    style: 'currency',
    currency: 'USD',
  }),
};
