import { createTheme, darken } from '@mui/material';
import parentTheme from 'styles/theme';

const theme = createTheme(parentTheme, {
  palette: {
    background: {
      default: parentTheme.palette.primary.dark,
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
          display: 'block',

          '& a.active': {
            backgroundColor: darken(parentTheme.palette.primary.dark, 0.45),
            color: parentTheme.palette.primary.contrastText,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          color: 'inherit',
          fill: 'current',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          width: '100%',
          gap: 8,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
    },
  },
});

export default theme;
