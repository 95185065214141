import { Box, SxProps, Theme, Typography } from '@mui/material';
import { AttributeType, DocumentType } from '@s3comsecurity/foundations';
import text from 'components/ServicesCollectionsForms/RequestLetter/text';
import React from 'react';
import { useUserProfileQuery } from 'redux/API';

interface Props {
  readonly name: string;
}

const RequestLetter: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name } = props;
  const { data: userProfile } = useUserProfileQuery();

  const senderName = React.useMemo((): string => {
    if (!userProfile) {
      return '';
    }

    switch (userProfile.tipoDocumento) {
      case DocumentType.legal:
        return userProfile.nombreRepresentanteLegal;
      case DocumentType.government:
        return '';
      case DocumentType.individual:
        return `${userProfile.primerNombre} ${userProfile.segundoNombre}`;
      default:
        return '';
    }
  }, [userProfile]);

  const body = React.useMemo((): React.ReactElement => {
    if (!userProfile) {
      return <></>;
    }

    switch (userProfile.tipoDocumento) {
      case DocumentType.legal:
        return (
          <Typography variant="body1">
            <span>Yo </span>
            <strong>{senderName}</strong>
            <span>, con cédula de identidad/RIF Nro.</span>
            <strong> {formatId(userProfile.rifRepresentanteLegal)} </strong>
            <span>por medio de la presente me dirijo a usted como representante legal de</span>
            <strong> {userProfile.nombre} </strong>
            <span>
              {text.CuerpoIncio} {text.Motivo} {text.CuerpoFin}
            </span>
          </Typography>
        );
      case DocumentType.government:
      case DocumentType.individual:
      default:
        return <></>;
    }
  }, [senderName, userProfile]);

  return (
    <Box sx={styles.container}>
      <form name={name}>
        <input
          type="hidden"
          name="cartaAceptada"
          value="true"
          data-type={AttributeType.bool}
          data-id="carta"
        />
        <Box sx={styles.content}>
          <Box>
            <Typography align="right">{text.Membrete}</Typography>
          </Box>
          <Box>
            <Typography whiteSpace="pre-wrap">{text.Destinatario}</Typography>
          </Box>
          <Box>
            <Typography>Asunto: {text.Asunto}</Typography>
          </Box>
          <Box>{body}</Box>
          <Box>
            <Typography variant="body1" align="left">
              {text.Cierre} {text.TipoRegistro}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" align="left">
              {text.Despedida}
            </Typography>
          </Box>
          <Box flex={1} />
          <Box textAlign="center">
            <Box display="inline-block" borderBottom="2px solid" px={1}>
              <Typography variant="subtitle1">{senderName}</Typography>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default RequestLetter;

const styles: Record<string, SxProps<Theme>> = {
  container: {
    maxWidth: 980,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '100%',
    py: 1,
  },
  content: {
    border: '1px dashed',
    borderColor: '#e0e0e0',
    display: 'flex',
    flexDirection: 'column',
    py: 2,
    px: 3,
    gap: 5,
  },
};

const formatId = (id: string): string => {
  return [id[0], id.slice(1, id.length - 1), id.slice(-1)].join('-');
};
