import { QueryStatus } from '@reduxjs/toolkit/query';
import JuridicoDatosBasicos from 'components/CompleteRegistration/forms/PasosComunes/JuridicoDatosBasicos';
import JuridicoDocumentos from 'components/CompleteRegistration/forms/PasosComunes/JuridicoDocumentos';
import JuridicoObjetos from 'components/CompleteRegistration/forms/PasosComunes/JuridicoObjetos';
import JuridicoRepresentanteLegal from 'components/CompleteRegistration/forms/PasosComunes/JuridicoRepresentanteLegal';
import SolicitanteDireccion from 'components/CompleteRegistration/forms/PasosComunes/SolicitanteDireccion';
import SolicitanteTelefonos from 'components/CompleteRegistration/forms/PasosComunes/SolicitanteTelefonos';
import ModalContainer from 'components/ModalContainer';
import OverlayMessageBox, { MessageType } from 'components/OverlayMessageBox';
import Spinner from 'components/Spinner';
import Stepper from 'components/Stepper';
import Switch from 'components/Switch';
import StepperProvider from 'context/stepper';
import { useStepperManager } from 'hooks/StepperManager';
import React from 'react';
import { useActualizarSolicitanteMutation, useUserQuery } from 'redux/API';
import { JuridicoForm, JuridicoPayload } from 'types/juridico';
import { WithDocuments } from 'types/withDocuments';

interface Props {
  onClose(): void;
}

const emptyUser = {
  registrado: false,
};

const RegistroPersonaJuridica: React.FC<Props> = (props: Props): React.ReactElement => {
  const { data: user = emptyUser } = useUserQuery();
  const { registrado } = user;
  const [submit, mutationStatus] = useActualizarSolicitanteMutation();
  const { onClose } = props;

  const manager = useStepperManager();

  const handleCompleted = React.useCallback(
    async (form: WithDocuments<JuridicoForm>): Promise<void> => {
      submit(JuridicoPayload.fromForm(form));
    },
    [submit],
  );

  const handleOperationCompleted = React.useCallback((): void => {
    onClose();
  }, [onClose]);

  const handleErrorDismissed = React.useCallback((): void => {
    mutationStatus.reset();
  }, [mutationStatus]);

  return (
    <ModalContainer title={registrado ? 'Editar Perfil' : 'Completar Registro'} onClose={onClose}>
      <StepperProvider manager={manager}>
        <Stepper onCancel={onClose} onCompleted={handleCompleted}>
          <JuridicoDatosBasicos name="datos" title="Datos Básicos" />
          <JuridicoRepresentanteLegal name="representanteLegal" title="Representante Legal" />
          <JuridicoDocumentos name="documentos" title="Documentos" />
          <JuridicoObjetos name="objetos" title="Objetos" />
          <SolicitanteDireccion name="direccion" title="Dirección" />
          <SolicitanteTelefonos name="telefonos" title="Teléfonos" />
        </Stepper>
      </StepperProvider>

      <Switch value={mutationStatus.status}>
        <Switch.Case value={QueryStatus.fulfilled}>
          <OverlayMessageBox
            type={MessageType.success}
            message={'Ha completado el registro correctamente.'}
            onClose={handleOperationCompleted}
          />
        </Switch.Case>
        <Switch.Case value={QueryStatus.rejected}>
          <OverlayMessageBox
            type={MessageType.error}
            message={
              'Ha ocurrido un error intentando realizar esta operación. ' +
              'Por favor intente más tarde. ' +
              'Su el problema persiste contacte a soporte técnico.'
            }
            onClose={handleErrorDismissed}
          />
        </Switch.Case>
        <Switch.Case value={QueryStatus.pending}>
          <Spinner spinning={true} />
        </Switch.Case>
      </Switch>
    </ModalContainer>
  );
};

export default RegistroPersonaJuridica;
