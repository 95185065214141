import { LockOpenOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { LoginRequest } from '@s3comsecurity/user-auth';
import AuthErrorBox from 'components/AuthErrorBox';
import Form from 'components/Form';
import FormErrorHelperText from 'components/FormError';
import PaddingBox from 'components/PaddingBox';
import PasswordInput from 'components/PasswordInput';
import RegistrationSuccessBox from 'components/RegistrationSuccessBox';
import RIFInput from 'components/RIFInput';
import Spinner from 'components/Spinner';
import { AuthenticationPaths } from 'constants/routes';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useLoginMutation } from 'redux/API';
import { setAccessToken } from 'redux/authenticationReducer';
import { StorageKey } from 'storageKeys';
import { FieldError } from 'types/fieldError';
import { PrefillType } from 'types/prefillType';
import schema from 'yup/schemas/login';

const Login: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [errors, setErrors] = React.useState<Partial<Record<keyof LoginRequest, FieldError>>>({});
  const [login, loginStatus] = useLoginMutation();

  const { type, data } = location.state ?? {};

  const handleSubmit = React.useCallback(
    (data: LoginRequest): void => {
      login(data);
    },
    [login],
  );

  React.useEffect((): void => {
    if (loginStatus.isSuccess) {
      const { token } = loginStatus.data;
      // Store it in the session storage
      sessionStorage.setItem(StorageKey.accessToken, token);
      // Set access token now!
      dispatch(setAccessToken(token));
    }
  }, [dispatch, loginStatus.data, loginStatus.isSuccess]);

  const handleClearErrors = React.useCallback((): void => {
    setErrors({});
  }, []);

  const defaultRIFValue = React.useMemo((): string | undefined => {
    if (type === PrefillType.newUser) {
      return data;
    } else {
      return undefined;
    }
  }, [data, type]);

  return (
    <Box position="relative">
      <PaddingBox>
        <Form
          id="login"
          schema={schema}
          errors={errors}
          disabled={loginStatus.isLoading}
          onSubmit={handleSubmit}
          onClearErrors={handleClearErrors}
          onError={setErrors}
        >
          <Grid flexDirection="column" alignItems="center" container>
            <Box marginBottom={4}>
              <Typography variant="h5">Bienvenido</Typography>
            </Box>
            <RegistrationSuccessBox visible={type === PrefillType.newUser} />
            <AuthErrorBox error={loginStatus.error} />
            <Box width="100%" marginBottom={1}>
              <RIFInput
                name="rif"
                autoComplete="username"
                defaultValue={defaultRIFValue}
                autoFocus={!defaultRIFValue}
              />
              <FormErrorHelperText error={errors.rif} />
            </Box>
            <Box width="100%" marginBottom={1}>
              <PasswordInput
                name="claveSecreta"
                placeholder={'Contraseña'}
                autoFocus={!!defaultRIFValue}
              />
              <FormErrorHelperText error={errors.claveSecreta} />
            </Box>
            <Grid textAlign="center" paddingTop={4} paddingBottom={4} item>
              <Button variant="contained" type="submit" startIcon={<LockOpenOutlined />}>
                Iniciar sesión
              </Button>
              <Box marginTop={2}>
                <Link component={RouterLink} to={AuthenticationPaths.recoverPassword}>
                  <Typography>¿Olvidó su contraseña?</Typography>
                </Link>
              </Box>
            </Grid>

            <Box marginTop={2}>
              <Typography component="p">
                <Typography component="span">¿No tiene cuenta?</Typography>
                <Typography component="span">&ensp;</Typography>
                <Link component={RouterLink} to="registrarse">
                  Crear cuenta
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Form>
      </PaddingBox>
      <Spinner spinning={loginStatus.isLoading} />
    </Box>
  );
};

export default Login;
