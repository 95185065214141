import {
  Box,
  FormLabel,
  Grid,
  InputBaseComponentProps,
  OutlinedInput,
  Switch,
} from '@mui/material';
import { Attribute, AttributeType } from '@s3comsecurity/foundations';
import FileInput, { isMimeType } from 'components/FileInput';
import DateFieldInput from 'components/Form/components/DateFieldInput';
import PhoneNumberInput from 'components/Form/components/PhoneNumberInput';
import Dropdown from 'components/RequestLayout/components/RequestComponentRenderer/components/Dropdown';
import SelectorOneOf from 'components/RequestLayout/components/RequestComponentRenderer/components/SelectorOneOf';
import SelectorSeveralOf from 'components/RequestLayout/components/RequestComponentRenderer/components/SelectorSeveralOf';
import RIFInput from 'components/RIFInput';
import React from 'react';

interface Props {
  readonly field: Attribute;
  readonly defaultValue: any;
  readonly uploadPrefix: string;
}

export interface ControlData extends InputBaseComponentProps {
  readonly 'data-id': string;
  readonly 'data-type': string;
}

const ControlSelector: React.FC<Props> = (props: Props): React.ReactElement => {
  const { field, defaultValue, uploadPrefix } = props;
  const { etiqueta: label, nombre: name, tipo: type } = field;

  const data: ControlData = {
    'data-id': String(field.id),
    'data-type': type,
  };

  const key = React.useMemo((): string => {
    if (!defaultValue) {
      return name;
    }

    return `${name}:${defaultValue}`;
  }, [defaultValue, name]);

  switch (type) {
    case AttributeType.text:
      return (
        <Box>
          <FormLabel>{label}</FormLabel>
          <OutlinedInput
            key={key}
            name={name}
            fullWidth={true}
            inputProps={data}
            defaultValue={defaultValue}
          />
        </Box>
      );
    case AttributeType.entityId:
      return (
        <Box>
          <FormLabel>{label}</FormLabel>
          <RIFInput key={key} name={name} data={data} defaultValue={defaultValue} />
        </Box>
      );
    case AttributeType.personId:
      return (
        <Box>
          <FormLabel>{label}</FormLabel>
          <RIFInput
            key={key}
            name={name}
            variant="cedula"
            data={data}
            defaultValue={defaultValue}
          />
        </Box>
      );
    case AttributeType.date:
      return (
        <Box>
          <FormLabel>{label}</FormLabel>
          <DateFieldInput key={key} name={name} data={data} defaultValue={defaultValue} />
        </Box>
      );
    case AttributeType.phoneNumber:
      return (
        <Box>
          <FormLabel>{label}</FormLabel>
          <PhoneNumberInput key={key} name={name} data={data} defaultValue={defaultValue} />
        </Box>
      );
    case AttributeType.oneOf:
      return (
        <Box>
          <FormLabel>{label}</FormLabel>
          <SelectorOneOf
            key={key}
            name={name}
            items={field.propiedades.opciones}
            data={data}
            defaultValue={defaultValue}
          />
        </Box>
      );
    case AttributeType.severalOf:
      return (
        <Box>
          <FormLabel>{label}</FormLabel>
          <SelectorSeveralOf
            key={key}
            name={name}
            items={field.propiedades.opciones}
            data={data}
            defaultValue={defaultValue}
          />
        </Box>
      );
    case AttributeType.list:
      return (
        <Grid alignItems="center" container>
          <Grid xs={6} item>
            <FormLabel>{label}</FormLabel>
          </Grid>
          <Grid xs={6} item>
            <Dropdown
              key={key}
              name={name}
              definition={field}
              data={data}
              defaultValue={defaultValue}
            />
          </Grid>
        </Grid>
      );
    case AttributeType.bool:
      return (
        <Grid alignItems="center" container>
          <Grid xs={6} item>
            <FormLabel>{label}</FormLabel>
          </Grid>
          <Grid xs={6} item>
            <Switch
              key={key}
              name={name}
              inputProps={data}
              defaultChecked={Boolean(defaultValue)}
            />
          </Grid>
        </Grid>
      );
    case AttributeType.file:
      const { aceptar = [] } = field.propiedades;

      const mimeTypes = aceptar?.filter(isMimeType) ?? [];
      return (
        <FileInput
          key={key}
          label={label}
          name={name}
          mimeTypes={mimeTypes}
          data={data}
          uploadPrefix={uploadPrefix}
          defaultValue={defaultValue}
        />
      );
    case AttributeType.numeric:
      // FIXME: use proper validation instead of native number type
      return (
        <Box>
          <FormLabel>{label}</FormLabel>
          <OutlinedInput
            key={key}
            name={name}
            fullWidth={true}
            inputProps={{ ...data, type: 'number' }}
            defaultValue={defaultValue}
          />
        </Box>
      );
    default:
      console.warn('Unhandled type: ', type);
      return <div />;
  }
};

export default ControlSelector;
