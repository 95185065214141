import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { Attribute } from '@s3comsecurity/foundations';
import ActionsCell from 'components/ActionsCell';
import React from 'react';
import { getAlignmentForFieldType } from 'utils/getAlignmentForFieldType';

interface Props {
  readonly columns: readonly Attribute[];
  readonly rows: readonly any[];

  onAction(action: 'view' | 'edit' | 'remove', itemId: string): void;
}

const InventoryTable: React.FC<Props> = (props: Props): React.ReactElement => {
  const { columns, rows } = props;
  const { onAction } = props;

  return (
    <TableContainer sx={styles.tableContainer}>
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            {columns.map(
              (field: Attribute): React.ReactElement => (
                <TableCell key={field.id} align={getAlignmentForFieldType(field.tipo)}>
                  <Typography variant="subtitle2" whiteSpace="pre-wrap">
                    {field.etiqueta}
                  </Typography>
                </TableCell>
              ),
            )}
            <TableCell width="80px" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(
            (item: Record<string, any>, index: number): React.ReactElement => (
              <TableRow key={index}>
                {columns.map(
                  (field: Attribute): React.ReactElement => (
                    <TableCell key={field.id} align={getAlignmentForFieldType(field.tipo)}>
                      <Typography whiteSpace="pre-wrap">{item[field.nombre]}</Typography>
                    </TableCell>
                  ),
                )}
                <TableCell>
                  <ActionsCell itemId={item.id} onAction={onAction} />
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InventoryTable;

const styles: Record<string, SxProps<Theme>> = {
  tableContainer: {
    flex: 1,
    overflowY: 'auto',
  },
};
