import classes from 'components/Stepper/components/step.module.scss';
import { StepProps } from 'components/Stepper/stepProps';
import React, { isValidElement } from 'react';

interface Props {
  readonly active: boolean;
  onSubmit(id: string, data: any): void;
}

function Step(props: React.PropsWithChildren<Props>): React.ReactElement {
  const { active, children: step, onSubmit } = props;
  const [fieldset, setFieldset] = React.useState<HTMLFieldSetElement | null>(null);

  if (!isValidElement<StepProps<any>>(step)) {
    throw new Error('invalid child for step');
  }

  React.useEffect((): void => {
    if (fieldset === null) {
      return;
    }

    const marker = 'data-skipped';
    if (!active) {
      const tabbable = fieldset.querySelectorAll(tabbableSelector);

      tabbable.forEach((child: Element): void => {
        child.setAttribute('tabindex', '-1');
        child.setAttribute(marker, 'true');
      });
    } else {
      const disabled = fieldset.querySelectorAll(`[${marker}]`);

      disabled.forEach((child: Element): void => {
        child.removeAttribute(marker);
        child.setAttribute('tabindex', '0');
      });
    }
  }, [active, fieldset]);

  return (
    <fieldset ref={setFieldset} tabIndex={-1} disabled={!active} className={classes.fieldset}>
      {React.cloneElement(step, { ...props, onSubmit: onSubmit })}
    </fieldset>
  );
}

export default Step;

const tabbableSelector = '[tabindex]:not([tabindex="-1"]), a';
