import { Box, Grid, SxProps, Theme } from '@mui/material';
import Buttons from 'components/Stepper/components/Buttons';
import Progress from 'components/Stepper/components/Progress';
import Step from 'components/Stepper/components/Step';
import { StepProps } from 'components/Stepper/stepProps';
import { styles } from 'components/Stepper/styles';
import { StepperStatus } from 'hooks/StepperManager';
import { useStepper } from 'hooks/useStepper';
import React, { Children as ReactChildren } from 'react';

interface Props {
  onCancel?(): void;
  onCompleted(data: any): void;
}

const Stepper: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const stepper = useStepper();

  const { children, onCancel, onCompleted } = props;
  const { activeStep } = stepper;

  const steps = ReactChildren.toArray(children);
  if (!steps.every(isStep)) {
    throw new Error('Stepper children must be Step');
  }

  const form = React.useMemo((): string => {
    const { name = '' } = steps[activeStep]?.props;
    return name;
  }, [activeStep, steps]);

  const back = React.useCallback((): void => {
    stepper.back();
  }, [stepper]);

  const stepWrapperStyle = React.useMemo((): SxProps<Theme> => {
    return { ...styles.stepsWrapper, transform: `translateX(-${100 * activeStep}%)` };
  }, [activeStep]);

  const handleSubmit = React.useCallback(
    (id: string, data: any): void => {
      if (activeStep === steps.length - 1) {
        stepper.done(id, data);
      } else {
        stepper.next(id, data);
      }
    },
    [activeStep, stepper, steps.length],
  );

  React.useEffect((): void => {
    if (stepper.status === StepperStatus.completed) {
      onCompleted(stepper.data);
    }
  }, [onCompleted, stepper.data, stepper.status]);

  return (
    <Grid height="100%" container>
      <Grid xs={3} item>
        <Progress steps={steps} active={activeStep} />
      </Grid>

      <Grid sx={styles.contentStepper} xs={12} sm={9} item>
        <Box sx={styles.steps}>
          <Box sx={stepWrapperStyle}>
            {steps.map(
              (step: React.ReactElement<StepProps<any>>, index: number): React.ReactElement => {
                const { props } = step;
                const { name } = props;

                // FIXME: Need to lazily render content, but first need a way
                //        to keep state up to date. Which is not the responsibility
                //        of the stepper
                return (
                  <Box key={name} sx={styles.step}>
                    <Step active={index === activeStep} onSubmit={handleSubmit}>
                      {step}
                    </Step>
                  </Box>
                );
              },
            )}
          </Box>
        </Box>
        <Buttons
          active={activeStep}
          last={steps.length - 1}
          form={form}
          onCancel={onCancel}
          onBack={back}
        />
      </Grid>
    </Grid>
  );
};

export default Stepper;

const isStep = (node: React.ReactNode): node is React.ReactElement<StepProps<any>> => {
  return React.isValidElement<StepProps<any>>(node);
};
