import { SolicitanteJuridicoRepresentanteLegal } from 'components/CompleteRegistration/forms/PasosComunes/JuridicoRepresentanteLegal/schema';
import { Attribute, AttributeType } from 'components/Form/types';

const fields: readonly Attribute<SolicitanteJuridicoRepresentanteLegal>[] = [
  {
    name: 'rif',
    type: AttributeType.rif,
    label: 'RIF del Representante Legal',
    required: true,
  },
  {
    name: 'nombre',
    type: AttributeType.text,
    label: 'Nombre del Representante Legal',
    required: true,
  },
];

export default fields;
