import 'components/ApplicationMenu/components/SubMenu/menu.css';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { Procedure, Service } from '@s3comsecurity/user-configuration';
import { useTypedParams } from 'hooks/useTypedParams';
import { ProcedureGroupParameters } from 'parameters/service';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useProceduresQuery } from 'redux/API';

interface Props {
  readonly service: Service;
}

const SubMenu: React.FC<Props> = (props: Props): React.ReactElement => {
  const { serviceId } = useTypedParams<Partial<ProcedureGroupParameters>>(
    ProcedureGroupParameters.mayBeFromUnknown,
  );
  const { service } = props;
  const { data = { tramites: [] } } = useProceduresQuery(service.id);
  const { tramites = [] } = data;
  const [expanded, setExpanded] = React.useState<boolean>(false);

  React.useEffect((): void => {
    setExpanded(serviceId === service.id);
  }, [service.id, serviceId]);

  const toggleOpen = React.useCallback((): void => {
    setExpanded((previousOpen: boolean): boolean => {
      return !previousOpen;
    });
  }, []);

  return (
    <>
      <ListItem sx={styles.header} component="div">
        <ListItemButton component={NavLink} to={`/tramites/${service.id}`} onClick={toggleOpen}>
          <ListItemText>
            <Typography variant="subtitle1" whiteSpace="pre-wrap">
              {service.etiqueta}
            </Typography>
          </ListItemText>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
        <List>
          {tramites?.map((item: Procedure): React.ReactElement => {
            return (
              <ListItem key={item.id} disableGutters={true}>
                <ListItemButton component={NavLink} to={`/tramites/${service.id}/${item.id}`}>
                  <ListItemText
                    primary={
                      <Typography variant="body1" whiteSpace="pre-wrap">
                        {item.etiqueta}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default SubMenu;

const styles: Record<string, SxProps<Theme>> = {
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'background.default',
  },
};
