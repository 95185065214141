import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'redux/store';
import Selector from 'routes/selector';
import theme from 'styles/theme';

function App(): React.ReactElement {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Selector />
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
