import { ArrowBack, ArrowForward, Check } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';

interface Props {
  readonly active: number;
  readonly last: number;
  readonly form: string;

  onCancel?(): void;
  onBack(): void;
}

const Buttons: React.FC<Props> = (props: Props): React.ReactElement => {
  const { active, form, last, onBack, onCancel } = props;

  return (
    <Box display="flex" gap={2} alignItems="center" marginTop={4}>
      <Button variant="contained" color="secondary" disableElevation={true} onClick={onCancel}>
        Cancelar
      </Button>
      <Box flex={1} />
      <Button variant="text" startIcon={<ArrowBack />} disabled={active === 0} onClick={onBack}>
        Atrás
      </Button>
      {active < last ? (
        <Button type="submit" form={form} variant="contained" endIcon={<ArrowForward />}>
          Siguiente
        </Button>
      ) : (
        <Button type="submit" form={form} variant="contained" startIcon={<Check />}>
          Enviar
        </Button>
      )}
    </Box>
  );
};

export default Buttons;
