import { SxProps, Theme } from '@mui/material';

const stepperCircleSize = 25;
export const styles: Record<string, SxProps<Theme>> = {
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  itemContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    zIndex: 1,
    cursor: 'pointer',
  },
  icon: {
    width: stepperCircleSize,
    height: stepperCircleSize,
  },
  itemLink: {
    position: 'relative',
    backgroundColor: 'secondary.main',
    marginLeft: `${(stepperCircleSize - 3) / 2}px`,
    my: -1,
    width: 3,
    flex: 1,
    minHeight: 60,
    zIndex: 0,
  },
  line: {
    position: 'absolute',
    left: 0,
    top: '-1px',
    right: 0,
    bottom: '-1px',
    maxHeight: 0,
    backgroundColor: 'success.main',
    transition: 'max-height 0.20s ease-in',
  },
};
