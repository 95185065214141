import { Service } from '@s3comsecurity/user-configuration';
import Procedures from 'components/ApplicationMenu/components/HomeMenu/Procedures';
import DashboardContainer from 'components/DashboardContainer';
import React from 'react';
import { useServicesQuery } from 'redux/API';

const HomeMenu: React.FC = (): React.ReactElement => {
  const { data: { servicios } = { servicios: [] } } = useServicesQuery();

  if (servicios === null) {
    return <></>;
  }

  return (
    <DashboardContainer>
      {servicios.map(
        (item: Service): React.ReactElement => (
          <Procedures key={item.id} serviceId={item.id} serviceName={item.nombre} />
        ),
      )}
    </DashboardContainer>
  );
};

export default HomeMenu;
