import React from 'react';
import { FieldError } from 'types/fieldError';

export const FormErrorsContext = React.createContext<Partial<Record<any, FieldError>>>({});

interface Props<T> {
  readonly errors: Partial<Record<keyof T, FieldError>>;
}

export function FormErrorsProvider<T>(
  props: React.PropsWithChildren<Props<T>>,
): React.ReactElement {
  const { errors, children } = props;

  return <FormErrorsContext.Provider value={errors}>{children}</FormErrorsContext.Provider>;
}
