import { LoginRequest } from '@s3comsecurity/user-auth';
import * as yup from 'yup';
import { validarIdentificador } from 'yup/validators/validarIdentificador';

export default yup.object<LoginRequest>().shape({
  rif: yup
    .string() //
    .test('identificador', '', validarIdentificador)
    .required('El RIF es obligatorio'),
  claveSecreta: yup
    .string() //
    .required('La contraseña es obligatoria'),
});
