import * as yup from 'yup';

export interface SolicitanteJuridicoDatos {
  readonly nombre: string;
  readonly acronimo: string;
  readonly registroMercantil: string;
  readonly tomo: string;
  readonly folio: string;
  readonly numeroRegistro: string;
  readonly fechaRegistro: string;
}

export default yup.object<SolicitanteJuridicoDatos>().shape({
  nombre: yup
    .string() //
    .required('Nombre Jurídico es obligatorio'),
  acronimo: yup
    .string() //
    .required('Acrónimo es obligatorio'),
  registroMercantil: yup
    .string() //
    .required('Registro Mercantil es obligatorio'),
  tomo: yup
    .string() //
    .required('Tomo es obligatorio'),
  folio: yup
    .string() //
    .required('Folio es obligatorio'),
  numeroRegistro: yup
    .string() //
    .required('Número de Registro es obligatorio'),
  fechaRegistro: yup
    .string() //
    .required('Fecha de Registro es obligatorio'),
});
