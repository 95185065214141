import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authenticationSelector } from 'redux/authenticationReducer';
import AuthenticationRoutes from 'routes/authentication';
import ProtectedRoutes from 'routes/protected';
import { StorageKey } from 'storageKeys';

const Selector = (): React.ReactElement => {
  const { token } = useSelector(authenticationSelector);
  const navigate = useNavigate();

  React.useEffect((): void => {
    const storageToken = sessionStorage.getItem(StorageKey.accessToken);
    if (token !== storageToken) {
      sessionStorage.removeItem(StorageKey.accessToken);
      navigate('/');
    }
  }, [navigate, token]);

  if (!token) {
    return <AuthenticationRoutes />;
  } else {
    return <ProtectedRoutes />;
  }
};

export default Selector;
