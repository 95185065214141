import { Box } from '@mui/material';
import headband from 'assets/images/cintillo.jpg';
import classes from 'components/Headband/headband.module.scss';
import React from 'react';

const Headband: React.FC = (): React.ReactElement => {
  return (
    <Box sx={containerStyle}>
      <img src={headband} className={classes.image} alt="headband" width="100%" />
    </Box>
  );
};

export default Headband;

const containerStyle = {
  display: { xs: 'none', sm: 'none' },
  zIndex: 1,
};
