import classes from 'components/Form/form.module.scss';
import { errorsReducer, formToObject } from 'components/Form/helpers';
import { FormErrorsProvider } from 'context/formErrors';
import React from 'react';
import { FieldError } from 'types/fieldError';
import * as yup from 'yup';

interface GroupProps<T> {
  readonly id: string;
  readonly schema: yup.Schema;
  readonly disabled?: boolean;
  readonly errors: Partial<Record<keyof T, FieldError>>;

  readonly autoComplete?: boolean;

  onSubmit(data: T): void;
  onError(errors: Partial<Record<keyof T, FieldError>>): void;
  onClearErrors(): void;
}

type Props<T> = GroupProps<T>;

function Form<T>(props: React.PropsWithChildren<Props<T>>): React.ReactElement {
  const { id, disabled = false, autoComplete = true, schema, errors } = props;
  const { onSubmit, onError, onClearErrors } = props;

  const formRef = React.useRef<HTMLFormElement>(null);
  const trySubmit = React.useCallback(async (): Promise<void> => {
    const { current: form } = formRef;

    if (form === null) {
      return;
    }

    const data = formToObject<T>(form);

    try {
      onClearErrors();
      await schema.validate(data, { abortEarly: false });
      // Ideally we have all the fields
      onSubmit(data);
    } catch (error: any) {
      if ('inner' in error && Array.isArray(error.inner)) {
        const errors = error.inner;
        onError(errors.reduce(errorsReducer, {}));
      } else {
        console.warn(error);
      }
    }
  }, [onClearErrors, onError, onSubmit, schema]);

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      await trySubmit();
    },
    [trySubmit],
  );

  const autoCompleteValue = React.useMemo((): 'on' | 'off' => {
    if (autoComplete) {
      return 'on';
    } else {
      return 'off';
    }
  }, [autoComplete]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLFieldSetElement>): void => {
      const { target } = event;
      const { name } = target;

      if (name === '') {
        // This is a utility input only, not the input with the value
        return;
      }

      if (errors) {
        delete errors[name as keyof T];
        // Emit the new errors set
        onError({ ...errors });
      }
    },
    [errors, onError],
  );

  return (
    <form
      id={id}
      ref={formRef}
      autoComplete={autoCompleteValue}
      className={classes.form}
      onSubmit={handleSubmit}
    >
      <fieldset className={classes.fieldset} disabled={disabled} onInput={handleChange}>
        <FormErrorsProvider errors={errors}>{props.children}</FormErrorsProvider>
      </fieldset>
    </form>
  );
}

export default Form;
