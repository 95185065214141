import { RegisterRequest } from '@s3comsecurity/user-auth';
import * as yup from 'yup';
import { TestContext, ValidationError } from 'yup';
import { validarIdentificador } from 'yup/validators/validarIdentificador';

export enum PasswordRequirements {
  minimumLength = 1 << 0,
  containsSpecialCharacters = 1 << 1,
  containsAtLeastOneNumber = 1 << 2,
  containsAtLeastOneLowerCaseLetter = 1 << 3,
  containsAtLeastOneUpperCaseLetter = 1 << 4,
}

interface OptionsContext {
  readonly isInEditMode: boolean;
}

export enum CustomValidationType {
  passwordRequirements = 'passwordRequirements',
}

export default yup.object<RegisterRequest>().shape({
  rif: yup
    .string() //
    .test('rif', '', validarIdentificador)
    .required('El rif es obligatorio'),
  email: yup
    .string() //
    .email()
    .required('El correo electrónico es obligatorio'),
  claveSecreta: yup
    .string() //
    .test((value: string | undefined, testContext: TestContext): boolean | ValidationError => {
      const { context: optionsContext = {} } = testContext.options;

      if (value === undefined) {
        return true;
      }

      if (value.length === 0) {
        if (isOptionsContext(optionsContext) && optionsContext.isInEditMode) {
          return true;
        }

        return testContext.createError({ message: 'La contraseña es obligatoria' });
      }

      const regularExpressions = {
        '.{8,}': PasswordRequirements.minimumLength,
        '[!@#$%&]': PasswordRequirements.containsSpecialCharacters,
        '\\d': PasswordRequirements.containsAtLeastOneNumber,
        '[a-z]': PasswordRequirements.containsAtLeastOneLowerCaseLetter,
        '[A-Z]': PasswordRequirements.containsAtLeastOneUpperCaseLetter,
      };

      const errorFlag = Object.entries(regularExpressions).reduce(
        (flag: number, [regexString, requirement]: [string, PasswordRequirements]): number => {
          const regexp = new RegExp(regexString);
          if (regexp.test(value)) {
            return flag;
          } else {
            return flag | (requirement as number);
          }
        },
        0,
      );

      if (errorFlag === 0) {
        return true;
      }

      return testContext.createError({
        message: getBestMessageForError(errorFlag),
        type: CustomValidationType.passwordRequirements,
        params: {
          value: errorFlag,
        },
      });
    })
    .required('La contraseña es obligatoria'),
  confirmaClaveSecreta: yup
    .string() //
    .oneOf([yup.ref('claveSecreta')], 'Las contraseñas no coinciden')
    .required('Confirmar contraseña es obligatoria'),
});

const PasswordNoSpecialCharactersError = 'Debe tener al menus uno de (! @ # $ % &)';
const PasswordNoNumberError = 'Debe tener al menos un número';
const PasswordNoLowerCaseLettersError = 'Debe tener al menos una letra minúscula';
const PasswordNoUpperCaseLettersError = 'Debe tener al menos una letra mayúscula';
const PasswordMustHaveAtLeast8Symbols = 'Debe tener al menos 8 letras y símbolos';

const getBestMessageForError = (errorFlag: number): string => {
  if (
    (errorFlag & PasswordRequirements.containsSpecialCharacters) ===
    PasswordRequirements.containsSpecialCharacters
  ) {
    return PasswordNoSpecialCharactersError;
  } else if (
    (errorFlag & PasswordRequirements.containsAtLeastOneNumber) ===
    PasswordRequirements.containsAtLeastOneNumber
  ) {
    return PasswordNoNumberError;
  } else if (
    (errorFlag & PasswordRequirements.containsAtLeastOneLowerCaseLetter) ===
    PasswordRequirements.containsAtLeastOneLowerCaseLetter
  ) {
    return PasswordNoLowerCaseLettersError;
  } else if (
    (errorFlag & PasswordRequirements.containsAtLeastOneUpperCaseLetter) ===
    PasswordRequirements.containsAtLeastOneUpperCaseLetter
  ) {
    return PasswordNoUpperCaseLettersError;
  } else if (
    (errorFlag & PasswordRequirements.minimumLength) ===
    PasswordRequirements.minimumLength
  ) {
    return PasswordMustHaveAtLeast8Symbols;
  }

  return 'Your password does not conform to the requirements';
};

const isOptionsContext = (optionsContext: any): optionsContext is OptionsContext => {
  return 'isInEditMode' in optionsContext && typeof optionsContext.isInEditMode === 'boolean';
};
