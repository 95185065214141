import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { ControlData } from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import React from 'react';

interface Props {
  readonly name: string;
  readonly items: readonly string[];
  readonly data?: ControlData;
  readonly defaultValue: readonly string[];
}

const SelectorSeveralOf: React.FC<Props> = (props: Props): React.ReactElement => {
  const { items, data, defaultValue } = props;

  return (
    <Grid mt={1} container>
      {items.map(
        (item: string): React.ReactElement => (
          <Grid key={item} xs={6} item>
            <FormControlLabel
              control={
                <Checkbox
                  value={item}
                  inputProps={data}
                  defaultChecked={defaultValue.includes(item)}
                />
              }
              label={item}
            />
          </Grid>
        ),
      )}
    </Grid>
  );
};

export default SelectorSeveralOf;
