import { Box, CircularProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Enumerable } from '@s3comsecurity/foundations';
import { ControlData } from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import { useFormForElementReference } from 'hooks/useFormForElementReference';
import { useFormSlice } from 'hooks/useFormSlice';
import { useNamedEndpoint } from 'hooks/useNamedEndpoint';
import React from 'react';
import api from 'redux/API';

interface Props {
  readonly name: string;
  readonly source: string;

  readonly parametros?: readonly string[];
  readonly data?: ControlData;

  readonly defaultValue?: string;
}

const DropdownWithSource: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, source, parametros, data, defaultValue } = props;

  const [input, setInput] = React.useState<HTMLInputElement | null>(null);
  const form = useFormForElementReference(input);
  const parameters = useFormSlice(parametros, form);

  const [localValue, setLocalValue] = React.useState<string>('');
  const [items, status] = useNamedEndpoint(api, source, parameters);

  const handleChange = React.useCallback((event: SelectChangeEvent) => {
    const { value } = event.target;
    setLocalValue(value);
  }, []);

  React.useEffect((): void => {
    setLocalValue('');
  }, [parameters]);

  React.useEffect((): void => {
    // Manually dispatch change event
    input?.dispatchEvent(new Event('input', { bubbles: true, cancelable: false }));
  }, [input, localValue]);

  const currentIcon = React.useMemo((): React.FunctionComponent | undefined => {
    if (status.isFetching) {
      return (): React.ReactElement => (
        <Box width={20} height={20} mx={1}>
          <CircularProgress size={20} thickness={6} />
        </Box>
      );
    } else {
      return undefined;
    }
  }, [status]);

  React.useEffect((): void => {
    if (!defaultValue) {
      return;
    }

    setLocalValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Select
        IconComponent={currentIcon}
        value={localValue}
        fullWidth={true}
        onChange={handleChange}
      >
        {items.length === 0 ? (
          <MenuItem value="" disabled={true}>
            No hay datos
          </MenuItem>
        ) : (
          items.map(
            (item: Enumerable): React.ReactElement => (
              <MenuItem key={item.id} value={item.id}>
                {item.nombre}
              </MenuItem>
            ),
          )
        )}
      </Select>

      <input ref={setInput} type="hidden" name={name} value={Number(localValue)} {...data} />
    </>
  );
};

export default DropdownWithSource;
