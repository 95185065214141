import { ArrowDropDown, ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import { ViewType } from 'components/Calendar/types/viewType';
import React from 'react';

interface Props {
  readonly month: string;
  readonly year: number;
  readonly currentView: ViewType;

  onPreviousMonth(): void;
  onNextMonth(): void;
  onExpandYear(): void;
}

const Header: React.FC<Props> = (props: Props): React.ReactElement => {
  const { month, year, currentView, onExpandYear, onPreviousMonth, onNextMonth } = props;

  const arrowStyle = React.useMemo((): SxProps<Theme> => {
    switch (currentView) {
      case ViewType.month:
        return {
          transition: 'all 0.25s',
        };
      case ViewType.year:
        return {
          transition: 'all 0.25s',
          transform: 'rotate(180deg)',
        };
    }
  }, [currentView]);

  return (
    <Box sx={containerStyle}>
      <Box display="flex" alignItems="center" gap={1} px={0.5}>
        <Box>
          <Typography>{month}</Typography>
        </Box>
        <Box>
          <Typography>{year}</Typography>
        </Box>
        <IconButton size="small" onClick={onExpandYear}>
          <ArrowDropDown sx={arrowStyle} />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <IconButton size="small" onClick={onPreviousMonth}>
          <ArrowLeft />
        </IconButton>
        <IconButton size="small" onClick={onNextMonth}>
          <ArrowRight />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Header;

const containerStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
