export const numericTransform = (value: string | number): number | undefined => {
  if (typeof value === 'number') {
    if (isNaN(value) || value === -1 || value === 0) {
      return undefined;
    }
    return value;
  }

  return undefined;
};
