import ProceduresCards from 'components/ProceduresCards';
import React from 'react';
import { useProceduresQuery } from 'redux/API';

interface Props {
  readonly serviceId: number;
  readonly serviceName: string;
}

const Procedures: React.FC<Props> = (props: Props): React.ReactElement => {
  const { serviceId, serviceName } = props;
  const { data: { tramites = [] } = { tramites: [] } } = useProceduresQuery(serviceId);

  return <ProceduresCards serviceName={serviceName} serviceId={serviceId} procedures={tramites} />;
};

export default Procedures;
