import { Box, SxProps, Theme } from '@mui/material';
import logo from 'assets/images/logo.jpg';
import Headband from 'components/Headband';
import React from 'react';
import { Outlet } from 'react-router-dom';

const Layout: React.FC = (): React.ReactElement => {
  return (
    <Box sx={styles.container}>
      <Headband />
      <Box sx={styles.outlet}>
        <Box display="flex" justifyContent="center">
          <img src={logo} alt="logo" width={260} />
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  },
  outlet: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: { xs: '100%', sm: '360px' },
    backgroundColor: 'white',
    py: 4,
    zIndex: 1,
  },
};
