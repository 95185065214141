import { Box, SxProps, Theme } from '@mui/material';
import { Attribute, AttributeType, ShowCondition } from '@s3comsecurity/foundations';
import classes from 'components/RequestLayout/components/DynamicFormRenderer/form.module.scss';
import ControlSelector from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import { useFormSlice } from 'hooks/useFormSlice';
import React from 'react';

interface Props {
  readonly fields: readonly Attribute[];
  readonly formName: string;
  readonly uploadPrefix: string;
  readonly defaultValues: Readonly<Record<string, any>>;
  readonly autoComplete?: boolean;
}

const booleanAttributesNames = (atributos: readonly Attribute[] | null): readonly string[] => {
  return (
    atributos
      ?.filter((atributo: Attribute): boolean => atributo.tipo === AttributeType.bool)
      .map((atributo: Attribute): string => atributo.nombre) ?? []
  );
};

const DynamicFormRenderer: React.FC<Props> = (props: Props): React.ReactElement => {
  const { formName, fields, defaultValues, autoComplete = true, uploadPrefix } = props;

  const [form, setForm] = React.useState<HTMLFormElement | null>(null);

  const names = booleanAttributesNames(fields);
  const values = useFormSlice(names, form);

  const filterHidden = React.useCallback(
    (atributo: Attribute): boolean => {
      const { mostrar } = atributo.propiedades;
      // FIXME: true por defecto es algo idiota! si queremos hacer algo no por defecto es ocultar,
      //        entonces esto debería llamarse ocultar, no mostrar
      if (!mostrar) {
        // True por defecto
        return true;
      }

      return (
        mostrar.every((item: ShowCondition): boolean => {
          const currentValue = defaultValues?.[item.cuando] ?? values?.[item.cuando];
          return currentValue === item.es;
        }) ?? true
      );
    },
    [values, defaultValues],
  );

  const autoCompleteValue = React.useMemo(
    (): 'on' | 'off' => (autoComplete ? 'on' : 'off'),
    [autoComplete],
  );

  return (
    <Box sx={styles.container}>
      <form
        key={formName}
        ref={setForm}
        name={formName}
        autoComplete={autoCompleteValue}
        className={classes.form}
      >
        {fields?.filter(filterHidden).map((field: Attribute): React.ReactElement => {
          return (
            <Box key={field.nombre} sx={styles.field}>
              <ControlSelector
                field={field}
                defaultValue={defaultValues[field.nombre]}
                uploadPrefix={uploadPrefix}
              />
            </Box>
          );
        })}
      </form>
    </Box>
  );
};

const styles: Record<string, SxProps<Theme>> = {
  container: {
    flex: 1,
    width: '100%',
  },
  field: {
    px: 2,
    py: 1,
    my: 2,
    borderRadius: 2,
    backgroundColor: '#f8f9fb',
    display: 'block',
  },
};

export default DynamicFormRenderer;
