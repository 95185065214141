import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import ScrollArea from 'components/ScrollArea';
import React from 'react';

interface Props {
  readonly title: string;
  readonly footer?: React.ReactElement;

  onClose(): void;
}

const ModalContainer: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const { title, children, footer, onClose } = props;

  return (
    <Box sx={styles.modal}>
      <Box sx={styles.header}>
        <Typography variant="h4">{title}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={styles.body}>
        <ScrollArea>{children}</ScrollArea>
      </Box>
      <Box sx={styles.footer}>{footer}</Box>
    </Box>
  );
};

export default ModalContainer;

const styles = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    minHeight: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'background.paper',
    h4: {
      margin: 0,
      px: 3,
    },
  },
  body: {
    flex: 1,
    flexShrink: 1,
    px: 3,
    overflowY: 'auto',
  },
  footer: {},
};
