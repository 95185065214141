import { SxProps, Theme } from '@mui/material';

const leftMenuWidth = 300;

const toolButtonStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 32,
  height: 32,
  borderRadius: 16,
};

export const styles: Record<string, SxProps<Theme>> = {
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    px: 2,
    gap: 1,
  },
  outlet: {
    flexGrow: 1,
    height: 0,
  },
  headBand: {
    position: 'sticky',
    top: 0,
    display: { xs: 'none', sm: 'block' },
    zIndex: 1,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: leftMenuWidth,
    top: 0,
    bottom: 0,
    right: 0,
  },
  leftMenu: {
    position: 'fixed',
    left: {
      xs: '-100%',
      sm: 0,
    },
    top: 0,
    bottom: 0,
    width: leftMenuWidth,
    transition: 'left 0.3s ease-in-out',
    height: '100%',
    backgroundColor: 'primary.dark',
    color: 'primary.contrastText',
    zIndex: 1,
  },
  outletContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  toolButton: toolButtonStyle,
  menuButton: {
    ...toolButtonStyle,
    display: {
      xs: undefined,
      sm: 'none',
    },
  },
  alertBox: {
    flex: 1,
    m: 1,
  },
};
