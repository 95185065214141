import fields from 'components/CompleteRegistration/forms/Natural/steps/DatosPersonales/fields';
import schema, {
  SolicitanteNaturalDatos,
} from 'components/CompleteRegistration/forms/Natural/steps/DatosPersonales/schema';
import Form from 'components/Form';
import Fieldset from 'components/Form/components/Fieldset';
import { StepProps } from 'components/Stepper/stepProps';
import React from 'react';
import { FieldError } from 'types/fieldError';

type Props = StepProps<SolicitanteNaturalDatos>;

const DatosPersonales: React.FC<Props> = (props: Props): React.ReactElement => {
  const [errors, setErrors] = React.useState<
    Partial<Record<keyof SolicitanteNaturalDatos, FieldError>>
  >({});
  const { name, onSubmit } = props;

  const handleClearErrors = React.useCallback((): void => {
    setErrors({});
  }, []);

  const handleSubmission = React.useCallback(
    (data: SolicitanteNaturalDatos): void => {
      onSubmit?.(name, data);
    },
    [name, onSubmit],
  );

  return (
    <Form<SolicitanteNaturalDatos>
      id={name}
      schema={schema}
      errors={errors}
      autoComplete={false}
      onSubmit={handleSubmission}
      onClearErrors={handleClearErrors}
      onError={setErrors}
    >
      <Fieldset fields={fields} />
    </Form>
  );
};

export default DatosPersonales;
