import { Alert, Box } from '@mui/material';
import React from 'react';

interface Props {
  readonly visible: boolean;
}

const RegistrationSuccessBox: React.FC<Props> = (props: Props): React.ReactElement => {
  const { visible } = props;
  if (!visible) {
    return <></>;
  }

  return (
    <Box minWidth="100%" marginBottom={2}>
      <Alert severity="success">
        Hemos creado su cuenta. Ingrese su contraseña para iniciar sesión.
      </Alert>
    </Box>
  );
};

export default RegistrationSuccessBox;
