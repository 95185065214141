import { Box, Grid, Typography } from '@mui/material';
import { Procedure } from '@s3comsecurity/user-configuration';
import CardMenu from 'components/ApplicationMenu/components/CardMenu';
import React from 'react';

interface Props {
  readonly serviceName: string;
  readonly serviceId: number;
  readonly procedures: readonly Procedure[];
}

const ProceduresCards: React.FC<Props> = (props: Props): React.ReactElement => {
  const { serviceId, procedures, serviceName } = props;

  // FIXME: GRPC understand this as null, the UI should not be interested in GRPC stuff
  //        and we should provide a better representation of the data.
  //        Using protocol buffers has advantages, but it also has disadvantages.
  if (procedures === null || procedures.length === 0) {
    return <></>;
  }

  return (
    <Box px={3}>
      <Box position="sticky" top={0} zIndex={1} bgcolor="#f8f8f8" py={1}>
        <Typography variant="subtitle1" fontSize={20} fontWeight={700}>
          {serviceName}
        </Typography>
      </Box>
      <Grid container>
        {procedures.map(
          (item: Procedure): React.ReactElement => (
            <Grid key={item.id} md={4} xs={12} sm={6} item>
              <Box p={1}>
                <CardMenu item={item} url={`/tramites/${serviceId}/${item.id}`} />
              </Box>
            </Grid>
          ),
        )}
      </Grid>
    </Box>
  );
};

export default ProceduresCards;
