export const isAcceptableNumericKey = (key: string): boolean => {
  switch (key) {
    case 'Delete':
    case 'Backspace':
    case 'ArrowLeft':
    case 'ArrowRight':
    case 'End':
    case 'Home':
    case 'Tab':
    case 'Enter':
    case 'Return':
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case '0':
      return true;
    default:
      return false;
  }
};
