import { AttributeType } from '@s3comsecurity/foundations';
import { StepProps } from 'components/Stepper/stepProps';
import React from 'react';

export const isStep = (
  node: Exclude<React.ReactNode, null | boolean | undefined>,
): node is React.ReactElement<StepProps<any>> => {
  return React.isValidElement<StepProps<any>>(node);
};

const getName = (element: Node | Element | RadioNodeList): string => {
  if (element instanceof Element) {
    const name = element.getAttribute('name');
    if (!name) {
      throw new Error('Name not found');
    }

    return name;
  } else if (element instanceof RadioNodeList) {
    const array = Array.from(element);
    if (array.length === 0) {
      throw new Error('Name not found');
    }

    return getName(array[0]);
  } else if (element.nodeType === Node.ELEMENT_NODE) {
    return getName(element as Element);
  } else {
    throw new Error('Name not found');
  }
};

const getElementAttribute = (
  element: Node | Element | RadioNodeList,
  attribute: string,
): string => {
  if (element instanceof Element) {
    const value = element.getAttribute(attribute);
    if (!value) {
      throw new Error(`Attribute not found: ${attribute}`);
    }

    return value;
  } else if (element instanceof RadioNodeList) {
    const array = Array.from(element);
    if (array.length === 0) {
      throw new Error(`Attribute not found: ${attribute}`);
    }

    return getElementAttribute(array[0], attribute);
  } else if (element.nodeType === Node.ELEMENT_NODE) {
    return getElementAttribute(element as Element, attribute);
  } else {
    throw new Error(`Attribute not found: ${attribute}`);
  }
};

const formattedValue = (
  value: string | boolean | number,
  element: Element | RadioNodeList,
): {
  readonly key: string;
  readonly value: any;
} => {
  const type = getElementAttribute(element, 'data-type');
  const name = getName(element);

  switch (type) {
    case AttributeType.numeric:
      if (typeof value === 'number') {
        return { key: name, value: value };
      } else if (!isNaN(Number(value))) {
        return { key: name, value: Number(value) };
      } else {
        throw new Error('Numeric attribute has non numeric value');
      }
    case AttributeType.bool:
      if (typeof value === 'string') {
        return { key: name, value: ['on', 'true'].includes(value.toLowerCase()) };
      } else if (typeof value === 'boolean') {
        return { key: name, value: value };
      } else {
        throw new Error('Boolean value cannot be inferred from given value');
      }
    case AttributeType.file:
      if (typeof value !== 'string') {
        throw new Error('File attribute has non string value');
      }

      if (value.trim() === '') {
        return { key: name, value: undefined };
      }

      return { key: name, value: value };
    default:
      return { key: name, value: value };
  }
};

export const formToObject = (form: HTMLFormElement): any => {
  const { elements } = form;
  const formData = new FormData(form);

  return Array.from(formData.entries()).reduce(function (
    object: Readonly<Record<string, any>>,
    [name, inputValue]: [string, any],
  ): Readonly<Record<string, any>> {
    const element = elements.namedItem(name);
    if (!element) {
      console.warn(`Element ${name} not found`);
      return object;
    }

    const { key, value } = formattedValue(inputValue, element);

    return { ...object, [key]: value };
  }, {});
};
