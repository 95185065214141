import React from 'react';

interface Props {
  readonly status: string;
}

const RequestStatus: React.FC<Props> = (_: Props): React.ReactElement => {
  // const { status } = props;
  //
  // return (
  //   <Box display="flex" alignItems="center" gap={1} px={2}>
  //     <Timelapse color={colors[status]} />
  //     <Typography color={colors[status]}>{labels[status]}</Typography>
  //   </Box>
  // );
  return <></>;
};

export default RequestStatus;
