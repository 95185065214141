import { StepperContext, StepperManager } from 'context/stepper';
import React from 'react';

export const useStepper = (): StepperManager => {
  const context = React.useContext<StepperManager | null>(StepperContext);
  if (context === null) {
    throw new Error('useStepper must be used within a StepperProvider');
  }

  return context;
};
