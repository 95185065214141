import { ArrowBack, ArrowForward, Check } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';

interface Props {
  readonly active: number;
  readonly last: number;
  readonly form: string | undefined;

  onNext(): void;
  onCancel?(): void;
  onBack(): void;
  onDone(): void;
}

const Buttons: React.FC<Props> = (props: Props): React.ReactElement => {
  const { active, form, last } = props;
  const { onBack, onNext, onCancel, onDone } = props;

  return (
    <Box display="flex" position="sticky" bottom={0} gap={2} alignItems="center" py={2}>
      <Button variant="contained" color="secondary" disableElevation={true} onClick={onCancel}>
        Cancelar
      </Button>
      <Box flex={1} />
      <Button variant="text" startIcon={<ArrowBack />} disabled={active === 0} onClick={onBack}>
        Atrás
      </Button>
      {active < last ? (
        <Button
          type="button"
          form={form}
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={onNext}
        >
          Siguiente
        </Button>
      ) : (
        <Button
          type="submit"
          form={form}
          variant="contained"
          startIcon={<Check />}
          onClick={onDone}
        >
          Enviar
        </Button>
      )}
    </Box>
  );
};

export default Buttons;
