import { Box, FormLabel, Grid, OutlinedInput } from '@mui/material';
import { RegisterRequest } from '@s3comsecurity/user-auth';
import AuthErrorBox from 'components/AuthErrorBox';
import Form from 'components/Form';
import FormErrorHelperText from 'components/FormError';
import PasswordInput from 'components/PasswordInput';
import RIFInput from 'components/RIFInput';
import Spinner from 'components/Spinner';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegisterMutation } from 'redux/API';
import { FieldError } from 'types/fieldError';
import { PrefillType } from 'types/prefillType';
import schema from 'yup/schemas/register';

const SignUp: React.FC = (): React.ReactElement => {
  const [errors, setErrors] = React.useState<Partial<Record<keyof RegisterRequest, FieldError>>>(
    {},
  );
  const [register, registerStatus] = useRegisterMutation();
  const navigate = useNavigate();

  const handleClearErrors = React.useCallback((): void => {
    setErrors({});
  }, []);

  const handleSubmit = React.useCallback(
    (data: RegisterRequest): void => {
      register(data);
    },
    [register],
  );

  React.useEffect((): void => {
    if (registerStatus.isSuccess) {
      const {
        data: { usuario },
      } = registerStatus;

      navigate('/', {
        state: {
          type: PrefillType.newUser,
          data: usuario.rif,
        },
      });
    }
  }, [navigate, registerStatus]);

  return (
    <Box position="relative">
      <Form
        id="register"
        schema={schema}
        errors={errors}
        autoComplete={false}
        onSubmit={handleSubmit}
        onClearErrors={handleClearErrors}
        onError={setErrors}
      >
        <Grid flexDirection="column" alignItems="center" container>
          <AuthErrorBox error={registerStatus.error} />
          <Box width="100%" marginBottom={2}>
            <FormLabel>Registro de Información Fiscal (RIF)</FormLabel>
            <RIFInput name="rif" autoFocus={true} />
            <FormErrorHelperText error={errors.rif} />
          </Box>
          <Box width="100%" marginBottom={2}>
            <FormLabel>Correo electrónico</FormLabel>
            <OutlinedInput
              name="email"
              placeholder="ej. nombre@gmail.com"
              autoComplete="new-email"
              fullWidth={true}
            />
            <FormErrorHelperText error={errors.email} />
          </Box>
          <Box width="100%" marginBottom={2}>
            <FormLabel>Contraseña</FormLabel>
            <PasswordInput name="claveSecreta" placeholder={'Contraseña'} newPassword={true} />
            <FormErrorHelperText error={errors.claveSecreta} />
          </Box>
          <Box width="100%" marginBottom={2}>
            <FormLabel>Confirmar Contraseña</FormLabel>
            <PasswordInput
              name="confirmaClaveSecreta"
              placeholder={'Confirmar Contraseña'}
              newPassword={true}
            />
            <FormErrorHelperText error={errors.claveSecreta} />
          </Box>
        </Grid>
      </Form>
      <Spinner spinning={registerStatus.isLoading} />
    </Box>
  );
};

export default SignUp;
