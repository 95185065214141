import { ParameterCannotConvertToTypeErr } from 'parameters/errors';

export interface ProcedureGroupParameters {
  readonly serviceId: number;
  readonly procedureId?: number;
}

export class ProcedureGroupParameters {
  public static fromUnknown(value: unknown): ProcedureGroupParameters {
    if (typeof value === 'object') {
      const parameters = value as any;
      const serviceId = Number(parameters.serviceId);

      if (isNaN(serviceId)) {
        throw ParameterCannotConvertToTypeErr;
      }
      const procedureId = Number(parameters.procedureId);

      return {
        serviceId: serviceId,
        procedureId: !isNaN(procedureId) ? procedureId : undefined,
      };
    } else {
      throw ParameterCannotConvertToTypeErr;
    }
  }

  public static mayBeFromUnknown(params: unknown): Partial<ProcedureGroupParameters> {
    try {
      return ProcedureGroupParameters.fromUnknown(params);
    } catch {
      return {};
    }
  }
}
