import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { AttributeType, Enumerable } from '@s3comsecurity/foundations';
import schema, {
  SolicitanteJuridicoObjeto,
  SolicitanteJuridicoObjetoForm,
} from 'components/CompleteRegistration/forms/PasosComunes/JuridicoObjetos/schema';
import Form from 'components/Form';
import FormErrorHelperText from 'components/FormError';
import { StepProps } from 'components/Stepper/stepProps';
import React from 'react';
import { useObjectsQuery, useUserQuery } from 'redux/API';
import { FieldError } from 'types/fieldError';

type Props = StepProps<SolicitanteJuridicoObjeto | readonly SolicitanteJuridicoObjeto[]>;

const emptyUser = {
  registrado: false,
};

const JuridicoObjetos: React.FC<Props> = (props: Props): React.ReactElement => {
  const { data: user = emptyUser } = useUserQuery();
  const { registrado } = user;
  const { data: objects = [] } = useObjectsQuery();
  const { name, onSubmit } = props;
  const [values, setValues] = React.useState<readonly number[]>([]);
  const [errors, setErrors] = React.useState<
    Partial<Record<keyof SolicitanteJuridicoObjetoForm, FieldError>>
  >({});

  React.useEffect(() => {
    if (registrado && objects.length > 0) {
      const selectedIds = objects.map((item: Enumerable) => item.id);
      setValues(selectedIds);
    }
  }, [registrado, objects]);

  const handleClearErrors = React.useCallback((): void => {
    setErrors({});
  }, []);

  const handleSubmission = React.useCallback(
    (data: SolicitanteJuridicoObjeto | readonly SolicitanteJuridicoObjeto[]): void => {
      onSubmit?.(name, data);
    },
    [name, onSubmit],
  );

  const handleItemChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, checked } = event.target;
    const numericValue = Number(value);
    if (isNaN(numericValue)) {
      console.warn('valor inesperado no es un numero');
      return;
    }

    if (checked) {
      setValues((values: readonly number[]): readonly number[] => [...values, numericValue]);
    } else {
      setValues((values: readonly number[]): readonly number[] =>
        values.filter((current: number): boolean => current !== numericValue),
      );
    }
  }, []);

  return (
    <Form<SolicitanteJuridicoObjeto | readonly SolicitanteJuridicoObjeto[]>
      id={name}
      schema={schema}
      errors={errors}
      autoComplete={false}
      onSubmit={handleSubmission}
      onClearErrors={handleClearErrors}
      onError={setErrors}
    >
      {values.map(
        (value: number): React.ReactElement => (
          <input
            key={value}
            name="id"
            type="hidden"
            value={value}
            data-type={AttributeType.numeric}
          />
        ),
      )}

      <Typography>Por favor seleccione 1 o más de los siguientes objetos</Typography>
      <FormErrorHelperText error={errors['objetos']} />
      <Grid px={2} marginTop={2} container>
        {objects?.map(
          (item: Enumerable): React.ReactElement => (
            <Grid xs={6} key={item.id} item>
              <FormControlLabel
                control={
                  <Checkbox
                    value={item.id}
                    checked={values.includes(item.id)}
                    onChange={handleItemChange}
                  />
                }
                label={item.nombre}
              />
            </Grid>
          ),
        )}
      </Grid>
    </Form>
  );
};

export default JuridicoObjetos;
