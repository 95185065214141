import React from 'react';

export const useCheckNonStaticParent = (): React.Ref<HTMLElement> => {
  const ref = React.useRef<HTMLElement>(null);

  React.useEffect((): void => {
    const { current: element } = ref;

    if (element === null) {
      return;
    }

    setTimeout((): void => {
      const parent = element.parentElement;
      if (!parent) {
        throw new Error('Must have a parent element');
      }

      const { position } = getComputedStyle(parent);
      if (position === 'static') {
        console.warn('Parent element must have a non-static position');
      }
    }, 0);
  });

  return ref;
};
