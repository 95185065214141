import { GetActiveRequestStepResponse } from '@s3comsecurity/user-requests';
import Content from 'components/RequestLayout/components/NewRequest/Content';
import { BASE_URL } from 'globalConstants';
import { useTypedParams } from 'hooks/useTypedParams';
import { RequestParameters } from 'parameters/procedure';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authenticationSelector } from 'redux/authenticationReducer';

const NewRequest: React.FC = (): React.ReactElement => {
  const { token } = useSelector(authenticationSelector);
  const navigate = useNavigate();

  const { serviceId, procedureId, requestId, stepId } = useTypedParams<RequestParameters>(
    RequestParameters.fromUnknown,
  );

  const updateCurrentActiveStep = React.useCallback(async (): Promise<void> => {
    const url = `${BASE_URL}/api/v1/solicitudes/${requestId}/paso-actual`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data: GetActiveRequestStepResponse = await response.json();
        // Now that we got it, navigate to it :)
        navigate(['.', data.idPaso].join('/'));
      } else if (response.status === 404) {
        console.warn('it is ok, just there is no steps yet');
      }
    } catch (exception) {
      console.warn(exception);
    }
  }, [navigate, requestId, token]);

  React.useEffect((): void => {
    if (!!stepId) {
      return;
    }

    void updateCurrentActiveStep();
  }, [stepId, updateCurrentActiveStep]);

  return (
    <Content
      serviceId={serviceId}
      procedureId={procedureId}
      requestId={requestId}
      stepId={stepId}
    />
  );
};

export default NewRequest;
