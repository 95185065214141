import Loading from 'components/Loading';
import { AuthenticationPaths } from 'constants/routes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthenticationCommonLayout from 'routes/authentication/AuthenticationCommonLayout';
import ForgotPassword from 'routes/authentication/ForgotPassword';
import Layout from 'routes/authentication/Layout';
import Login from 'routes/authentication/Login';
import SignUp from 'routes/authentication/SignUp';

const AuthenticationRoutes: React.FC = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route element={<AuthenticationCommonLayout />}>
          <Route path={AuthenticationPaths.recoverPassword} element={<ForgotPassword />} />
          <Route path={AuthenticationPaths.signUp} element={<SignUp />} />
        </Route>

        <Route element={<Login />} index />
      </Route>

      <Route path="*" element={<Loading />} />
    </Routes>
  );
};

export default AuthenticationRoutes;
