import { Service } from '@s3comsecurity/user-configuration';
import Procedures from 'components/ApplicationMenu/components/HomeMenu/Procedures';
import DashboardContainer from 'components/DashboardContainer';
import { useTypedParams } from 'hooks/useTypedParams';
import { ProcedureGroupParameters } from 'parameters/service';
import React from 'react';
import { useSelector } from 'react-redux';
import api from 'redux/API';

const ServicesMenu: React.FC = (): React.ReactElement => {
  const { serviceId } = useTypedParams<ProcedureGroupParameters>(
    ProcedureGroupParameters.fromUnknown,
  );

  const { services } = api.endpoints;
  const { data: { servicios } = { servicios: [] } } = useSelector(services.select());

  const selectedService = servicios.find((tramite: Service): boolean => tramite.id === serviceId);

  if (!selectedService) {
    return <></>;
  }

  return (
    <DashboardContainer>
      <Procedures serviceId={selectedService.id} serviceName={selectedService.nombre} />
    </DashboardContainer>
  );
};

export default ServicesMenu;
