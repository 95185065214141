import { AttributeType } from '@s3comsecurity/foundations';

export const getAlignmentForFieldType = (type: AttributeType): 'left' | 'right' | 'center' => {
  switch (type) {
    case AttributeType.numeric:
      return 'center';
    default:
      return 'left';
  }
};
