import { Box, SxProps, Tab, Tabs, Theme } from '@mui/material';
import Disable from 'components/Disable';
import Render from 'components/Render';
import RequestsTable from 'components/ServicesCollectionsForms/RequestsTable';
import { useRequestsQuery } from 'hooks/useRequestsQuery';
import React from 'react';
import { useSelector } from 'react-redux';
import api from 'redux/API';
import { Page } from 'types/page';

interface Props {}

const DashboardContainer: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const query = useRequestsQuery();

  const [currentTab, setCurrentTab] = React.useState<number>(0);

  const { children } = props;
  const { user: userQuery, userRequests: userRequestsQuery } = api.endpoints;
  const { data: { registrado = false } = { registrado: false } } = useSelector(userQuery.select());
  const { data: solicitudes = Page.empty() } = useSelector(userRequestsQuery.select(query));

  const handleTabChange = React.useCallback((_: React.SyntheticEvent, newValue: number): void => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Disable when={!registrado}>
      <Box height="100%" display="flex" flexDirection="column">
        <Box position="sticky" top={0} minWidth="50%" mx="auto" zIndex={1}>
          <Tabs variant="fullWidth" value={currentTab} centered={true} onChange={handleTabChange}>
            <Tab value={0} label={`Solicitudes (${solicitudes.totalPagina})`} />
            <Tab value={1} label="Trámites" />
          </Tabs>
        </Box>
        <Box borderTop="1px solid" borderColor="secondary.light" width="100%" />
        <Box sx={styles.content}>
          <Render when={currentTab === 0}>
            <RequestsTable />
          </Render>
          <Render when={currentTab === 1}>{children}</Render>
        </Box>
      </Box>
    </Disable>
  );
};

export default DashboardContainer;

const styles: Record<string, SxProps<Theme>> = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    flex: 1,
    backgroundColor: '#f8f8f8',
    overflowY: 'scroll',
  },
};
