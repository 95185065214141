import { CircularProgress } from '@mui/material';
import React from 'react';

export const createSelectIconComponent = (loading: boolean): React.ElementType | undefined => {
  if (loading) {
    return function ProgressIcon(props: React.ComponentProps<any>): React.ReactElement {
      const { className } = props;
      const finalClassName = className
        .split(' ')
        .filter((className: string): boolean => !className.includes('disabled'))
        .join(' ');

      return <CircularProgress className={finalClassName} size={16} thickness={6} />;
    };
  } else {
    return undefined;
  }
};
