import { Box, SxProps, Theme, useTheme } from '@mui/material';
import classes from 'components/CircularProgressWrapper/circularProgressWrapper.module.scss';
import React from 'react';

interface Props {
  readonly progress: number | null;
}

const CircularProgressWrapper: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const { children, progress } = props;
  const {
    palette: {
      primary: { main: color },
    },
  } = useTheme();

  const dashOffset = React.useMemo((): number => {
    if (progress === null) {
      return 0;
    }

    return (2 * Math.PI * radius * (100.0 - progress)) / 100.0;
  }, [progress]);

  const dashArray = React.useMemo((): number => 2 * Math.PI * radius, []);
  if (progress === null) {
    return <>{children}</>;
  }

  return (
    <Box sx={styles.container}>
      <>{children}</>
      <Box position="absolute" sx={styles.wrapper}>
        <svg
          width={size}
          height={size}
          version="1.1"
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg"
          className={classes.svg}
        >
          <circle className={classes.track} strokeWidth={strokeWidth} r={radius} cx={c} cy={c} />
          <circle
            stroke={color}
            strokeWidth={strokeWidth}
            r={radius}
            cx={c}
            cy={c}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
          />
        </svg>
      </Box>
    </Box>
  );
};

const styles: Record<string, SxProps<Theme>> = {
  container: {
    position: 'relative',
    borderRadius: '100%',
  },
  wrapper: {
    position: 'absolute',
    inset: 0,
  },
};

const size = 100;
const strokeWidth = 8;
const radius = (size - strokeWidth) / 2;
const c = size / 2;
const viewBox = `0 0 ${size} ${size}`;

export default CircularProgressWrapper;
