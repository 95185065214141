import { SxProps, Theme } from '@mui/material';

export const styles: Record<string, SxProps<Theme>> = {
  steps: {
    width: '100%',
    overflowX: 'hidden',
    flexGrow: 1,
  },
  stepsWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflowX: 'visible',
    transform: 'translateX(-100%)',
    transition: 'transform 0.35s ease-out',
  },
  step: {
    minWidth: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: {
      xs: '100%',
      sm: 800,
    },
    minWidth: 0,
  },
  contentStepper: {
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
    pb: 4,
    width: {
      xs: '100%',
      sm: 800,
    },
    minWidth: 0,
  },
  progress: {
    maxWidth: 200,
    marginRight: 2,
    padding: 1,
    height: '100%',
    backgroundColor: '#f9f9f9',
    borderRadius: 2,
    display: {
      xs: 'none',
      sm: 'block',
    },
  },
};
