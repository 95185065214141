import React, { Children } from 'react';

interface CaseProps<T extends string | number> {
  readonly value: T;
}

interface Props<T extends string | number> {
  readonly value: T;
}

function Switch<T extends string | number>(
  props: React.PropsWithChildren<Props<T>>,
): React.ReactElement {
  const children = Children.toArray(props.children);

  return (
    <>
      {children.filter((child: React.ReactNode): boolean => {
        if (!React.isValidElement<CaseProps<T>>(child)) {
          console.warn('found an element that is not a react element');
          return false;
        }

        const {
          props: { value },
        } = child;

        return value === props.value;
      })}
    </>
  );
}

Switch.Case = function Case<T extends string | number>(
  props: React.PropsWithChildren<CaseProps<T>>,
): React.ReactElement {
  return <>{props.children}</>;
};

export default Switch;
