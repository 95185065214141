import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import React, { ReactElement } from 'react';

const localTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '8px',
          fontSize: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 1)',
        },
      },
    },
  },
});

interface ProfileTooltipProps {
  children: ReactElement;
}

const ProfileTooltip: React.FC<ProfileTooltipProps> = ({ children }) => {
  return (
    <ThemeProvider theme={localTheme}>
      <Tooltip title="Perfil" placement="top" arrow>
        {children}
      </Tooltip>
    </ThemeProvider>
  );
};

export default ProfileTooltip;
