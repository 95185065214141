export const toQueryString = (object: Record<string, any>): string => {
  const entries = Object.entries(object);
  if (entries.length === 0) {
    return '';
  }

  return (
    '?' +
    entries
      .map(([key, value]: [string, any]): string => `${key}=${JSON.stringify(value)}`)
      .join('&')
  );
};
