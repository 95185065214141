import * as yup from 'yup';

export interface SolicitanteJuridicoDocumentos {
  readonly rif: string;
  readonly acta: string;
}

export interface SolicitanteJuridicoDocumentosForm {
  readonly rif: string;
  readonly rifFechaVencimiento: string;
  readonly acta: string;
  readonly actaFechaVencimiento: string;
}

export default yup.object<SolicitanteJuridicoDocumentosForm>().shape({
  rif: yup.string(), //
  //.required('Debe enviar el RIF emitido por el portal web del SENIAT'),
  acta: yup.string(), //
  //.required('Debe enviar el acta constitutiva de la empresa'),
});
