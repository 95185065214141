import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Button, Dialog, Modal, Typography } from '@mui/material';
import { DocumentType } from '@s3comsecurity/foundations';
import CompleteRegistration from 'components/CompleteRegistration';
import React from 'react';
import { useSelector } from 'react-redux';
import api, { useUserProfileQuery } from 'redux/API';

interface Props {
  readonly open: boolean;
  onClose(): void;
}

const ProfileModal: React.FC<Props> = (props: Props): React.ReactElement => {
  const { open, onClose } = props;
  const { data: profile } = useUserProfileQuery();
  const { user: userQuery } = api.endpoints;
  const { data: user } = useSelector(userQuery.select());
  const [editProfileIsOpen, setEditProfileIsOpen] = React.useState(false);

  const handleEditProfile = React.useCallback((): void => {
    setEditProfileIsOpen(true);
  }, []);

  const closeModal = React.useCallback((): void => {
    setEditProfileIsOpen(false);
  }, []);

  // FIXME: convert this to a component
  const renderProfileDetails = (): React.ReactElement => {
    if (!profile || !user) {
      return <Typography>Información no disponible</Typography>;
    }

    switch (profile.tipoDocumento) {
      case DocumentType.legal:
        return (
          <React.Fragment>
            <Box sx={boxAvatarStyle}>
              <Avatar sx={avatarStyle} />
            </Box>
            <Typography>
              <strong>Nombre:</strong> {profile.nombre}
            </Typography>
            <Typography>
              {/* FIXME: esperando data de la API */}
              <strong>RIF/CI:</strong> {user?.rif}
            </Typography>
            <Typography>
              <strong>Representante Legal:</strong> {profile.nombreRepresentanteLegal}
            </Typography>
            <Typography>
              <strong>Rif Representante Legal:</strong> {profile.rifRepresentanteLegal}
            </Typography>
            <Typography>
              <strong>Email:</strong> {user.email}
            </Typography>
            <Typography>
              <strong>Tipo de Usuario:</strong> {profile.tipoDocumento}
            </Typography>
            {/*{telefonos &&*/}
            {/*  telefonos.map((telefono: Telefono, index: number) => (*/}
            {/*    <Typography key={index}>*/}
            {/*      <strong>{telefono.tipoTelefono}:</strong> {telefono.numero}*/}
            {/*    </Typography>*/}
            {/*  ))}*/}
          </React.Fragment>
        );

      case DocumentType.government:
        //FIXME: esperando data de la API
        return (
          <React.Fragment>
            <Box sx={boxAvatarStyle}>
              <Avatar sx={avatarStyle} />
            </Box>
            <Typography>{user?.nombre}</Typography>
            <Typography>{user?.rif}</Typography>
          </React.Fragment>
        );

      case DocumentType.individual:
        //FIXME: esperando data de la API
        return (
          <React.Fragment>
            <Box sx={boxAvatarStyle}>
              <Avatar sx={avatarStyle} />
            </Box>
            <Typography>
              {profile.primerNombre} {profile.primerApellido}
            </Typography>
            <Typography>{user.rif}</Typography>
          </React.Fragment>
        );
      default:
        throw new Error('cannot render this');
    }
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          {renderProfileDetails()}
          <Box sx={boxButtonStyle}>
            <Button variant="contained" startIcon={<EditIcon />} onClick={handleEditProfile}>
              Editar Perfil
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog open={editProfileIsOpen} maxWidth="xl">
        <CompleteRegistration documentType={user?.tipoDocumento} onClose={closeModal} />
      </Dialog>
    </React.Fragment>
  );
};

export default ProfileModal;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const boxAvatarStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mb: 3,
};

const avatarStyle = {
  width: 150,
  height: 150,
  marginY: 2,
};

const boxButtonStyle = {
  display: 'flex',
  justifyContent: 'center',
  mt: 4,
};
