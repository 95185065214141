import { DocumentType } from '@s3comsecurity/foundations';
import fields from 'components/CompleteRegistration/forms/PasosComunes/JuridicoDatosBasicos/fields';
import schema, {
  SolicitanteJuridicoDatos,
} from 'components/CompleteRegistration/forms/PasosComunes/JuridicoDatosBasicos/schema';
import Form from 'components/Form';
import Fieldset from 'components/Form/components/Fieldset';
import { StepProps } from 'components/Stepper/stepProps';
import React from 'react';
import { useUserProfileQuery } from 'redux/API';
import { useUserQuery } from 'redux/API';
import { FieldError } from 'types/fieldError';

type Props = StepProps<SolicitanteJuridicoDatos>;

const emptyUser = {
  registrado: false,
};

const JuridicoDatosBasicos: React.FC<Props> = (props: Props): React.ReactElement => {
  const { data: userProfile } = useUserProfileQuery();
  const { data: user = emptyUser } = useUserQuery();
  const { registrado } = user;
  const [errors, setErrors] = React.useState<
    Partial<Record<keyof SolicitanteJuridicoDatos, FieldError>>
  >({});
  const { name, onSubmit } = props;

  let initialValues;

  if (registrado && userProfile?.tipoDocumento === DocumentType.legal) {
    initialValues = {
      nombre: userProfile.nombre || '',
      acronimo: userProfile.acronimo || '',
      registroMercantil: userProfile.registroMercantil || '',
      tomo: userProfile.tomo || '',
      folio: userProfile.folio || '',
      numeroRegistro: userProfile.numeroRegistro || '',
      fechaRegistro: formatter.format(new Date(userProfile.fechaRegistro)) ?? '',
    };
  }

  const handleClearErrors = React.useCallback((): void => {
    setErrors({});
  }, []);

  const handleSubmission = React.useCallback(
    (data: SolicitanteJuridicoDatos): void => {
      onSubmit?.(name, data);
    },
    [name, onSubmit],
  );

  return (
    <Form<SolicitanteJuridicoDatos>
      id={name}
      schema={schema}
      errors={errors}
      autoComplete={false}
      onSubmit={handleSubmission}
      onClearErrors={handleClearErrors}
      onError={setErrors}
    >
      <Fieldset fields={fields} defaultValue={initialValues} />
    </Form>
  );
};

export default JuridicoDatosBasicos;

const formatter = new Intl.DateTimeFormat('es-VE', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});
