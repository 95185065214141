import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

const TramiteLayout: React.FC = (): React.ReactElement => {
  return (
    <Box width="100%" height="100%">
      <Outlet />
    </Box>
  );
};

export default TramiteLayout;
