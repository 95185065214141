import { DocumentType } from '@s3comsecurity/foundations';
import RegistroPersonaJuridica from 'components/CompleteRegistration/forms/Juridico';
import RegistroEntidadGubernamental from 'components/CompleteRegistration/forms/JuridicoGobierno';
import RegistroPersonaNatural from 'components/CompleteRegistration/forms/Natural';
import React from 'react';

interface Props {
  readonly documentType: DocumentType | undefined;
  onClose(): void;
}

const CompleteRegistration: React.FC<Props> = (props: Props): React.ReactElement => {
  const { documentType, onClose } = props;

  switch (documentType) {
    case DocumentType.individual:
      return <RegistroPersonaNatural onClose={onClose} />;
    case DocumentType.legal:
      return <RegistroPersonaJuridica onClose={onClose} />;
    case DocumentType.government:
      return <RegistroEntidadGubernamental onClose={onClose} />;
    default:
      return <></>;
  }
};

export default CompleteRegistration;
