import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { Attribute, AttributeType } from '@s3comsecurity/foundations';
import Confirm from 'components/Confirm';
import DynamicFormViewer from 'components/DynamicFormViewer';
import InventoryTable from 'components/Inventory/components/Table';
import EditForm from 'components/Inventory/modals/EditForm';
import ModalContainer from 'components/ModalContainer';
import Render from 'components/Render';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  readonly fields: readonly Attribute[];
  readonly defaultValues: Readonly<Record<string, any>>;
}

const InventoryScreen: React.FC<Props> = (props: Props): React.ReactElement => {
  const { fields, defaultValues } = props;
  const { action, itemId } = useParams();
  const navigate = useNavigate();
  const [items, setItems] = React.useState<readonly Readonly<Record<string, any>>[]>([]);

  const handleAddItem = React.useCallback((): void => {
    navigate('agregar');
  }, [navigate]);

  const handleCloseModal = React.useCallback((): void => {
    navigate(-1);
  }, [navigate]);

  const currentIdFilter = React.useCallback(
    (item: Record<string, any>): boolean => item.id !== itemId,
    [itemId],
  );

  const handleRemoveItem = React.useCallback((): void => {
    setItems(
      (items: readonly Readonly<Record<string, any>>[]): readonly Readonly<Record<string, any>>[] =>
        items.filter(currentIdFilter),
    );
    handleCloseModal();
  }, [currentIdFilter, handleCloseModal]);

  const columns = React.useMemo(
    (): readonly Attribute[] =>
      fields.filter((field: Attribute): boolean => {
        const { propiedades } = field;
        if (!propiedades) {
          return false;
        }

        return propiedades.tabular;
      }),
    [fields],
  );

  const handleAction = React.useCallback(
    (action: 'view' | 'edit' | 'remove', id: string): void => {
      switch (action) {
        case 'edit':
          navigate(`editar/${id}`);
          break;
        case 'remove':
          navigate(`eliminar/${id}`);
          break;
        case 'view':
          navigate(`detalle/${id}`);
          break;
        default:
          throw new Error('Invalid action');
      }
    },
    [navigate],
  );

  const selectedItem = React.useMemo((): Readonly<Record<string, any>> => {
    if (!itemId) {
      return {};
    }

    return items.find((item: Record<string, any>): boolean => item.id === itemId) ?? {};
  }, [itemId, items]);

  const formValue = React.useMemo((): string => JSON.stringify(items), [items]);

  const handleSubmit = React.useCallback(
    (item: any): void => {
      setItems(
        (
          items: readonly Readonly<Record<string, any>>[],
        ): readonly Readonly<Record<string, any>>[] => [item, ...items.filter(currentIdFilter)],
      );

      handleCloseModal();
    },
    [currentIdFilter, handleCloseModal],
  );

  React.useEffect((): void => {
    if (!defaultValues.inventario) {
      return;
    } else if (Array.isArray(defaultValues.inventario)) {
      setItems(defaultValues.inventario);
    } else {
      console.warn('Invalid defaultValues', defaultValues);
    }
  }, [defaultValues]);

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%" height="100%" overflow="hidden">
        <Box textAlign="right" mb={2}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddItem}>
            Agregar
          </Button>
        </Box>
        <Render when={items.length > 0}>
          <InventoryTable columns={columns} rows={items} onAction={handleAction} />
        </Render>
        <Render when={items.length === 0}>
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <Typography>
              No ha agregado ning&uacute;n objeto, por favor presione el bot&oacute;n agregar
            </Typography>
          </Box>
        </Render>

        <Dialog open={action === 'agregar' || action === 'editar'}>
          <EditForm
            fields={fields}
            action={action}
            selectedItem={selectedItem}
            onSubmit={handleSubmit}
            onClose={handleCloseModal}
          />
        </Dialog>

        <Dialog open={action === 'eliminar'}>
          <Confirm
            title="Eliminar elemento"
            question="Desea usted eliminar este elemento o prefiere conservarlo"
            danger={true}
            onRejected={handleCloseModal}
            onApproved={handleRemoveItem}
          />
        </Dialog>

        <Dialog open={action === 'detalle'}>
          <ModalContainer title="Detalle del elemento" onClose={handleCloseModal}>
            <DynamicFormViewer fields={fields} values={selectedItem} />
            <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
              <Button color="primary" onClick={handleCloseModal}>
                Cerrar
              </Button>
            </Box>
          </ModalContainer>
        </Dialog>
      </Box>

      <form name="inventario">
        <input value={formValue} name="inventario" type="hidden" data-type={AttributeType.object} />
      </form>
    </>
  );
};

export default InventoryScreen;
