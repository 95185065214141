import { Box } from '@mui/material';
import Buttons from 'components/DynamicStepper/components/Buttons';
import StepIndicator from 'components/DynamicStepper/components/StepIndicator';
import StepSeparator from 'components/DynamicStepper/components/StepSeparator';
import { formToObject, isStep } from 'components/DynamicStepper/helpers';
import { StepProps } from 'components/Stepper/stepProps';
import React, { Children as ReactChildren } from 'react';

interface Props {
  readonly currentStep: number;
  readonly form: string;

  onCancel?(): void;

  onBack(): void;
  onNext(data?: any): Promise<void>;
  onDone(): void;
}

const DynamicStepper: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const { children, form, currentStep } = props;
  const { onCancel, onNext, onBack, onDone } = props;

  const handleNext = React.useCallback(async (): Promise<void> => {
    const forms = Array.from(document.forms);
    if (forms.length !== 1) {
      throw new Error('only 1 form expected');
    }

    // FIXME: what? magic numbers ALWAYS bad no?
    await onNext(formToObject(forms[0]));
  }, [onNext]);

  const handleDone = React.useCallback(async (): Promise<void> => {
    await handleNext();
    onDone();
  }, [handleNext, onDone]);

  if (ReactChildren.count(children) !== 2) {
    throw new Error('Stepper must have exactly 2 children');
  }

  const [stepsElement, activeStep] = ReactChildren.toArray(children);

  if (!React.isValidElement<React.PropsWithChildren>(stepsElement)) {
    throw new Error('Stepper children must be Step');
  }

  const { children: steps } = stepsElement.props;
  const stepsArray = ReactChildren.toArray(steps);
  if (!stepsArray.every(isStep)) {
    throw new Error('Stepper children must be Step');
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box display="flex" alignItems="start" justifyContent="space-between" my={1} mb={1.5} px={2}>
        {stepsArray
          .flatMap(
            (
              step: React.ReactElement<StepProps<any>>,
              index: number,
            ): readonly React.ReactElement[] => {
              const { title } = step.props;
              const status =
                index < currentStep ? 'passed' : index === currentStep ? 'active' : 'pending';

              return [
                <StepSeparator key={[step.key, 'separator'].join('-')} status={status} />,
                <StepIndicator key={step.key} position={index + 1} label={title} status={status} />,
              ];
            },
          )
          .slice(1)}
      </Box>

      <Box flex={1} px={3} overflow="auto">
        {activeStep}
      </Box>

      <Box px={3}>
        <Buttons
          active={currentStep}
          last={stepsArray.length - 1}
          form={form}
          onCancel={onCancel}
          onNext={handleNext}
          onDone={handleDone}
          onBack={onBack}
        />
      </Box>
    </Box>
  );
};

export default DynamicStepper;
