import { Action, ActionType, reducer } from 'hooks/StepperManager/reducer';
import React, { Reducer } from 'react';

export enum StepperStatus {
  initial = 'initial',
  completed = 'completed',
}

export interface StepperState {
  readonly activeStep: number;
  readonly data: any;
  readonly status: StepperStatus;
}

export interface StepperApi {
  next<T>(id: string, data: T): void;
  done<T>(id: string, data: T): void;
  back(): void;
}

const initialState: StepperState = {
  status: StepperStatus.initial,
  activeStep: 0,
  data: {},
};

export const useStepperManager = (): StepperState & StepperApi => {
  const [state, dispatch] = React.useReducer<Reducer<StepperState, Action>>(reducer, initialState);

  return {
    status: state.status,
    activeStep: state.activeStep,
    data: state.data,
    done: <T>(id: string, data: T): void => {
      dispatch({ type: ActionType.done, payload: { id: id, data: data } });
    },
    next: <T>(id: string, data: T): void => {
      dispatch({ type: ActionType.next, payload: { id: id, data: data } });
    },
    back: (): void => {
      dispatch({ type: ActionType.back });
    },
  };
};
