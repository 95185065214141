import { MenuItem, Select, Typography } from '@mui/material';
import { DropdownFieldWithStaticData } from 'components/Form/types';
import React from 'react';
import { DropdownItem } from 'types/dropdownItem';

interface Props<T> {
  readonly field: DropdownFieldWithStaticData<T>;
}

function DropdownFieldWithStaticDataRenderer<T>(props: Props<T>): React.ReactElement {
  const { field } = props;
  const { data } = field;

  const renderValue = React.useCallback(
    (value: any): React.ReactNode => {
      const item = data.find((item: DropdownItem): boolean => item.id === value);
      const label = item?.label;

      if (!label) {
        return (
          <Typography color="text.disabled" fontStyle="italic">
            Seleccionar una opción
          </Typography>
        );
      }

      return label;
    },
    [data],
  );

  return (
    <Select
      name={String(field.name)}
      renderValue={renderValue}
      defaultValue=""
      displayEmpty={true}
      fullWidth={true}
    >
      {data.map(
        (item: DropdownItem): React.ReactElement => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ),
      )}
    </Select>
  );
}

export default DropdownFieldWithStaticDataRenderer;
