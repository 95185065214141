import { DocumentType } from '@s3comsecurity/foundations';
import fields from 'components/CompleteRegistration/forms/PasosComunes/JuridicoRepresentanteLegal/fields';
import schema, {
  SolicitanteJuridicoRepresentanteLegal,
} from 'components/CompleteRegistration/forms/PasosComunes/JuridicoRepresentanteLegal/schema';
import Form from 'components/Form';
import Fieldset from 'components/Form/components/Fieldset';
import { StepProps } from 'components/Stepper/stepProps';
import React from 'react';
import { useUserProfileQuery } from 'redux/API';
import { useUserQuery } from 'redux/API';
import { FieldError } from 'types/fieldError';

type Props = StepProps<SolicitanteJuridicoRepresentanteLegal>;

const emptyUser = {
  registrado: false,
};

const JuridicoRepresentanteLegal: React.FC<Props> = (props: Props): React.ReactElement => {
  const { data: userProfile } = useUserProfileQuery();
  const { data: user = emptyUser } = useUserQuery();
  const { registrado } = user;
  const [errors, setErrors] = React.useState<
    Partial<Record<keyof SolicitanteJuridicoRepresentanteLegal, FieldError>>
  >({});
  const { name, onSubmit } = props;

  let initialValues;

  if (registrado && userProfile?.tipoDocumento === DocumentType.legal) {
    initialValues = {
      nombre: userProfile.nombreRepresentanteLegal || '',
      rif: userProfile.rifRepresentanteLegal || '',
    };
  }

  const handleClearErrors = React.useCallback((): void => {
    setErrors({});
  }, []);

  const handleSubmission = React.useCallback(
    (data: SolicitanteJuridicoRepresentanteLegal): void => {
      onSubmit?.(name, data);
    },
    [name, onSubmit],
  );

  return (
    <Form<SolicitanteJuridicoRepresentanteLegal>
      id={name}
      schema={schema}
      errors={errors}
      autoComplete={false}
      onSubmit={handleSubmission}
      onClearErrors={handleClearErrors}
      onError={setErrors}
    >
      <Fieldset fields={fields} defaultValue={initialValues} />
    </Form>
  );
};

export default JuridicoRepresentanteLegal;
