import { LeafletMouseEvent } from 'leaflet';
import React from 'react';
import { useMapEvents } from 'react-leaflet';

interface Props {
  onClick(latitude: number, longitude: number): void;
}

const MapEvents: React.FC<Props> = (props: Props): React.ReactElement => {
  const { onClick } = props;

  useMapEvents({
    click: (event: LeafletMouseEvent): void => {
      const { latlng } = event;
      onClick(latlng.lat, latlng.lng);
    },
  });
  return <></>;
};

export default MapEvents;
