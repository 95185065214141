import { BaseQueryApi } from '@reduxjs/toolkit/query';
import { ApplicationState } from 'redux/store';

export const prepareHeaders = async (
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState'>,
): Promise<Headers> => {
  const state: ApplicationState = api.getState() as ApplicationState;

  const { authentication } = state;
  const { token } = authentication;

  headers.set('Accept', 'application/json');
  if (token !== null) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};
