import { Box, SxProps, Theme } from '@mui/material';
import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';

interface Props {
  readonly currentPage: number;
}

// TODO: Incomplete implementation
//       Missing features:
//          * Animation
//          * Width adjustments (could use a ref to get the parent element and have context)
//          * Child validation (probably use a wrapper)
function Pager(props: React.PropsWithChildren<Props>): React.ReactElement {
  const { currentPage, children } = props;
  const [style, setStyle] = React.useState<SxProps<Theme>>({});
  if (currentPage < 0 || currentPage >= Children.count(children)) {
    throw new Error('Invalid page number for pager');
  }

  const computeOptimalSize = React.useCallback(async (): Promise<void> => {
    const array = Children.toArray(children);
    const { body } = document;

    const probe = document.createElement('div');
    body.append(probe);

    Object.assign(probe.style, {
      visibility: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: Number.MAX_SAFE_INTEGER,
    });

    const root = ReactDOM.createRoot(probe);
    const measure = async (index: number, current: number): Promise<number> => {
      if (index >= array.length) {
        return current;
      }

      const child = array[index];
      if (!React.isValidElement(child)) {
        return current;
      }
      root.render(React.cloneElement(child));

      await new Promise<void>((resolve: VoidFunction): void => {
        setTimeout(resolve, 0);
      });

      // Get the height
      const elementHeight = probe.offsetHeight;
      // Remove from DOM
      probe.remove();

      if (elementHeight > current) {
        return measure(index + 1, elementHeight);
      }

      return measure(index + 1, current);
    };

    const height = await measure(0, 0);
    setStyle({ height });
  }, [children]);

  React.useLayoutEffect((): void => {
    void computeOptimalSize();
  }, [computeOptimalSize]);

  return <Box sx={style}>{Children.toArray(children)[currentPage]}</Box>;
}

export default Pager;
