import { Box } from '@mui/material';
import React from 'react';

const PaddingBox: React.FC<React.PropsWithChildren> = (
  props: React.PropsWithChildren,
): React.ReactElement => {
  return <Box padding={4}>{props.children}</Box>;
};

export default PaddingBox;
