import { Add, Delete, Factory, Home, PhoneAndroid } from '@mui/icons-material';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { AttributeType } from '@s3comsecurity/foundations';
import Clicker from 'components/Clicker';
import schema, {
  SolicitanteTelefonosForm,
} from 'components/CompleteRegistration/forms/PasosComunes/SolicitanteTelefonos/schema';
import EnumerableSelect from 'components/EnumerableSelect';
import Form from 'components/Form';
import { StepProps } from 'components/Stepper/stepProps';
import { AsYouType, isValidPhoneNumber, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
import React from 'react';
import { useTiposTelefonosQuery } from 'redux/API';
import { FieldError } from 'types/fieldError';
import { noop } from 'utils/noop';
import { v4 } from 'uuid';

enum PhoneNumberType {
  mobile = 1,
  room = 2,
  office = 3,
}

class ExtendedPhoneNumber {
  readonly id: string;
  readonly parsed: PhoneNumber;
  readonly type: PhoneNumberType;
  readonly number: string;

  constructor(type: PhoneNumberType, phoneNumber: PhoneNumber) {
    this.id = v4();
    this.type = type;
    this.parsed = phoneNumber;
    this.number = phoneNumber.formatNational();
  }

  public serialize(): string {
    const { type, parsed } = this;
    return type + ':' + parsed.formatNational();
  }
}

type Props = StepProps<readonly { readonly tipo: number; readonly numero: string }[]>;

const SolicitanteTelefonos: React.FC<Props> = (props: Props): React.ReactElement => {
  const { data: types = [], isLoading: loading } = useTiposTelefonosQuery();

  const [phoneType, setPhoneType] = React.useState<PhoneNumberType | 0>(0);
  const [currentNumber, setCurrentNumber] = React.useState<string>('');
  const [numbers, setNumbers] = React.useState<readonly ExtendedPhoneNumber[]>([]);

  const [errors, _] = React.useState<Partial<Record<keyof SolicitanteTelefonosForm, FieldError>>>(
    {},
  );

  const { name, onSubmit } = props;
  const { current: formatter } = React.useRef<AsYouType>(new AsYouType('VE'));

  React.useEffect((): void => {
    const type = types[0]?.id;
    if (type === undefined) {
      return;
    }

    setPhoneType(type);
  }, [types]);

  const handleSubmission = React.useCallback(
    (data: SolicitanteTelefonosForm): void => {
      const telefonos = Array.isArray(data.telefonos) ? data.telefonos : [data.telefonos];
      onSubmit?.(name, telefonos);
    },
    [name, onSubmit],
  );

  const handlePhoneNumberChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      // MUST reset the formatter or else it accumulates the
      // input
      formatter.reset();
      // Now format the partial number
      setCurrentNumber(formatter.input(value));
    },
    [formatter],
  );

  const handleAddNumber = React.useCallback((): void => {
    const parsed = parsePhoneNumber(currentNumber, 'VE');
    setNumbers((numbers: readonly ExtendedPhoneNumber[]): readonly ExtendedPhoneNumber[] => {
      switch (phoneType) {
        case PhoneNumberType.room:
        case PhoneNumberType.office:
        case PhoneNumberType.mobile:
          return [...numbers, new ExtendedPhoneNumber(phoneType, parsed)];
        default:
          return numbers;
      }
    });

    setPhoneType(PhoneNumberType.mobile);
    setCurrentNumber('');
  }, [currentNumber, phoneType]);

  const validNumber = React.useMemo((): boolean => {
    return isValidPhoneNumber(currentNumber, 'VE');
  }, [currentNumber]);

  const handleRemoveNumber = React.useCallback((removed: ExtendedPhoneNumber): void => {
    setNumbers((numbers: readonly ExtendedPhoneNumber[]): readonly ExtendedPhoneNumber[] => {
      return numbers.filter((item: ExtendedPhoneNumber): boolean => {
        return item.id !== removed.id;
      });
    });
  }, []);

  return (
    <Form<SolicitanteTelefonosForm>
      id={name}
      schema={schema}
      errors={errors}
      autoComplete={false}
      onError={noop}
      onClearErrors={noop}
      onSubmit={handleSubmission}
    >
      {numbers.map(
        (item: ExtendedPhoneNumber): React.ReactElement => (
          <React.Fragment key={item.id}>
            <input
              type="hidden"
              name="telefonos.tipo"
              value={item.type}
              data-type={AttributeType.numeric}
            />
            <input type="hidden" name="telefonos.numero" value={item.number} />
          </React.Fragment>
        ),
      )}
      <Grid spacing={1} mx={-0.75} marginTop={-1} container>
        <Grid xs={3} item>
          <FormLabel>Tipo de teléfono</FormLabel>
          <EnumerableSelect
            items={types}
            loading={loading}
            value={phoneType}
            onChange={setPhoneType}
          />
        </Grid>
        <Grid xs={7} item>
          <FormLabel>Número</FormLabel>
          <OutlinedInput
            value={currentNumber}
            fullWidth={true}
            onChange={handlePhoneNumberChange}
          />
        </Grid>
        <Grid xs={2} item>
          <FormLabel>&nbsp;</FormLabel>
          <Button startIcon={<Add />} disabled={!validNumber} onClick={handleAddNumber}>
            Añadir
          </Button>
        </Grid>
      </Grid>
      <Grid my={3}>
        {numbers.length > 0 ? <Typography variant="h6">Teléfonos Agregados</Typography> : null}
        <Box my={2}>
          <List>
            {numbers.map((item: ExtendedPhoneNumber): React.ReactElement => {
              const { type, number } = item;

              return (
                <ListItem key={item.id} divider={true} disableGutters={true}>
                  <ListItemIcon>{icons[type]}</ListItemIcon>
                  <ListItemText primary={number} secondary={labels[type]} />
                  <ListItemSecondaryAction>
                    <Clicker data={item} onClick={handleRemoveNumber}>
                      <IconButton>
                        <Delete />
                      </IconButton>
                    </Clicker>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Grid>
    </Form>
  );
};

export default SolicitanteTelefonos;

const icons: { [key in PhoneNumberType]: React.ReactElement } = {
  [PhoneNumberType.mobile]: <PhoneAndroid />,
  [PhoneNumberType.room]: <Home />,
  [PhoneNumberType.office]: <Factory />,
};

const labels: { [key in PhoneNumberType]: string } = {
  [PhoneNumberType.mobile]: 'Celular',
  [PhoneNumberType.room]: 'Habitación',
  [PhoneNumberType.office]: 'Oficina',
};
