import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Attribute, ShowCondition } from '@s3comsecurity/foundations';
import React from 'react';

interface Props {
  readonly fields: readonly Attribute[];
  readonly values: Readonly<Record<string, any>>;
}

const DynamicFormRenderer: React.FC<Props> = (props: Props): React.ReactElement => {
  const { fields, values } = props;

  const filterHidden = React.useCallback(
    (atributo: Attribute): boolean => {
      const { mostrar } = atributo.propiedades;
      // FIXME: true por defecto es algo idiota! si queremos hacer algo no por defecto es ocultar,
      //        entonces esto debería llamarse ocultar, no mostrar
      if (!mostrar) {
        // True por defecto
        return true;
      }

      return (
        mostrar.every((item: ShowCondition): boolean => values?.[item.cuando] === item.es) ?? true
      );
    },
    [values],
  );

  return (
    <Box sx={styles.container}>
      {fields?.filter(filterHidden).map((field: Attribute): React.ReactElement => {
        return (
          <Box key={field.nombre} sx={styles.field}>
            <Typography>{field.etiqueta}</Typography>
            <Typography>{values[field.nombre]}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const styles: Record<string, SxProps<Theme>> = {
  container: {
    flex: 1,
    width: '100%',
  },
  field: {
    px: 2,
    py: 1,
    my: 2,
    borderRadius: 2,
    backgroundColor: '#f8f9fb',
    display: 'block',
  },
};

export default DynamicFormRenderer;
