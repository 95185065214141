import { createTheme, CSSInterpolation, Theme } from '@mui/material';
import { ComponentsPropsList } from '@mui/material/styles/props';

type CSSInterpolationBuilder<ComponentName extends keyof ComponentsPropsList> = {
  readonly ownerState: ComponentsPropsList[ComponentName] & Record<string, unknown>;
  readonly theme: Theme;
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
      dark: '#181818',
    },
    info: {
      main: '#313131',
      dark: '#232323',
    },
    secondary: {
      main: '#ccc',
      dark: '#bbb',
      contrastText: '#222',
    },
    text: {
      primary: '#334',
      secondary: '#667',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    fontWeightBold: 800,
    fontWeightMedium: 600,
    fontWeightRegular: 500,
    fontWeightLight: 400,
    h4: {
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          fontFamily: ['sans-serif', 'Montserrat'],
          fontSize: '15px',
        },
        body: {
          margin: 0,
        },
        strong: {
          fontWeight: 700,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'black',
        },
        arrow: {
          color: 'black',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontFamily: 'Poppins',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: 500,
          fontSize: 13,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'block',
          fontWeight: 600,
          fontSize: 13,
          marginBottom: 2,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 90,
        },
        outlinedSecondary: {
          borderColor: '#666',
          color: '#666',
        },
        textSecondary: {
          borderColor: '#666',
          color: '#666',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:disabled': {
            opacity: 0.35,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation3: {
          background: 'white',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 4,
          overflow: 'hidden',
        },
      },
      defaultProps: {
        PaperProps: {
          elevation: 3,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: ({ ownerState, theme }: CSSInterpolationBuilder<'MuiAlert'>): CSSInterpolation => {
          const { error, primary } = theme.palette;
          const { severity } = ownerState;

          return {
            fontFamily: ['sans-serif', 'Montserrat'],
            fontWeight: 500,
            fontSize: 14,
            a: {
              color: severity === 'error' ? error.main : primary.main,
            },
          };
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '8px 12px',
        },
        adornedEnd: {
          paddingRight: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#555555',
          svg: {
            height: 22,
            width: 22,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
          paddingTop: 0,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          width: '100%',
          margin: 0,
        },
        item: {
          minWidth: 0,
        },
      },
    },
  },
});

export default theme;
