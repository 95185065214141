import { SolicitanteJuridicoDatos } from 'components/CompleteRegistration/forms/PasosComunes/JuridicoDatosBasicos/schema';
import { Attribute, AttributeType } from 'components/Form/types';

const fields: readonly Attribute<SolicitanteJuridicoDatos>[] = [
  {
    type: AttributeType.text,
    name: 'nombre',
    label: 'Nombre',
    required: true,
  },
  {
    type: AttributeType.text,
    name: 'acronimo',
    label: 'Acrónimo',
    required: true,
  },
  {
    type: AttributeType.text,
    name: 'registroMercantil',
    label: 'Registro Mercantil',
    required: true,
  },
  {
    type: AttributeType.text,
    name: 'tomo',
    label: 'Tomo',
    required: true,
  },
  {
    type: AttributeType.text,
    name: 'folio',
    label: 'Folio',
    required: true,
  },
  {
    type: AttributeType.text,
    name: 'numeroRegistro',
    label: 'Número de Registro',
    required: true,
  },
  {
    type: AttributeType.date,
    name: 'fechaRegistro',
    label: 'Fecha de Registro',
    required: true,
  },
];

export default fields;
