import { QueryLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Component, Enumerable } from '@s3comsecurity/foundations';
import { Fee, Request, Requester } from '@s3comsecurity/requests';
import { UpdateUserProfileRequest } from '@s3comsecurity/user-accounts';
import { LoginRequest, RegisterRequest, Session, User } from '@s3comsecurity/user-auth';
import {
  CitiesRequest,
  GetParishRequest,
  GetParishResponse,
  GetProceduresResponse,
  GetProcedureStepComponentsRequest,
  GetProcedureStepsRequest,
  GetProcedureStepsResponse,
  GetServicesResponse,
  MunicipalitiesRequest,
  ParishesRequest,
} from '@s3comsecurity/user-configuration';
import {
  CreateRequestRequest,
  GetAllRequestsRequest,
  GetCurrentTimelinePointRequest,
  GetCurrentTimelinePointResponse,
  GetFeeRequest,
  GetProcedureRequestsRequest,
  GetRequestStepRequest,
  GetRequestStepResponse,
  GetServiceRequestsRequest,
  SubmitStageRequest,
  UpdateRequestStepRequest,
} from '@s3comsecurity/user-requests';
import { BASE_URL } from 'globalConstants';
import { logout } from 'redux/authenticationReducer';
import { Page } from 'types/page';
import { prepareHeaders } from 'utils/headers';
import { toQueryString } from 'utils/toQueryString';

export interface FileMetadata {
  readonly id: string;
  readonly nombre: string;
  readonly tipo: string;
  readonly magnitud: number;
  readonly fecha: string;
  readonly url: string;
}

type GetFileMetadataRequest = any;

const baseQuery = fetchBaseQuery({
  baseUrl: [BASE_URL, 'api', 'v1'].join('/'),
  prepareHeaders: prepareHeaders,
});

const baseQueryWithAuthFailure: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: string | FetchArgs, api: any, extraOptions: any) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }

  return result;
};

type BaseQueryType = BaseQueryFn<string | FetchArgs>;

const api = createApi({
  reducerPath: 'api/v1',
  baseQuery: baseQueryWithAuthFailure,
  refetchOnMountOrArgChange: true,
  tagTypes: ['CurrentUser', 'Solicitudes'],
  endpoints: builder => ({
    objects: builder.query<readonly Enumerable[], void>({
      query: (): string => 'datos/objetos',
    }),
    states: builder.query<readonly Enumerable[], void>({
      query: (): string => 'datos/estados',
      onQueryStarted: async (
        _: void,
        {
          dispatch,
          queryFulfilled,
        }: QueryLifecycleApi<void, BaseQueryType, readonly Enumerable[], 'api/v1'>,
      ): Promise<void> => {
        const patchMunicipios = dispatch(
          api.util.updateQueryData(
            'municipalities',
            { idEstado: 0 },
            (draft: readonly Enumerable[] | null | undefined): void => {
              if (!!draft) {
                Object.assign(draft, { elementos: [] });
              }
            },
          ),
        );

        const patchParroquias = dispatch(
          api.util.updateQueryData(
            'parishes',
            { idEstado: 0, idMunicipio: 0 },
            (draft: readonly Enumerable[] | null | undefined): void => {
              if (!!draft) {
                Object.assign(draft, { elementos: [] });
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchMunicipios.undo();
          patchParroquias.undo();
        }
      },
    }),
    municipalities: builder.query<readonly Enumerable[], MunicipalitiesRequest>({
      query: (req: MunicipalitiesRequest): string => {
        return `datos/estados/${req.idEstado}/municipios`;
      },
      onQueryStarted: async (
        request: MunicipalitiesRequest,
        {
          dispatch,
          queryFulfilled,
        }: QueryLifecycleApi<MunicipalitiesRequest, BaseQueryType, readonly Enumerable[], 'api/v1'>,
      ): Promise<void> => {
        const patchParroquias = dispatch(
          api.util.updateQueryData(
            'parishes',
            { idEstado: request.idEstado, idMunicipio: 0 },
            (draft: readonly Enumerable[] | null | undefined): void => {
              if (!!draft) {
                Object.assign(draft, { elementos: [] });
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchParroquias.undo();
        }
      },
    }),
    parishes: builder.query<readonly Enumerable[], ParishesRequest>({
      query: (query: ParishesRequest): string =>
        `datos/estados/${query.idEstado}/municipios/${query.idMunicipio}/parroquias`,
    }),
    cities: builder.query<readonly Enumerable[], CitiesRequest>({
      query: (req: CitiesRequest): string => `datos/estados/${req.idEstado}/ciudades`,
    }),
    getParroquia: builder.query<GetParishResponse, GetParishRequest>({
      query: (req: GetParishRequest): string => `datos/parroquias/${req.idParroquia}`,
    }),
    localidades: builder.query<readonly Enumerable[], void>({
      query: (): string => 'datos/localidades',
    }),
    inmuebles: builder.query<readonly Enumerable[], void>({
      query: (): string => 'datos/inmuebles',
    }),
    tiposTelefonos: builder.query<readonly Enumerable[], void>({
      query: (): string => 'datos/tipos-telefonos',
    }),
    profesiones: builder.query<readonly Enumerable[], void>({
      query: (): string => 'datos/profesiones',
    }),
    estadosCiviles: builder.query<readonly Enumerable[], void>({
      query: (): string => 'datos/estados-civiles',
    }),
    actualizarSolicitante: builder.mutation<void, UpdateUserProfileRequest>({
      query: (req: UpdateUserProfileRequest): FetchArgs => ({
        url: 'usuarios/yo/perfil',
        method: 'PUT',
        body: req,
      }),
      invalidatesTags: ['CurrentUser'],
    }),
    user: builder.query<User, void>({
      query: (): string => 'usuarios/yo',
      providesTags: ['CurrentUser'],
    }),
    userProfile: builder.query<Requester, void>({
      query: (): string => 'usuarios/yo/perfil',
    }),
    login: builder.mutation<void, LoginRequest>({
      query: (req: LoginRequest): FetchArgs => ({
        url: 'accesos/sesiones',
        method: 'POST',
        body: req,
      }),
    }),
    register: builder.mutation<Session, RegisterRequest>({
      query: (req: RegisterRequest): FetchArgs => ({
        url: 'accesos/cuentas',
        method: 'POST',
        body: req,
      }),
    }),
    userRequests: builder.query<
      Page<Request>,
      GetAllRequestsRequest | GetServiceRequestsRequest | GetProcedureRequestsRequest
    >({
      query: (filters: GetProcedureRequestsRequest): string => {
        const { idGrupoTramites = undefined, idTramite = undefined, ...query } = filters;

        const base = ['tramites', idGrupoTramites, idTramite, 'solicitudes']
          .filter(
            (fragment: string | number | undefined): fragment is string | number => !!fragment,
          )
          .join('/');

        return `${base}${toQueryString(query)}`;
      },
      providesTags: ['Solicitudes'],
    }),
    submitStage: builder.mutation<void, SubmitStageRequest>({
      query: (req: SubmitStageRequest): FetchArgs => ({
        url: `solicitudes/${req.idSolicitud}`,
        method: 'POST',
      }),
    }),
    updateRequestStepData: builder.mutation<void, UpdateRequestStepRequest>({
      query: (req: UpdateRequestStepRequest) => {
        const { datos } = req;

        return {
          url: `solicitudes/${req.idSolicitud}/${req.idPaso}`,
          method: 'PUT',
          body: { datos },
        };
      },
      invalidatesTags: ['Solicitudes'],
    }),
    requestStepData: builder.query<GetRequestStepResponse, GetRequestStepRequest>({
      query: (req: GetRequestStepRequest): string => {
        const { idSolicitud } = req;
        return `solicitudes/${idSolicitud}/${req.idPaso}`;
      },
    }),
    createRequest: builder.mutation<Request, CreateRequestRequest>({
      query: (req: CreateRequestRequest): FetchArgs => ({
        url: `tramites/${req.idGrupoTramites}/${req.idTramite}/solicitudes`,
        method: 'POST',
        body: req,
      }),
    }),
    cancelRequest: builder.mutation<void, string>({
      query: (id: string): FetchArgs => ({
        url: `solicitudes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Solicitudes'],
    }),
    services: builder.query<GetServicesResponse, void>({
      query: (): string => 'servicios',
    }),
    procedures: builder.query<GetProceduresResponse, number>({
      query: (idServicio: number): string => `servicios/${idServicio}/tramites`,
    }),
    steps: builder.query<GetProcedureStepsResponse, GetProcedureStepsRequest>({
      query: (req: GetProcedureStepsRequest): string =>
        `servicios/${req.idServicio}/tramites/${req.idTramite}/pasos/${req.idLineaTiempo}`,
    }),
    components: builder.query<readonly Component[], GetProcedureStepComponentsRequest>({
      query: (req: GetProcedureStepComponentsRequest): string =>
        `servicios/${req.idServicio}/tramites/${req.idTramite}/pasos/${req.idPaso}/componentes`,
    }),
    getFee: builder.query<Fee, GetFeeRequest>({
      query: (req: GetFeeRequest): string => `solicitudes/${req.idSolicitud}/tarifa`,
    }),
    fileMetadata: builder.query<FileMetadata, GetFileMetadataRequest>({
      query: (req: GetFileMetadataRequest): string => `cdn/${req.idArchivo}/meta`,
    }),
    currentTimelinePoint: builder.query<
      GetCurrentTimelinePointResponse,
      GetCurrentTimelinePointRequest
    >({
      query: (req: GetCurrentTimelinePointRequest): string =>
        `solicitudes/${req.idSolicitud}/linea-tiempo`,
    }),
  }),
});

// Export the entire API
export default api;

export const {
  useStatesQuery,
  useMunicipalitiesQuery,
  useParishesQuery,
  useCitiesQuery,
  useGetParroquiaQuery,
  useObjectsQuery,
  useLocalidadesQuery,
  useInmueblesQuery,
  useTiposTelefonosQuery,
  useLoginMutation,
  useUserQuery,
  useProfesionesQuery,
  useEstadosCivilesQuery,
  useRegisterMutation,
  useActualizarSolicitanteMutation,
  useUserProfileQuery,
  useServicesQuery,
  useProceduresQuery,
  useStepsQuery,
  useComponentsQuery,
  useUserRequestsQuery,
  useCreateRequestMutation,
  useCancelRequestMutation,
  useUpdateRequestStepDataMutation,
  useRequestStepDataQuery,
  useGetFeeQuery,
  useFileMetadataQuery,
  useSubmitStageMutation,
  useCurrentTimelinePointQuery,
} = api;
