import { SolicitanteJuridicoGobiernoGacetaForm } from 'components/CompleteRegistration/forms/JuridicoGobierno/JuridicoGobiernoGaceta/schema';
import { Attribute, AttributeType } from 'components/Form/types';

const fields: readonly Attribute<SolicitanteJuridicoGobiernoGacetaForm>[] = [
  {
    type: AttributeType.text,
    name: 'numero',
    label: 'Número Gaceta',
    required: true,
  },
  {
    type: AttributeType.date,
    name: 'fecha',
    label: 'Fecha Gaceta',
    required: true,
  },
];

export default fields;
