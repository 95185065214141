import { Box, SxProps, Theme, Typography } from '@mui/material';
import Day from 'components/Calendar/components/day';
import React from 'react';

interface OwnProps {
  readonly date: Date;
  onChange?(date: Date): void;
}

type Props = React.PropsWithoutRef<OwnProps> & React.RefAttributes<any>;

const MonthView: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: React.Ref<HTMLDivElement>): React.ReactElement => {
    const { date, onChange } = props;

    const start = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    const currentCount = countMonthDays(date);
    const previousCount = countMonthDays(date, -1);
    const month = date.getMonth();

    const handleClick = React.useCallback(
      (day: number): void => {
        onChange?.(new Date(date.getFullYear(), date.getMonth(), day));
      },
      [date, onChange],
    );

    return (
      <Box ref={ref} sx={gridStyle}>
        {headers.map((name: string): React.ReactElement => {
          return (
            <Box key={name} height="32px">
              <Typography
                align="center"
                color="#888"
                fontSize={12}
                lineHeight="24px"
                fontWeight={600}
              >
                {name}
              </Typography>
            </Box>
          );
        })}
        {days
          .slice(0, start)
          .map((_: number, index: number): number => previousCount - start + index + 1)
          .map((day: number): React.ReactElement => {
            const key = `<${month}${day}`;

            return <Day key={key} variant="previous" value={day} />;
          })}
        {days
          .slice(start, currentCount + start)
          .map((_: number, index: number): number => index + 1)
          .map((day: number): React.ReactElement => {
            const key = `=${month}${day}`;
            const variant = day === date.getDate() ? 'selected' : 'current';

            return <Day key={key} variant={variant} value={day} onClick={handleClick} />;
          })}
        {days
          .slice(currentCount + start)
          .map((_: number, index: number): number => index + 1)
          .map((day: number): React.ReactElement => {
            const key = `>${month}${day}`;

            return <Day key={key} variant="next" value={day} />;
          })}
      </Box>
    );
  },
);

export default MonthView;

const formatter = new Intl.DateTimeFormat(undefined, { weekday: 'short' });
const headers = [0, 1, 2, 3, 4, 5, 6].map((index: number): string => {
  const today = new Date();
  today.setDate(today.getDate() + (index - today.getDay()));

  return formatter.format(today);
});

const gridStyle: SxProps<Theme> = {
  display: 'grid',
  width: '100%',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gridTemplateRows: 'repeat(7, 1fr)',
  marginTop: 1,
  textAlign: 'center',
};

const countMonthDays = (date: Date, extra?: number): number => {
  const month = date.getMonth() + (extra ?? 0) + 1;
  const year = date.getFullYear();
  const endOfMonth = new Date(year, month, 0);

  return endOfMonth.getDate();
};

const days = new Array(42).fill(0);
