import classes from 'components/ScrollArea/scrollArea.module.scss';
import React, { CSSProperties } from 'react';

interface ScrollbarState {
  readonly height: number;
  readonly top: number;
  readonly maxTop: number;
  readonly hiddenFraction: number;
}

const initialScrollbarState: ScrollbarState = {
  height: 0,
  top: 0,
  maxTop: 0,
  hiddenFraction: 0,
};

const ScrollArea: React.FC<React.PropsWithChildren> = (
  props: React.PropsWithChildren,
): React.ReactElement => {
  const { children } = props;
  const [scrollbarState, setScrollbarState] = React.useState<ScrollbarState>(initialScrollbarState);
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);
  const [contentWrapper, setContentWrapper] = React.useState<HTMLDivElement | null>(null);
  const [grabbed, setGrabbed] = React.useState<boolean>(false);

  const installScrollbars = React.useCallback(
    (availableHeight: number, neededHeight: number): void => {
      const hiddenFraction = (neededHeight - availableHeight) / neededHeight;
      setScrollbarState(
        (currentState: ScrollbarState): ScrollbarState => ({
          ...currentState,
          hiddenFraction: hiddenFraction,
          height: availableHeight * (1 - hiddenFraction),
          maxTop: availableHeight * hiddenFraction,
        }),
      );
    },
    [],
  );

  React.useEffect((): VoidFunction | void => {
    if (container === null || contentWrapper === null) {
      return;
    }

    const parent = container.parentElement;
    if (parent === null) {
      return;
    }

    if (parent.scrollHeight <= parent.offsetHeight) {
      return;
    }

    const savedStyle = { ...parent.style };
    const computedStyle = getComputedStyle(parent);
    // If there is padding, it affects the height of the content
    const padding = parseInt(computedStyle.paddingTop) + parseInt(computedStyle.paddingBottom);

    Object.assign(parent.style, createStyle(parent));
    installScrollbars(parent.offsetHeight, contentWrapper.scrollHeight + padding);

    return (): void => {
      Object.assign(parent.style, savedStyle);
    };
  }, [container, contentWrapper, installScrollbars]);

  const thumbStyle = React.useMemo(
    (): CSSProperties => ({ height: scrollbarState.height, top: scrollbarState.top }),
    [scrollbarState],
  );

  const thumbClassName = React.useMemo((): string => {
    if (grabbed) {
      return [classes.thumb, classes.grabbed].join(' ');
    }

    return classes.thumb;
  }, [grabbed]);

  const scrollBy = React.useCallback((delta: number): void => {
    setScrollbarState((currentState: ScrollbarState): ScrollbarState => {
      const computed = currentState.top + delta;
      const clamped = Math.min(Math.max(computed, 0), currentState.maxTop);

      return {
        ...currentState,
        top: clamped,
      };
    });
  }, []);

  const grab = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>): void => {
      setGrabbed(true);

      event.stopPropagation();
      event.preventDefault();

      const onMove = (event: Event): void => {
        event.stopPropagation();
        event.preventDefault();

        if (event instanceof MouseEvent) {
          scrollBy(event.movementY);
        }
      };

      const onMouseUp = (): void => {
        setGrabbed(false);

        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [scrollBy],
  );

  React.useEffect((): VoidFunction | void => {
    if (container === null || contentWrapper === null) {
      return;
    }

    const parent = container.parentElement;
    if (parent === null) {
      return;
    }

    const onWheel = (event: WheelEvent): void => {
      scrollBy(event.deltaY / 4);
    };

    parent.addEventListener('wheel', onWheel, { passive: true });
    return (): void => {
      parent.removeEventListener('wheel', onWheel);
    };
  }, [container, contentWrapper, scrollBy]);

  React.useEffect((): VoidFunction | void => {
    if (container === null) {
      return;
    }

    const parent = container.parentElement;
    if (parent === null) {
      return;
    }

    let triggeredByUs = false;

    const preventScroll = (value: number): void => {
      triggeredByUs = true;
      parent.scrollTop = Math.min(value, 1);

      setTimeout((): void => {
        triggeredByUs = false;
      }, 0);
    };

    const update = (): void => {
      const scrolledPercent = parent.scrollTop / (parent.scrollHeight - parent.offsetHeight);

      preventScroll(parent.scrollTop);

      setScrollbarState((currentState: ScrollbarState): ScrollbarState => {
        return {
          ...currentState,
          top: scrolledPercent * currentState.maxTop,
        };
      });
    };

    const onScroll = (event: Event): void => {
      if (!triggeredByUs) {
        event.stopPropagation();
        event.preventDefault();

        update();
      }
    };

    parent.addEventListener('scroll', onScroll);
    return (): void => {
      parent.removeEventListener('scroll', onScroll);
    };
  }, [container]);

  const contentStyle = React.useMemo((): CSSProperties => {
    if (container === null || contentWrapper === null) {
      return {};
    }

    const parent = container.parentElement;
    if (parent === null) {
      return {};
    }

    const scrolledPercent = scrollbarState.top / scrollbarState.maxTop;
    const hiddenHeight = contentWrapper.scrollHeight * scrollbarState.hiddenFraction;

    return { transform: `translateY(-${hiddenHeight * scrolledPercent - parent.scrollTop}px)` };
  }, [
    container,
    contentWrapper,
    scrollbarState.hiddenFraction,
    scrollbarState.maxTop,
    scrollbarState.top,
  ]);

  const contentClassName = React.useMemo((): string => {
    if (grabbed) {
      return [classes.content, classes.grabbed].join(' ');
    }

    return classes.content;
  }, [grabbed]);

  return (
    <div className={classes.wrapper} ref={setContainer}>
      <div style={contentStyle} className={contentClassName} ref={setContentWrapper}>
        {children}
      </div>

      <div className={classes.scrollbar}>
        <div style={thumbStyle} className={thumbClassName} onMouseDown={grab} />
      </div>
    </div>
  );
};

export default ScrollArea;

const createStyle = (element: HTMLElement): Partial<CSSStyleDeclaration> => {
  const style: Partial<CSSStyleDeclaration> = {
    overflow: 'hidden',
  };

  const currentStyle = getComputedStyle(element);
  if (currentStyle.position === 'static') {
    style.position = 'relative';
  }

  return style;
};
