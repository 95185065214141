import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ControlData } from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import React from 'react';

interface Props {
  readonly name: string;
  readonly items: readonly string[];
  readonly data?: ControlData;
  readonly defaultValue?: string;
}

const DropdownWithOptions: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, items, data, defaultValue } = props;
  const [localValue, setLocalValue] = React.useState<string>('');

  const handleChange = React.useCallback((event: SelectChangeEvent) => {
    const { value } = event.target;
    setLocalValue(value);
  }, []);

  React.useEffect((): void => {
    if (!defaultValue) {
      return;
    }

    setLocalValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Select value={localValue} fullWidth={true} onChange={handleChange}>
        {items.map(
          (item: string): React.ReactElement => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ),
        )}
      </Select>
      <input name={name} value={localValue} type="hidden" {...data} />
    </>
  );
};

export default DropdownWithOptions;
