import MapComponent from 'components/ServicesCollectionsForms/MapComponent';
import React, { CSSProperties } from 'react';

interface Props {
  readonly name: string;
  readonly defaultValues: any;
}

const RequestMapComponentRenderer: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, defaultValues } = props;

  return (
    <form style={formStyle} name={name}>
      <MapComponent defaultValues={defaultValues} orientation="horizontal" />
    </form>
  );
};

const formStyle: CSSProperties = {
  height: '100%',
  width: '100%',
};

export default RequestMapComponentRenderer;
