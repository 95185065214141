import {
  CheckCircleOutlineOutlined,
  ErrorSharp,
  FileUpload,
  TimelapseOutlined,
} from '@mui/icons-material';
import { Status } from 'components/FileInput/types';
import React from 'react';

interface Props {
  readonly status: Status;
}

const Icon: React.FC<Props> = (props: Props): React.ReactElement => {
  void props;
  switch (props.status) {
    case Status.success:
      return <CheckCircleOutlineOutlined fontSize="large" color="success" />;
    case Status.error:
      return <ErrorSharp fontSize="large" color="error" />;
    case Status.none:
      return <FileUpload fontSize="large" color="secondary" />;
    case Status.uploading:
      return <TimelapseOutlined fontSize="large" color="secondary" />;
  }
};

export default Icon;
