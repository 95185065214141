import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SxProps,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { Service } from '@s3comsecurity/user-configuration';
import logo from 'assets/images/logo-dark.png';
import SubMenu from 'components/ApplicationMenu/components/SubMenu';
import theme from 'components/ApplicationMenu/theme';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useServicesQuery } from 'redux/API';

const ApplicationMenu: React.FC = (): React.ReactElement => {
  const { data } = useServicesQuery();
  const { servicios = [] } = data ?? {};

  return (
    <ThemeProvider theme={theme}>
      <Box sx={styles.container} flexGrow={1}>
        <Box position="sticky" top={0} bgcolor="background.default" zIndex={1}>
          <Box sx={styles.logo}>
            <img alt="logo" src={logo} />
          </Box>
          <List disablePadding={true}>
            <ListItem disableGutters={true}>
              <ListItemButton component={NavLink} to="/">
                <ListItemText>Inicio</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
        <Box sx={styles.list}>
          <List disablePadding={true}>
            {servicios.map(
              (servicio: Service): React.ReactElement => (
                <SubMenu key={servicio.id} service={servicio} />
              ),
            )}
          </List>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ApplicationMenu;

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  list: {
    flex: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderRadius: 0,
      transition: 'background-color 0.2s',

      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
    },
  },
  logo: {
    padding: 2,
    textAlign: 'center',
    img: {
      maxWidth: '100%',
    },
  },
};
