export enum MimeTypes {
  pdf = 'application/pdf',
  jpeg = 'image/jpeg',
  png = 'image/png',
}

export enum Status {
  none = 0,
  uploading = 1,
  success = 2,
  error = 3,
}
