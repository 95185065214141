import React from 'react';

export const useFormSlice = (
  watchedElementsNames: readonly string[] | undefined,
  form: HTMLFormElement | null,
): Readonly<Record<string, string | number | boolean>> | undefined => {
  const [value, setValue] = React.useState<
    Readonly<Record<string, string | number | boolean>> | undefined
  >(undefined);

  React.useEffect((): VoidFunction | void => {
    if (form === null) {
      return;
    }

    if (watchedElementsNames === undefined) {
      setValue({});
      return;
    }

    if (!Array.isArray(watchedElementsNames)) {
      throw new Error('names must be an array');
    }

    const handleFormChange = (event: Event): void => {
      const { target } = event;
      if (target instanceof HTMLInputElement || target instanceof HTMLSelectElement) {
        const { name, value } = target;
        if (name === undefined || value === undefined || !watchedElementsNames.includes(name)) {
          return;
        }

        const getTypedValue = (): number | string | boolean => {
          if (target instanceof HTMLInputElement && target.type === 'checkbox') {
            return target.checked;
          } else if (!isNaN(Number(value))) {
            return Number(value);
          } else {
            return value;
          }
        };

        setValue(
          (
            formValue: Readonly<Record<string, string | number | boolean>> | undefined,
          ): Readonly<Record<string, string | number | boolean>> => {
            if (formValue === undefined) {
              return { [name]: getTypedValue() };
            }

            return { ...formValue, [name]: getTypedValue() };
          },
        );
      }
    };

    form.addEventListener('input', handleFormChange);
    return (): void => {
      form.removeEventListener('input', handleFormChange);
    };
  }, [form, watchedElementsNames]);

  return value;
};
