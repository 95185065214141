import { DeleteOutlined, EditNoteRounded, Preview } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React from 'react';

interface Props {
  readonly itemId: string | number;

  onAction(action: 'view' | 'edit' | 'remove', itemId: string | number): void;
}

const ActionsCell: React.FC<Props> = (props: Props): React.ReactElement => {
  const { onAction } = props;
  const { itemId } = props;

  const handlePreviewItem = React.useCallback((): void => {
    onAction('view', itemId);
  }, [itemId, onAction]);

  const handleEditItem = React.useCallback((): void => {
    onAction('edit', itemId);
  }, [itemId, onAction]);

  const handleRemoveItem = React.useCallback((): void => {
    onAction('remove', itemId);
  }, [itemId, onAction]);

  return (
    <Box display="flex" gap={1}>
      <IconButton size="small" onClick={handlePreviewItem}>
        <Preview />
      </IconButton>
      <IconButton size="small" onClick={handleEditItem}>
        <EditNoteRounded />
      </IconButton>
      <IconButton size="small" onClick={handleRemoveItem}>
        <DeleteOutlined />
      </IconButton>
    </Box>
  );
};

export default ActionsCell;
