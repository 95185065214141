import { List, Paper } from '@mui/material';
import ProgressItem from 'components/Stepper/components/ProgressItem';
import { StepProps } from 'components/Stepper/stepProps';
import { styles } from 'components/Stepper/styles';
import React from 'react';

interface Props {
  readonly steps: readonly React.ReactElement<StepProps<any>>[];
  readonly active: number;
}

const Progress: React.FC<Props> = (props: Props): React.ReactElement => {
  const { steps, active } = props;

  return (
    <Paper sx={styles.progress} elevation={0}>
      <List>
        {steps.map(
          (step: React.ReactElement<StepProps<any>>, index: number): React.ReactElement => {
            const { name, title } = step.props;

            return (
              <ProgressItem
                key={name}
                title={title}
                completed={index < active}
                active={active === index}
                last={index === steps.length - 1}
              />
            );
          },
        )}
      </List>
    </Paper>
  );
};

export default Progress;
