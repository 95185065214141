import { Enumerable } from '@s3comsecurity/foundations';

export type FetchStatus = {
  readonly isFetching: boolean;
};

const useNoQuery = (): any => {
  console.warn('calling useNoQuery');
  return [[], { isFetching: false }];
};

export const useNamedEndpoint = (
  api: any,
  endpointName: string,
  parameters?: Readonly<Record<string, string | number | boolean>>,
): [readonly Enumerable[], FetchStatus] => {
  const { endpoints } = api;

  const endpoint = endpoints[endpointName];
  const useQuery = endpoint?.useQuery ?? useNoQuery;
  const { data: list = [], isFetching } = useQuery(parameters, {
    skip: !parameters,
  });

  return [list, { isFetching }];
};
