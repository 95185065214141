import { Box } from '@mui/material';
import Toolbar from 'components/Calendar/components/header';
import MonthView from 'components/Calendar/components/monthView';
import YearView from 'components/Calendar/components/yearView';
import { ViewType } from 'components/Calendar/types/viewType';
import Pager from 'components/Pager';
import React from 'react';

interface Props {
  readonly date: Date;
  onChange?(date: Date): void;
}

interface FormattedMonthYear {
  readonly month: string;
  readonly year: number;
}

const Calendar: React.FC<Props> = (props: Props): React.ReactElement => {
  const { date, onChange } = props;
  const { month, year } = React.useMemo((): FormattedMonthYear => {
    return {
      month: monthFormatter.format(date),
      year: date.getFullYear(),
    };
  }, [date]);
  const [currentView, setCurrentView] = React.useState<ViewType>(ViewType.month);

  const handleNextMonth = React.useCallback((): void => {
    onChange?.(new Date(date.getFullYear(), date.getMonth() + 1, date.getDate()));
  }, [date, onChange]);

  const handlePreviousMonth = React.useCallback((): void => {
    onChange?.(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()));
  }, [date, onChange]);

  const handleExpandYear = React.useCallback((): void => {
    setCurrentView((currentView: ViewType): ViewType => {
      switch (currentView) {
        case ViewType.month:
          return ViewType.year;
        case ViewType.year:
          return ViewType.month;
      }
    });
  }, []);

  return (
    <Box padding={1}>
      <Toolbar
        month={month}
        year={year}
        currentView={currentView}
        onNextMonth={handleNextMonth}
        onPreviousMonth={handlePreviousMonth}
        onExpandYear={handleExpandYear}
      />
      <Pager currentPage={currentView}>
        <MonthView date={date} onChange={onChange} />
        <YearView date={date} onChange={onChange} />
      </Pager>
    </Box>
  );
};

const monthFormatter = new Intl.DateTimeFormat(undefined, { month: 'long' });

export default Calendar;
