import { FormLabel, Typography } from '@mui/material';
import { Attribute, AttributeType } from 'components/Form/types';
import React from 'react';

interface Props<T> {
  readonly field: Attribute<T>;
}

function FieldLabel<T>(props: Props<T>): React.ReactElement {
  const { field } = props;
  const { required = false } = field;

  if (field.type === AttributeType.custom) {
    return <></>;
  }

  return (
    <FormLabel>
      {field.label}
      <Typography component="span" color="error">
        &nbsp;{required ? '*' : ''}
      </Typography>
    </FormLabel>
  );
}

export default FieldLabel;
