import { CheckCircle, Error as ErrorIcon, Info, Warning } from '@mui/icons-material';
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { useCheckNonStaticParent } from 'hooks/useCheckNonStaticParent';
import React from 'react';

export enum MessageType {
  none,
  success,
  warning,
  error,
  information,
}

interface Props {
  readonly type: MessageType;
  readonly message: string;

  onClose?(): void;
}

const OverlayMessageBox: React.FC<Props> = (props: Props): React.ReactElement => {
  const { type, message, onClose } = props;
  const ref = useCheckNonStaticParent();

  if (type === MessageType.none) {
    return <Box ref={ref} />;
  }

  return (
    <Box sx={styles.overlay} ref={ref}>
      {icons[type]}
      <Typography fontSize={24} fontWeight={700}>
        {titles[type]}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {message}
      </Typography>
      <Box marginTop={2}>
        <Button
          variant="contained"
          size="large"
          color={colors[type]}
          disableElevation={true}
          onClick={onClose}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
};

export default OverlayMessageBox;

const styles: Record<string, SxProps<Theme>> = {
  overlay: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    inset: 0,
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.75)',
    backdropFilter: 'blur(4px)',
    borderRadius: 1,
    gap: 3,
    zIndex: 1,
    p: 20,
  },
  icon: {
    width: 64,
    height: 64,
  },
};

const titles: Record<MessageType, string> = {
  [MessageType.success]: 'Listo',
  [MessageType.information]: 'Información',
  [MessageType.warning]: 'Advertencia',
  [MessageType.error]: 'Error',
  [MessageType.none]: '',
};

const colors: Record<MessageType, 'success' | 'error' | 'warning' | 'info' | undefined> = {
  [MessageType.success]: 'success',
  [MessageType.information]: 'info',
  [MessageType.warning]: 'warning',
  [MessageType.error]: 'error',
  [MessageType.none]: undefined,
};

const icons: Record<MessageType, React.ReactElement | null> = {
  [MessageType.success]: <CheckCircle sx={styles.icon} color="success" />,
  [MessageType.information]: <Info sx={styles.icon} color="info" />,
  [MessageType.warning]: <Warning sx={styles.icon} color="warning" />,
  [MessageType.error]: <ErrorIcon sx={styles.icon} color="error" />,
  [MessageType.none]: null,
};
