import { CheckCircle } from '@mui/icons-material';
import { Box, ListItem, SxProps, Theme, Typography } from '@mui/material';
import Render from 'components/Render';
import { styles } from 'components/Stepper/components/styles';
import React from 'react';

interface Props {
  readonly title: string;
  readonly completed: boolean;
  readonly active: boolean;
  readonly last: boolean;
}

interface ColorSet {
  readonly text: 'primary' | 'text' | 'secondary';
  readonly icon: 'success' | 'secondary';
  readonly link: 'success.main' | 'secondary.main';
}

const ProgressItem: React.FC<Props> = (props: Props): React.ReactElement => {
  const { title, completed, active, last } = props;

  const colors = React.useMemo(
    (): ColorSet => ({
      text: active ? 'primary' : completed ? 'text' : 'secondary',
      icon: active ? 'success' : completed ? 'success' : 'secondary',
      link: active ? 'secondary.main' : completed ? 'success.main' : 'secondary.main',
    }),
    [active, completed],
  );

  const innerStyle = React.useMemo(
    (): SxProps<Theme> => ({
      ...styles.line,
      ...(completed ? { maxHeight: 64 } : {}),
    }),
    [completed],
  );

  return (
    <ListItem key={title} sx={styles.item} disableGutters>
      <Box sx={styles.itemContent}>
        <CheckCircle sx={styles.icon} color={colors.icon} />
        <Typography variant="h6" color={colors.text}>
          {title}
        </Typography>
      </Box>
      <Render when={!last}>
        <Box sx={styles.itemLink}>
          <Box sx={innerStyle} />
        </Box>
      </Render>
    </ListItem>
  );
};

export default ProgressItem;
