import { Box, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { ControlData } from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import React from 'react';

interface Props {
  readonly name: string;
  readonly data?: ControlData;

  readonly defaultValue?: string;
}

const PhoneNumberInput: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, data } = props;

  const [areaCode, setAreaCode] = React.useState<string>('0424');
  const [number, setNumber] = React.useState<string>('');

  const handleAreaCodeChange = React.useCallback((event: SelectChangeEvent): void => {
    const { value } = event.target;
    setAreaCode(value);
  }, []);

  const handleNumberChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      const numeric = Number(value);
      if (isNaN(numeric)) {
        return;
      }

      setNumber(value.slice(0, 7));
    },
    [],
  );

  const value = React.useMemo((): string => [areaCode, number].join('-'), [areaCode, number]);

  return (
    <Box display="flex" alignItems="center" justifyContent="stretch" gap={1}>
      <Box flexGrow={0} width="12ex">
        <Select fullWidth={true} value={areaCode} onChange={handleAreaCodeChange}>
          <MenuItem value="0424">0424</MenuItem>
          <MenuItem value="0414">0414</MenuItem>
          <MenuItem value="0412">0412</MenuItem>
          <MenuItem value="0416">0416</MenuItem>
          <MenuItem value="0426">0426</MenuItem>
        </Select>
      </Box>
      <Box flex={1}>
        <OutlinedInput
          value={number}
          placeholder="1234567"
          fullWidth={true}
          onChange={handleNumberChange}
        />
      </Box>

      <input type="hidden" name={name} value={value} {...data} />
    </Box>
  );
};

export default PhoneNumberInput;
