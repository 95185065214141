import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, Button, ButtonBase, SxProps, Theme, Typography } from '@mui/material';
import Clicker from 'components/Clicker';
import React from 'react';

interface Props {
  readonly date: Date;

  onChange?(date: Date): void;
}

const YearView: React.FC<Props> = (props: Props): React.ReactElement => {
  const { date, onChange } = props;
  const [offset, setOffset] = React.useState<number>(0);

  const handleYearClick = React.useCallback(
    (year: number): void => {
      onChange?.(new Date(year, date.getMonth(), date.getDate()));
    },
    [date, onChange],
  );

  const handleGoBack = React.useCallback((): void => {
    setOffset((offset: number): number => offset + 2 * (pad + 1));
  }, []);

  const handleGoNext = React.useCallback((): void => {
    setOffset((offset: number): number => offset - 2 * (pad + 1));
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.grid}>
        {years.map((_: number, index: number): React.ReactElement => {
          const year = initialYear - pad + index - offset;

          return (
            <Box key={year} sx={styles.cell}>
              <Clicker data={year} onClick={handleYearClick}>
                <ButtonBase sx={styles.number}>
                  <Typography>{year}</Typography>
                </ButtonBase>
              </Clicker>
            </Box>
          );
        })}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button size="small" startIcon={<ArrowLeft />} onClick={handleGoBack}>
          Ant.
        </Button>
        <Button size="small" endIcon={<ArrowRight />} onClick={handleGoNext}>
          Sig.
        </Button>
      </Box>
    </Box>
  );
};

export default YearView;

const initialYear = new Date().getFullYear();
const pad = 7;
const years = new Array(2 * (pad + 1)) //
  .fill(0);

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  number: {
    borderRadius: 1,
    px: 1,
    py: 0.5,
  },
  grid: {
    display: 'grid',
    flex: 1,
    gridTemplateColumns: `repeat(${(pad + 1) / 2}, 1fr)`,
    my: 1,
  },
};
