import { SolicitanteNaturalDatos } from 'components/CompleteRegistration/forms/Natural/steps/DatosPersonales/schema';
import { Attribute, AttributeType } from 'components/Form/types';
import { useEstadosCivilesQuery, useProfesionesQuery } from 'redux/API';

const fields: readonly Attribute<SolicitanteNaturalDatos>[] = [
  {
    type: AttributeType.text,
    name: 'primerNombre',
    label: 'Primer Nombre',
    required: true,
  },
  {
    type: AttributeType.text,
    name: 'segundoNombre',
    label: 'Segundo Nombre',
  },
  {
    type: AttributeType.text,
    name: 'primerApellido',
    label: 'Primer Apellido',
    required: true,
  },
  {
    type: AttributeType.text,
    name: 'segundoApellido',
    label: 'Segundo Apellido',
    required: true,
  },
  {
    type: AttributeType.dropdown,
    name: 'idProfesion',
    label: 'Profesión',
    useQuery: useProfesionesQuery,
    required: true,
  },
  {
    type: AttributeType.dropdown,
    name: 'idEstadoCivil',
    label: 'Estado Civil',
    useQuery: useEstadosCivilesQuery,
    required: true,
  },
  {
    type: AttributeType.dropdown,
    name: 'sexo',
    label: 'Sexo',
    data: [
      {
        id: 'F',
        label: 'Femenino',
      },
      {
        id: 'M',
        label: 'Masculino',
      },
    ],
    required: true,
  },
  {
    type: AttributeType.date,
    name: 'fechaNacimiento',
    label: 'Fecha de Nacimiento',
  },
];

export default fields;
