import { Box, ButtonBase, SxProps, Theme } from '@mui/material';
import StepIcon from 'components/DynamicStepper/components/StepIcon';
import React, { CSSProperties } from 'react';

interface Props {
  readonly label: string;
  readonly position: number;
  readonly status: 'passed' | 'active' | 'pending';
}

const StepIndicator: React.FC<Props> = (props: Props): React.ReactElement => {
  const { label, position, status } = props;

  const iconStyle = React.useMemo((): SxProps<Theme> => {
    switch (status) {
      case 'pending':
        return [styles.icon, styles.pendingIcon];
      case 'passed':
      case 'active':
        return [styles.icon, styles.activeIcon];
    }
  }, [status]);

  const labelStyle = React.useMemo((): SxProps<Theme> => {
    switch (status) {
      case 'pending':
        return { ...styles.label, ...styles.pendingLabel };
      case 'passed':
        return styles.label;
      case 'active':
        return { ...styles.label, ...styles.activeLabel };
    }
  }, [status]);

  return (
    <ButtonBase component={Box} sx={styles.container}>
      <Box sx={iconStyle}>
        <StepIcon status={status} position={position} />
      </Box>
      <Box sx={labelStyle}>{label}</Box>
    </ButtonBase>
  );
};

export default StepIndicator;

const iconSize = 28;
const styles: Record<string, CSSProperties> = {
  container: {
    display: 'block',
    width: 200,
    padding: '8px',
    borderRadius: 2,
    color: 'primary.light',
  },
  icon: {
    width: iconSize,
    height: iconSize,
    lineHeight: `${iconSize}px`,
    borderRadius: 14,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  pendingIcon: {
    opacity: 0.5,
    backgroundColor: 'secondary.dark',
    color: 'text.secondary',
  },
  activeIcon: {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginTop: 2,
    textAlign: 'center',
    color: 'text.secondary',
  },
  pendingLabel: {
    opacity: 0.5,
    color: 'text.secondary',
  },
  activeLabel: {
    color: 'primary.main',
  },
};
