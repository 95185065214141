import * as yup from 'yup';

export interface SolicitanteJuridicoGobiernoGacetaForm {
  readonly numero: string;
  readonly fecha: string;
}

export default yup.object<SolicitanteJuridicoGobiernoGacetaForm>().shape({
  numero: yup
    .string() //
    .required(),
  fecha: yup
    .string() //
    .required(),
});
