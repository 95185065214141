import { Component, ComponentType } from '@s3comsecurity/foundations';
import { Step } from '@s3comsecurity/user-configuration';
import Inventory from 'components/Inventory';
import PaymentForm from 'components/PaymentForm';
import DynamicFormRenderer from 'components/RequestLayout/components/DynamicFormRenderer';
import RequestMapComponentRenderer from 'components/RequestMapComponentRenderer';
import RequestLetter from 'components/ServicesCollectionsForms/RequestLetter';
import React from 'react';
import { useRequestStepDataQuery } from 'redux/API';

interface Props {
  readonly component: Component;
  readonly serviceId: number;
  readonly procedureId: number;
  readonly requestId: string;
  readonly stepId: number;

  readonly steps: readonly Step[];
}

const RequestComponentRenderer: React.FC<Props> = (props: Props): React.ReactElement => {
  const { component, steps, requestId, stepId, serviceId, procedureId } = props;
  const { data: { datos: defaultValues } = { datos: {} } } = useRequestStepDataQuery(
    {
      idSolicitud: requestId,
      idPaso: stepId,
    },
    {
      skip: requestId === undefined || stepId === null,
    },
  );

  const currentStep = React.useMemo((): Step | undefined => {
    if (steps === null || steps.length === 0) {
      return { id: -1, etiqueta: 'No hay pasos', nombre: 'No hay pasos' };
    }

    return steps.find((step: Step): boolean => step.id === stepId);
  }, [stepId, steps]);

  const uploadPrefix = React.useMemo((): string => {
    return ['', serviceId, procedureId, stepId, requestId]
      .map((id: number | string): string => {
        if (typeof id === 'string') {
          return id;
        }

        return id.toString().padStart(8, '0');
      })
      .join('/');
  }, [procedureId, requestId, serviceId, stepId]);

  if (!currentStep) {
    return <></>;
  }

  switch (component.tipo) {
    case ComponentType.inventory:
      return <Inventory fields={component.atributos} defaultValues={defaultValues} />;
    case ComponentType.form:
      return (
        <DynamicFormRenderer
          fields={component.atributos}
          formName={currentStep.nombre}
          uploadPrefix={uploadPrefix}
          defaultValues={defaultValues}
        />
      );
    case ComponentType.document:
      return <></>;
    case ComponentType.payment:
      return <PaymentForm />;
    case ComponentType.letter:
      return <RequestLetter name={currentStep.nombre} />;
    case ComponentType.map:
      return (
        <RequestMapComponentRenderer name={currentStep.nombre} defaultValues={defaultValues} />
      );
    default:
      return <></>;
  }
};

export default RequestComponentRenderer;
