function getCurrentDate() {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const text = {
  Titulo:
    'Carta de solicitud para la autorización de instalación, renovación, mantenimiento o ' +
    'cambio de motivo de la valla publicitaria en las vías públicas nacionales',
  Membrete: `Caracas ${getCurrentDate()}`,
  Destinatario:
    'Sr. G/D. Gabriel Arístides Aguana Rodríguez\n' +
    'Presidente\n' +
    'Instituto Nacional de Transporte Terrestre',
  Asunto: 'Instalacion de valla publicitaria en las vías públicas nacionales',
  Motivo: 'Instalacion de Valla Publicitarias en las vías públicas nacionales',
  TipoRegistro: 'Comercial.',
  CuerpoIncio: 'en la oportunidad de solicitar las gestiones para la autorización de:',
  CuerpoFin:
    'por parte del Instituto Nacional de Transporte Terrestre, de acuerdo a las ' +
    'especificaciones que aquí se indican.',
  Cierre:
    'El objetivo de instalación de la valla publicitaria es según las características efectuadas en el registro: ',
  Despedida: 'Agradeciendo su atención quedamos atentos',
};

export default text;
