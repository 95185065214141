import { Box } from '@mui/material';
import { Component } from '@s3comsecurity/foundations';
import { Step } from '@s3comsecurity/user-configuration';
import RequestComponentRenderer from 'components/RequestLayout/components/RequestComponentRenderer';
import Spinner from 'components/Spinner';
import React from 'react';
import { useComponentsQuery } from 'redux/API';

interface Props {
  readonly requestId: string;
  readonly serviceId: number;
  readonly procedureId: number;
  readonly stepId: number;

  readonly steps: readonly Step[];
}

const Components: React.FC<Props> = (props: Props): React.ReactElement => {
  const { procedureId, serviceId, stepId, requestId, steps } = props;

  const { data: components = [], isFetching } = useComponentsQuery({
    idServicio: serviceId,
    idTramite: procedureId,
    idPaso: stepId,
  });

  if (isFetching) {
    return (
      <Box position="absolute" left={0} top={0} right={0} bottom={0}>
        <Spinner spinning={true} size={64} thickness={5} color="primary" />
      </Box>
    );
  }

  return (
    <>
      {components?.map(
        (component: Component): React.ReactElement => (
          <RequestComponentRenderer
            key={component.id}
            serviceId={serviceId}
            procedureId={procedureId}
            requestId={requestId}
            stepId={stepId}
            component={component}
            steps={steps}
          />
        ),
      )}
    </>
  );
};

export default Components;
