import React from 'react';

interface ClickableProps {
  onClick(): void;
}

interface Props<T, _ extends ClickableProps> {
  readonly data: T;
  onClick(data: T): void;
}

function Clicker<T, Q extends ClickableProps>(
  props: React.PropsWithChildren<Props<T, Q>>,
): React.ReactElement {
  const { children: child, data } = props;
  const { onClick } = props;

  const handleClick = React.useCallback((): void => {
    onClick(data);
  }, [data, onClick]);

  if (!React.isValidElement<ClickableProps>(child)) {
    throw new Error('can only have 1 clickable child');
  }

  return React.cloneElement<ClickableProps>(child, {
    onClick: handleClick,
  });
}

export default Clicker;
