import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { Request, Status, TimelineItem } from '@s3comsecurity/requests';
import Render from 'components/Render';
import RequestItemHeader from 'components/ServicesCollectionsForms/RequestsTable/RequestItemHeader';
import StatusIcon from 'components/ServicesCollectionsForms/RequestsTable/StatusIcon';
import StatusLegend from 'components/ServicesCollectionsForms/RequestsTable/StatusLegend';
import Spinner from 'components/Spinner';
import { useRequestsQuery } from 'hooks/useRequestsQuery';
import { useTypedParams } from 'hooks/useTypedParams';
import { ProcedureGroupParameters } from 'parameters/service';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateRequestMutation, useUserRequestsQuery } from 'redux/API';
import { Page } from 'types/page';

type IconProps = StepIconProps & { readonly status: Status };

const StepIcon: React.FC<IconProps> = (props: IconProps): React.ReactElement => {
  return <StatusIcon size={24} status={props.status} />;
};

const RequestsTable: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const query = useRequestsQuery();

  const { serviceId, procedureId } = useTypedParams<Partial<ProcedureGroupParameters>>(
    ProcedureGroupParameters.mayBeFromUnknown,
  );

  const { data: solicitudes = Page.empty() } = useUserRequestsQuery(query);
  const { elementos } = solicitudes;

  const [createRequest, createRequestMutation] = useCreateRequestMutation();

  React.useEffect(() => {
    const { data, status } = createRequestMutation;

    if (status === QueryStatus.fulfilled) {
      navigate(data.id);
    }
  }, [navigate, createRequestMutation]);

  const goToNewRequest = React.useCallback((): void => {
    if (serviceId === undefined || procedureId === undefined) {
      throw new Error('No se puede crear una solicitud sin un tipo de trámite');
    }
    // Nota: tipoTramite es un valor único.
    createRequest({ idGrupoTramites: serviceId, idTramite: procedureId });
  }, [createRequest, procedureId, serviceId]);

  const spinning = React.useMemo(
    (): boolean => createRequestMutation.status === QueryStatus.pending,
    [createRequestMutation.status],
  );

  return (
    <Box sx={styles.container}>
      <Box position="sticky" top={0} zIndex={1} py={1} px={3}>
        <Typography variant="subtitle1" fontSize={20} fontWeight={700}>
          Solicitudes ({solicitudes.total})
        </Typography>
      </Box>
      <Render when={solicitudes.total > 0}>
        <Box flex={1} m={3}>
          {/* FIXME: make the backend return an empty array */}
          {elementos?.map((solicitud: Request): React.ReactElement => {
            const { lineaTiempo: puntos } = solicitud;

            return (
              <Accordion key={solicitud.id}>
                <AccordionSummary
                  expandIcon={
                    <IconButton>
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                >
                  <RequestItemHeader solicitud={solicitud} />
                </AccordionSummary>
                <AccordionDetails>
                  <Stepper alternativeLabel>
                    {puntos?.map((punto: TimelineItem): React.ReactElement => {
                      const icon = (props: StepIconProps): React.ReactElement => (
                        <StepIcon {...props} status={punto.estatus} />
                      );

                      return (
                        <Step key={punto.id} active={punto.estatus === Status.processing}>
                          <StepLabel StepIconComponent={icon}>{punto.nombre}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Render>

      <Render when={solicitudes.total === 0}>
        <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
          <Typography align="center" variant="subtitle1">
            Tiene 0 solicitudes para este trámite
          </Typography>
        </Box>
      </Render>

      <Box sx={styles.legendBox}>
        {solicitudes.total > 0 ? <StatusLegend /> : <Box flex={1} />}
        {procedureId && (
          <Button variant="contained" startIcon={<AddIcon />} onClick={goToNewRequest}>
            Nueva
          </Button>
        )}
      </Box>
      <Spinner spinning={spinning} size={70} thickness={4} color="primary" />
    </Box>
  );
};

export default RequestsTable;

const styles: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: '#f8f8f8',
  },
  legendBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    p: 2,
  },
};
