import { Status } from 'components/FileInput/types';
import { BASE_URL } from 'globalConstants';
import { FileMetadata } from 'redux/API';

export interface State {
  readonly progress: number | null;
  readonly status: Status;
  readonly fileId: string | undefined;
  readonly file: File | null;
  readonly fileName: string;
  readonly fileSize: number;
  readonly fileUrl: string;
  readonly preview: boolean;
}

export class State {
  public static initial(): State {
    return {
      progress: null,
      status: Status.none,
      fileId: '',
      file: null,
      fileName: '',
      fileSize: 0,
      preview: false,
      fileUrl: '',
    };
  }
}

export enum FileInputActionType {
  fileSelected = 'fileSelected',
  updateProgress = 'updateProgress',
  uploadingStarted = 'uploadingStarted',
  uploadingSucceeded = 'uploadingSucceeded',
  uploadingFailed = 'uploadingFailed',
  preview = 'preview',
  closePreview = 'closePreview',
  initialize = 'initialize',
}

interface FileInputActionBase {
  readonly type: FileInputActionType;
}

interface FileInputActionFileSelected extends FileInputActionBase {
  readonly type: FileInputActionType.fileSelected;
  readonly data: File;
}

interface FileInputActionUpdateProgress extends FileInputActionBase {
  readonly type: FileInputActionType.updateProgress;
  readonly data: number;
}

interface FileInputActionStartUploading extends FileInputActionBase {
  readonly type: FileInputActionType.uploadingStarted;
}

interface FileInputActionUploadingSucceeded extends FileInputActionBase {
  readonly type: FileInputActionType.uploadingSucceeded;
  readonly data: string;
}

interface FileInputActionUploadingFailed extends FileInputActionBase {
  readonly type: FileInputActionType.uploadingFailed;
}

interface FileInputActionInitialize extends FileInputActionBase {
  readonly type: FileInputActionType.initialize;
  readonly data: FileMetadata;
}

interface FileInputActionPreview extends FileInputActionBase {
  readonly type: FileInputActionType.preview;
}

interface FileInputActionClosePreview extends FileInputActionBase {
  readonly type: FileInputActionType.closePreview;
}

export type FileInputAction =
  | FileInputActionFileSelected
  | FileInputActionUpdateProgress
  | FileInputActionStartUploading
  | FileInputActionUploadingSucceeded
  | FileInputActionUploadingFailed
  | FileInputActionInitialize
  | FileInputActionPreview
  | FileInputActionClosePreview;

export const reducer = (state: State, action: FileInputAction): State => {
  switch (action.type) {
    case FileInputActionType.fileSelected:
      const file = action.data;

      return {
        ...state,
        file: file,
        fileName: action.data.name,
        fileSize: file.size,
        fileUrl: URL.createObjectURL(file),
      };
    case FileInputActionType.updateProgress:
      return { ...state, progress: action.data };
    case FileInputActionType.uploadingStarted:
      return { ...state, progress: 0, status: Status.uploading, fileId: '' };
    case FileInputActionType.uploadingSucceeded:
      return { ...state, progress: null, status: Status.success, fileId: action.data };
    case FileInputActionType.uploadingFailed:
      return { ...state, progress: null, file: null, status: Status.error, fileId: '' };
    case FileInputActionType.closePreview:
      return { ...state, preview: false };
    case FileInputActionType.preview:
      return { ...state, preview: true };
    case FileInputActionType.initialize:
      const { id, nombre, magnitud, url } = action.data;

      return {
        ...state,
        progress: null,
        status: Status.success,
        fileId: id,
        fileName: nombre,
        fileSize: magnitud,
        fileUrl: `${BASE_URL}${url}`,
      };
    default:
      return state;
  }
};
