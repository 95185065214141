import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { OpenStreetMapSearchResultItem } from 'types/openStreetMapSearchResultItem';

export interface GeoQuery {
  readonly state: string;
  readonly county?: string;
  readonly city?: string;
}

const openStreetMap = createApi({
  reducerPath: 'api/openstreetmap',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://nominatim.openstreetmap.org',
  }),
  endpoints: builder => ({
    search: builder.query<readonly OpenStreetMapSearchResultItem[], GeoQuery>({
      query: (parameters: GeoQuery): string => {
        const query = Object.entries(parameters)
          .filter(([, value]: [string, any]): boolean => !!value)
          .map(([key, value]: [string, any]): string => `${key}=${encodeURIComponent(value)}`)
          .join('&');

        return `/search?${query}&format=json&countrycodes=VE`;
      },
    }),
  }),
});

export default openStreetMap;

export const { useLazySearchQuery } = openStreetMap;
