import * as yup from 'yup';

// FIXME: puede ser cédula
export function validarIdentificador<T>(
  this: yup.TestContext<T>,
  value: string | undefined,
): yup.ValidationError | boolean {
  if (value !== undefined) {
    if (value.length !== 10) {
      return this.createError({
        message: 'Longitud incorrecta, ¿faltan dígitos?',
        path: this.path,
      });
    } else if (!isChecksumValid(value)) {
      return this.createError({
        message: 'Dígito verificador incorrecto',
        path: this.path,
      });
    } else {
      return true;
    }
  } else {
    return false;
  }
}

const isChecksumValid = (value: string): boolean => {
  const documentType = value[0];
  const digits = Array.from(value.slice(1, -1)).map(Number);
  const checkDigit = Number(value[value.length - 1]);

  const initialWeight = variableWeight[documentType as keyof typeof variableWeight];
  const reducer = (computedChecksum: number, digit: number, index: number): number =>
    computedChecksum + digit * digitPositionWeight[index];
  const computedCheckDigit = 11 - (digits.reduce(reducer, initialWeight) % 11);

  if (computedCheckDigit === 11) {
    return checkDigit === 0;
  } else {
    return checkDigit === computedCheckDigit;
  }
};

const variableWeight = { V: 4, E: 8, J: 12, G: 20 };
const digitPositionWeight = [3, 2, 7, 6, 5, 4, 3, 2];
