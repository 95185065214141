import {
  Action,
  ActionType,
  MapController,
  MapControllerContext,
  MapControllerState,
} from 'context/map';
import React, { Reducer } from 'react';

const initialState: MapControllerState = {
  boundingBox: [0.724452215982, 12.1623070337, -73.3049515449, -59.7582848782],
  center: [8.0, -66.0],
};

export const defaultCenter = [12.1623070337, -73.3049515449];

const reducer = (state: MapControllerState, action: Action): MapControllerState => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.setCenter:
      return { ...state, center: payload };
    case ActionType.setBoundingBox:
      return { ...state, boundingBox: payload };
    default:
      return state;
  }
};

export const useMapControllerProvider = (): [MapControllerState, MapController] => {
  const [state, dispatch] = React.useReducer<Reducer<MapControllerState, Action>>(
    reducer,
    initialState,
  );

  return [state, React.useMemo((): MapController => new MapController(dispatch), [])];
};

export const useMapController = (): MapController => {
  const context = React.useContext<MapController | null>(MapControllerContext);
  if (context === null) {
    throw new Error('There is no map controller context provided to the parent tree');
  }

  return context;
};
