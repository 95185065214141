import { Menu, Notifications, Settings } from '@mui/icons-material';
import { Alert, Box, Button, ButtonBase, Dialog, SxProps, Theme, Typography } from '@mui/material';
import { DocumentType } from '@s3comsecurity/foundations';
import { User } from '@s3comsecurity/user-auth';
import ApplicationMenu from 'components/ApplicationMenu';
import CompleteRegistration from 'components/CompleteRegistration';
import Disable from 'components/Disable';
import Headband from 'components/Headband';
import Render from 'components/Render';
import UserMenu from 'components/UserMenu';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUserQuery } from 'redux/API';
import { logout } from 'redux/authenticationReducer';
import { styles } from 'routes/protected/styles';
import { StorageKey } from 'storageKeys';

const Layout: React.FC = (): React.ReactElement => {
  const [menuIsVisible, setMenuIsVisible] = React.useState(false);
  const [completeRegistrationIsOpen, setCompleteRegistrationIsOpen] = React.useState(false);
  const { data: user = emptyUser, isLoading, error } = useUserQuery();
  const { registrado = false, tipoDocumento } = user;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = React.useCallback((): void => {
    sessionStorage.removeItem(StorageKey.accessToken);

    navigate('/');
    dispatch(logout());
  }, [dispatch, navigate]);

  React.useEffect((): void => {
    if (error && 'status' in error && error.status === 401) {
      handleLogout();
    }
  }, [dispatch, error, handleLogout]);

  const hideMenu = React.useCallback((): void => {
    if (!menuIsVisible) {
      return;
    }

    setMenuIsVisible(false);
  }, [menuIsVisible]);

  const closeModal = React.useCallback((): void => {
    setCompleteRegistrationIsOpen(false);
  }, []);

  const stopPropagation = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.stopPropagation();
    },
    [],
  );

  const completeRegistration = React.useCallback((): void => {
    setCompleteRegistrationIsOpen(true);
  }, []);

  const toggleMenuVisibility = React.useCallback((): void => {
    setMenuIsVisible((previousIsVisible: boolean): boolean => !previousIsVisible);
  }, []);

  const leftMenuStyle = React.useMemo((): SxProps<Theme> => {
    if (menuIsVisible) {
      return { ...styles.leftMenu, left: 0 };
    }

    return styles.leftMenu;
  }, [menuIsVisible]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Box sx={styles.container} onClick={hideMenu}>
      <Box sx={styles.headBand}>
        <Headband />
      </Box>

      <Disable when={!registrado}>
        <Box sx={leftMenuStyle} onClick={stopPropagation}>
          <ApplicationMenu />
        </Box>
      </Disable>

      <Box sx={styles.content}>
        <Box sx={styles.outletContainer}>
          <Box sx={styles.toolbar}>
            <Render when={!registrado}>
              <Box sx={styles.alertBox}>
                <Alert
                  severity="error"
                  action={
                    <Button
                      variant="contained"
                      disableElevation={true}
                      color="error"
                      size="small"
                      onClick={completeRegistration}
                    >
                      Completar Registro
                    </Button>
                  }
                >
                  <Typography component="span" fontWeight={600}>
                    Hemos detectado que no ha completado su registro
                  </Typography>
                </Alert>
              </Box>
            </Render>
            <Box>
              <ButtonBase sx={styles.menuButton} onClick={toggleMenuVisibility}>
                <Menu />
              </ButtonBase>
            </Box>
            <Box marginLeft="auto">
              <ButtonBase sx={styles.toolButton} disabled>
                <Settings />
              </ButtonBase>
            </Box>
            <Box>
              <ButtonBase sx={styles.toolButton} disabled>
                <Notifications />
              </ButtonBase>
            </Box>
            <UserMenu user={user} onLogout={handleLogout} />
          </Box>
          <Box sx={styles.outlet}>
            <Outlet />
          </Box>
        </Box>
      </Box>

      {/* FIXME: colocar esto en el lugar correcto */}
      <Dialog open={completeRegistrationIsOpen} maxWidth="xl">
        <CompleteRegistration documentType={tipoDocumento} onClose={closeModal} />
      </Dialog>
    </Box>
  );
};

export default Layout;

const emptyUser: User = {
  email: '',
  nombre: '',
  rif: '',
  registrado: false,
  tipoDocumento: DocumentType.legal,
};
