import EnumerableSelect from 'components/EnumerableSelect';
import { DropdownFieldWithFetchHook } from 'components/Form/types';
import React from 'react';

interface Props<T> {
  readonly field: DropdownFieldWithFetchHook<T>;

  onChange?(value: number): void;
}

function DropdownFieldWithFetchHookRenderer<T>(props: Props<T>): React.ReactElement {
  const { field, onChange } = props;
  const { data, isFetching } = field.useQuery();

  return (
    <EnumerableSelect
      name={String(field.name)}
      items={data}
      loading={isFetching}
      onChange={onChange}
    />
  );
}

export default DropdownFieldWithFetchHookRenderer;
