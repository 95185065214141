import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { ControlData } from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import React from 'react';

interface Props {
  readonly name: string;
  readonly items: readonly string[];
  readonly data?: ControlData;
  readonly defaultValue?: string;
}

const SelectorOneOf: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, items, data, defaultValue = items?.[0] ?? '' } = props;

  return (
    <RadioGroup key={name + defaultValue} name={name} defaultValue={defaultValue}>
      <Grid mt={2} container>
        {items.map(
          (item: string): React.ReactElement => (
            <Grid key={item} xs={6} item>
              <FormControlLabel control={<Radio value={item} inputProps={data} />} label={item} />
            </Grid>
          ),
        )}
      </Grid>
    </RadioGroup>
  );
};

export default SelectorOneOf;
