import { numericTransform } from 'helpers/numericTransform.yup';
import * as yup from 'yup';

export interface SolicitanteNaturalDatos {
  readonly primerNombre: string;
  readonly segundoNombre: string;
  readonly primerApellido: string;
  readonly segundoApellido: string;
  readonly idProfesion: number;
  readonly idEstadoCivil: number;
  readonly sexo: string;
  readonly fechaNacimiento: string;
}

export default yup.object<SolicitanteNaturalDatos>().shape({
  primerNombre: yup
    .string() //
    .required('Primer nombre es obligatorio'),
  primerApellido: yup
    .string() //
    .required('Primer apellido es obligatorio'),
  segundoApellido: yup
    .string() //
    .required('Segundo apellido es obligatorio'),
  idProfesion: yup
    .number() //
    .transform(numericTransform)
    .required('Debe seleccionar una profesión'),
  idEstadoCivil: yup
    .number() //
    .transform(numericTransform)
    .required('Debe seleccionar una profesión'),
  sexo: yup
    .string() //
    .oneOf(['M', 'F'], 'Debe seleccionar un sexo'),
  fechaNacimiento: yup
    .string() //
    .required('Fecha de Registro es obligatoria'),
});
