import { DocumentType } from '@s3comsecurity/foundations';
import {
  LegalRequesterBasicInformation,
  RequesterAddressWithCoordinates,
} from '@s3comsecurity/requests';
import {
  UpdateLegalRequesterRequest,
  UpdateLegalRequesterRequestDocuments,
  UpdateLegalRequesterRequestLegalRepresentative,
  UpdateRequesterRequestPhoneNumber,
} from '@s3comsecurity/user-accounts';
import { SolicitanteJuridicoDatos } from 'components/CompleteRegistration/forms/PasosComunes/JuridicoDatosBasicos/schema';
import { SolicitanteJuridicoObjeto } from 'components/CompleteRegistration/forms/PasosComunes/JuridicoObjetos/schema';
import { SolicitanteJuridicoRepresentanteLegal } from 'components/CompleteRegistration/forms/PasosComunes/JuridicoRepresentanteLegal/schema';
import { SolicitanteDireccionForm } from 'components/CompleteRegistration/forms/PasosComunes/SolicitanteDireccion/schema';

export interface JuridicoForm {
  readonly datos: LegalRequesterBasicInformation;
  readonly representanteLegal: UpdateLegalRequesterRequestLegalRepresentative;
  // FIXME: find a more elegant way to do this (maybe directly in the protobufs)
  readonly objetos: SolicitanteJuridicoObjeto | readonly SolicitanteJuridicoObjeto[];
  readonly direccion: RequesterAddressWithCoordinates;
  // FIXME: find a more elegant way to do this (maybe directly in the protobufs)
  readonly telefonos: UpdateRequesterRequestPhoneNumber;
  readonly documentos: UpdateLegalRequesterRequestDocuments;
}

export class JuridicoPayload {
  public static fromForm(request: JuridicoForm): UpdateLegalRequesterRequest {
    const { datos, representanteLegal, objetos, direccion, telefonos, documentos } = request;

    return {
      tipoDocumento: DocumentType.legal,
      datos: datos,
      representanteLegal: representanteLegal,
      direccion: direccion,
      telefonos: Array.isArray(telefonos) ? telefonos : [telefonos],
      objetos: Array.isArray(objetos) ? objetos : [objetos],
      documentos: documentos,
    };
  }
}

export interface JuridicoPayload {
  readonly datos: SolicitanteJuridicoDatos;
  readonly representanteLegal: SolicitanteJuridicoRepresentanteLegal;
  readonly direccion: SolicitanteDireccionForm<number>;
  readonly telefonos: readonly string[];
  readonly objetos: readonly SolicitanteJuridicoObjeto[];
}
