export const prettySize = (size: number): string => {
  return formatter.format(size);
};

const formatter = new Intl.NumberFormat(undefined, {
  style: 'unit',
  notation: 'compact',
  unitDisplay: 'narrow',
  unit: 'byte',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
