import {
  GetAllRequestsRequest,
  GetProcedureRequestsRequest,
  GetServiceRequestsRequest,
} from '@s3comsecurity/user-requests';
import { useTypedParams } from 'hooks/useTypedParams';
import { ProcedureGroupParameters } from 'parameters/service';
import React from 'react';

export const useRequestsQuery = ():
  | GetAllRequestsRequest
  | GetServiceRequestsRequest
  | GetProcedureRequestsRequest => {
  const { serviceId, procedureId } = useTypedParams<Partial<ProcedureGroupParameters>>(
    ProcedureGroupParameters.mayBeFromUnknown,
  );

  return React.useMemo(():
    | GetAllRequestsRequest
    | GetServiceRequestsRequest
    | GetProcedureRequestsRequest => {
    return {
      idGrupoTramites: serviceId,
      idTramite: procedureId,
      pagina: 1,
      totalPagina: 10,
    };
  }, [procedureId, serviceId]);
};
