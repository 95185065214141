import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { AttributeType, Enumerable } from '@s3comsecurity/foundations';
import React from 'react';
import { Fn } from 'types/fn';
import { createSelectIconComponent } from 'utils/selectLoadingIcon';

interface Props {
  readonly items: readonly Enumerable[] | undefined;
  readonly name?: string;
  readonly loading?: boolean;
  readonly value?: number;

  onChange?(value: number): void;
}

const EnumerableSelect: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, items, loading = false, value } = props;
  const { onChange } = props;

  const IconComponent = React.useMemo(
    (): React.ElementType | undefined => createSelectIconComponent(loading),
    [loading],
  );

  const handleChange = React.useMemo((): Fn<SelectChangeEvent<number>, void> | undefined => {
    if (onChange) {
      return (event: SelectChangeEvent<number>): void => {
        const { value } = event.target;
        if (typeof value === 'string') {
          return;
        }

        onChange(value);
      };
    }

    return undefined;
  }, [onChange]);

  const renderValue = React.useCallback(
    (value: any): React.ReactNode => {
      const item = items?.find((item: Enumerable): boolean => item.id === value);
      const label = item?.nombre;

      if (loading) {
        return (
          <Typography color="text.disabled" fontStyle="italic">
            Cargando&hellip;
          </Typography>
        );
      } else if (!label) {
        return (
          <Typography color="text.disabled" fontStyle="italic">
            Seleccionar una opción
          </Typography>
        );
      }

      return <Typography>{label}</Typography>;
    },
    [items, loading],
  );

  const validValue = React.useMemo((): number | '' | undefined => {
    if (items === null || items === undefined || items.length === 0) {
      return '';
    }

    return value === 0 || value === undefined ? '' : (value ?? '');
  }, [items, value]);

  if (onChange !== undefined) {
    return (
      <Select
        name={name}
        inputProps={inputProps}
        displayEmpty={true}
        renderValue={renderValue}
        IconComponent={IconComponent}
        fullWidth={true}
        value={validValue}
        onChange={handleChange}
      >
        {items?.map(toMenuItem)}
      </Select>
    );
  } else {
    return (
      <Select
        name={name}
        inputProps={inputProps}
        displayEmpty={true}
        defaultValue=""
        renderValue={renderValue}
        IconComponent={IconComponent}
        fullWidth={true}
      >
        {items?.map(toMenuItem)}
      </Select>
    );
  }
};

export default EnumerableSelect;

const inputProps = {
  'data-type': AttributeType.numeric,
};

const toMenuItem = (enumerable: Enumerable): React.ReactElement => {
  return (
    <MenuItem key={enumerable.id} value={enumerable.id}>
      {enumerable.nombre}
    </MenuItem>
  );
};
