import { AttributeType } from '@s3comsecurity/foundations';
import { FieldError } from 'types/fieldError';
import * as yup from 'yup';

export const errorsReducer = <T>(
  errors: Partial<Record<keyof T, FieldError>> | FieldError,
  error: yup.ValidationError,
): Partial<Record<keyof T, FieldError>> | FieldError => {
  if (!error.path) {
    return {
      message: error.message,
    };
  }

  return {
    [error.path]: {
      message: error.message,
    },
    ...errors,
  };
};

const isInputElement = (element: Element): element is HTMLInputElement =>
  element.tagName === 'INPUT';

export const formToObject = <T>(form: HTMLFormElement | HTMLFieldSetElement): T => {
  const inputs = Array.from(form.querySelectorAll('input'));

  const data = {
    ...inputs //
      .filter(isInputElement)
      .reduce(inputReducer, {}),
  } as T;

  return group(unpack(data));
};

const group = (object: any): any => {
  const entries = Object.entries(object);
  const values = Object.values(object);
  if (values.every(Array.isArray)) {
    const length = values[0]?.length ?? 0;
    if (!values.every((array: any[]): boolean => array.length === length)) {
      throw new Error('mismatched element count');
    }
    const target = new Array(length) //
      .fill({});

    // Need to convert
    return entries.reduce((result: any[], [key, value]: [string, any]): any => {
      value.forEach((item: any, index: number): any => {
        result[index] = { ...result[index], [key]: item };
      });

      return result;
    }, target);
  } else {
    return Object.fromEntries(
      entries.map(([key, value]: [string, any]): [string, any] => {
        if (typeof value === 'object') {
          return [key, group(value)];
        } else {
          return [key, value];
        }
      }),
    );
  }
};

const unpack = (data: any): any => {
  const entries = Object.entries(data);

  return entries.reduce((object: any, [key, value]: [string, any]): any => {
    if (!key.includes('.')) {
      return { ...object, [key]: value };
    }
    const [first, ...left] = key.split('.');
    const next = left.join('.');

    return { ...object, [first]: { ...object[first], ...unpack({ [next]: value }) } };
  }, {});
};

const toTypedValue = (
  input: HTMLInputElement,
): string | number | Record<string, string | number> | undefined => {
  const dataType = input.getAttribute('data-type') ?? AttributeType.text;
  const { value } = input;

  switch (dataType as AttributeType) {
    case AttributeType.file:
      if (!value || value.trim() === '') {
        return undefined;
      }

      return value;
    case AttributeType.bool:
    case AttributeType.text:
    case AttributeType.date:
    case AttributeType.list:
    case AttributeType.remoteList:
    case AttributeType.entityId:
    case AttributeType.personId:
    case AttributeType.phoneNumber:
    case AttributeType.oneOf:
    case AttributeType.severalOf:
      return value;
    case AttributeType.numeric:
      return Number(value);
    case AttributeType.object:
      return JSON.parse(value);
    default:
      console.error(input, 'has unknown data type:', dataType);
      throw new Error(`unknown data type: ${dataType}`);
  }
};

const inputReducer = <T>(data: Partial<T>, input: HTMLInputElement): Partial<T> => {
  const { name } = input;
  const key = name as keyof T;
  const currentValue = data[key];

  if (key === '') {
    return data;
  }
  const typedValue = toTypedValue(input);

  if (Array.isArray(currentValue)) {
    return { ...data, [key]: [...currentValue, typedValue] };
  } else if (!!currentValue) {
    return { ...data, [key]: [currentValue, typedValue] };
  } else {
    return { ...data, [key]: typedValue };
  }
};
