import React from 'react';

interface Props {
  readonly when: boolean;
}

const Disable: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const { when, children } = props;
  if (!React.isValidElement(children)) {
    throw new Error('Disable component must have a valid React element as child');
  }

  if (when) {
    return React.cloneElement<any>(children, {
      style: {
        ...children.props.style,
        pointerEvents: 'none',
        opacity: 0.5,
      },
    });
  } else {
    return children;
  }
};

export default Disable;
