import React from 'react';

interface Props {
  readonly when: boolean;
}

const Render: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const { when, children } = props;

  if (when) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};

export default Render;
