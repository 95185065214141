import Loading from 'components/Loading';
import ProcedureLayout from 'components/RequestLayout';
import NewRequest from 'components/RequestLayout/components/NewRequest';
import RequestsOverview from 'components/RequestLayout/components/RequestsOverview';
import ServicesMenu from 'components/ServicesMenu';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from 'routes/protected/Dashboard';
import Layout from 'routes/protected/Layout';

const ProtectedRoutes: React.FC = (): React.ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Dashboard />} />
        <Route path="tramites/:serviceId/*">
          <Route path=":procedureId/*" element={<ProcedureLayout />}>
            <Route path="" element={<RequestsOverview />} />
            <Route path=":requestId/:stepId?/*" element={<NewRequest />} />
          </Route>
          <Route element={<ServicesMenu />} index />
        </Route>
        <Route path="ayuda" element={null} />
      </Route>
      <Route path="*" element={<Loading />} />
    </Routes>
  );
};

export default ProtectedRoutes;
