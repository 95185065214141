import { Box } from '@mui/material';
import React from 'react';

interface Props {
  readonly status: 'passed' | 'active' | 'pending';
}

const StepIcon: React.FC<Props> = (props: Props): React.ReactElement => {
  const { status } = props;

  const color = React.useMemo((): string => {
    switch (status) {
      case 'passed':
      case 'active':
        return 'primary.main';
      case 'pending':
        return 'secondary.dark';
    }
  }, [status]);

  return <Box height={2} flex={1} mx="-75px" mt="21px" bgcolor={color} />;
};

export default StepIcon;
