import React, { Dispatch } from 'react';

export enum ActionType {
  setCenter,
  setBoundingBox,
}

interface ActionBase {
  readonly type: ActionType;
  readonly payload?: any;
}

export interface SetCenterAction extends ActionBase {
  readonly type: ActionType.setCenter;
  readonly payload: [number, number];
}

type BoundingBox = [number, number, number, number];

export interface SetBoundingBoxAction extends ActionBase {
  readonly type: ActionType.setBoundingBox;
  readonly payload: BoundingBox;
}

export type Action = SetCenterAction | SetBoundingBoxAction;

export interface MapControllerState {
  readonly boundingBox: [number, number, number, number];
  readonly center: [number, number];
}

interface Props {
  readonly controller: MapController;
}

export class MapController {
  private readonly dispatch: Dispatch<Action>;

  constructor(dispatch: Dispatch<Action>) {
    this.dispatch = dispatch;
  }

  public setBoundingBox(boundingBox: readonly string[]): void {
    if (boundingBox.length !== 4) {
      console.warn('Tried to pass a bounding box that does not have 4 elements');
      return;
    }

    const numeric = boundingBox.map((bound: string): number => Number(bound)) as BoundingBox;

    this.dispatch({
      type: ActionType.setBoundingBox,
      payload: numeric,
    });
  }
}

export const MapControllerContext = React.createContext<MapController | null>(null);

export const MapControllerProvider: React.FC<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>,
): React.ReactElement => {
  const { controller, children } = props;

  return (
    <MapControllerContext.Provider value={controller}>{children}</MapControllerContext.Provider>
  );
};
