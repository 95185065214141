import { numericTransform } from 'helpers/numericTransform.yup';
import * as yup from 'yup';

export interface Coordenadas<TipoCoordenada> {
  readonly latitud: TipoCoordenada;
  readonly longitud: TipoCoordenada;
}

export interface SolicitanteDireccionForm<TipoCoordenada = string> {
  readonly idParroquia: number;
  readonly idLocalidad: number;
  readonly nombreLocalidad: string;
  readonly idInmueble: number;
  readonly nombreInmueble: string;
  readonly puntoReferencia: string;
  readonly coordenadas?: Coordenadas<TipoCoordenada>;
}

const coordinatesSchema: yup.Schema = yup
  .object<Coordenadas<string>>() //
  .shape({
    latitud: yup //
      .string(),
    longitud: yup //
      .string(),
  })
  .test(
    'coordenadas',
    function (
      this: yup.TestContext<Partial<Coordenadas<string>> | undefined>,
      value: Partial<Coordenadas<string>> | undefined,
    ): yup.ValidationError | boolean {
      if (!value) {
        return this.createError({
          message: 'Debe seleccionar un punto en el mapa',
        });
      }

      if ((!value.latitud || value.latitud === '') && (!value.longitud || value.longitud === '')) {
        return this.createError({
          message: 'Debe seleccionar un punto en el mapa',
        });
      }

      const latitud = Number(value.latitud);
      const longitud = Number(value.longitud);
      if (isNaN(latitud) || isNaN(longitud)) {
        return this.createError({
          message: 'Formato inválido para las coordenadas',
        });
      }

      return true;
    },
  );

export const createSchema = (withCoordinates?: boolean): yup.Schema =>
  yup.object<SolicitanteDireccionForm>().shape({
    ubicacion: yup.object().shape({
      parroquia: yup.number().transform(numericTransform),
      ciudad: yup.number().transform(numericTransform),
    }),
    idLocalidad: yup
      .number() //
      .transform(numericTransform)
      .required('Debe seleccionar un tipo de localidad'),
    nombreLocalidad: yup
      .string() //
      .required('Describa la localidad'),
    idInmueble: yup
      .number() //
      .transform(numericTransform)
      .required('Debe seleccionar un tipo de inmueble'),
    nombreInmueble: yup
      .string() //
      .required('Describa el inmueble'),
    ...(withCoordinates ? { coordenadas: coordinatesSchema } : {}),
  });
