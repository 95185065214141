import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import ModalContainer from 'components/ModalContainer';
import React from 'react';

interface Props {
  readonly title: string;
  readonly question: string;
  readonly danger?: boolean;

  onApproved(): void;
  onRejected(): void;
}

const Confirm: React.FC<Props> = (props: Props): React.ReactElement => {
  const { title, question, danger = false, onApproved, onRejected } = props;

  const confirmColor = React.useMemo(
    (): 'error' | 'primary' => (danger ? 'error' : 'primary'),
    [danger],
  );

  return (
    <ModalContainer
      title={title}
      footer={
        <Box sx={styles.buttons}>
          <Button variant="outlined" color="secondary" onClick={onRejected}>
            No
          </Button>
          <Button variant="contained" color={confirmColor} onClick={onApproved}>
            S&iacute;
          </Button>
        </Box>
      }
      onClose={onRejected}
    >
      <Box mb={2}>
        <Typography>¿{question}?</Typography>
      </Box>
    </ModalContainer>
  );
};

export default Confirm;

const styles: Record<string, SxProps<Theme>> = {
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: 'background.paper',
    padding: 2,
    gap: 1,
  },
};
