import { Box, Dialog, Grid, Typography } from '@mui/material';
import FieldLabel from 'components/Form/components/FieldLabel';
import FieldRenderer from 'components/Form/components/FieldRenderer';
import { Attribute, AttributeType } from 'components/Form/types';
import FormError from 'components/FormError';
import ModalContainer from 'components/ModalContainer';
import { useFormErrors } from 'hooks/useFormErrors';
import React from 'react';

interface Props<T> {
  readonly fields: readonly Attribute<T>[];

  readonly defaultValue?: T;
}

function Fieldset<T>(props: Props<T>): React.ReactElement {
  const errors = useFormErrors();

  const { defaultValue, fields } = props;
  const [currentHelp, setCurrentHelp] = React.useState<string | null>(null);

  const handleHideHelp = React.useCallback((): void => {
    setCurrentHelp(null);
  }, []);

  const handleShowHelp = React.useCallback((help: string): void => {
    setCurrentHelp(help);
  }, []);

  return (
    <Box mx={-0.75} my={-1}>
      <Grid container>
        {fields.map((field: Attribute<T>): React.ReactElement => {
          const { columnSpan = 1 } = field;
          const xs = 6 * columnSpan;
          const sm = 12 * columnSpan;

          return (
            <Grid
              sx={itemStyle}
              key={String(field.name)}
              xs={field.type === AttributeType.custom ? sm : xs}
              item
            >
              <FieldLabel field={field} />
              <FieldRenderer
                field={field}
                defaultValue={defaultValue?.[field.name]}
                onHelpRequested={handleShowHelp}
              />
              <FormError error={errors[field.name]} />
            </Grid>
          );
        })}
      </Grid>
      <Dialog open={currentHelp !== null}>
        <ModalContainer title="Ayuda" onClose={handleHideHelp}>
          <Typography>{currentHelp}</Typography>
        </ModalContainer>
      </Dialog>
    </Box>
  );
}

export default Fieldset;

const itemStyle = {
  px: 0.75,
  py: 0.5,
};
