import { Box, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { ControlData } from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import React from 'react';
import { isAcceptableNumericKey } from 'utils/isAcceptableNumericKey';
import { noop } from 'utils/noop';

interface Props {
  readonly name: string;
  readonly autoFocus?: boolean;
  readonly defaultValue?: string;
  readonly help?: string;
  readonly autoComplete?: string;

  readonly variant?: 'cedula' | 'rif';
  readonly data?: ControlData;
}

interface RIF {
  readonly type: string;
  readonly text: string;
}

const RIFInput: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, autoFocus = false, defaultValue, variant = 'rif', data, autoComplete } = props;

  const parsed = parse(defaultValue);

  const [digitsInput, setDigitsInput] = React.useState<HTMLInputElement | null>(null);
  const [valueInput, setValueInput] = React.useState<HTMLInputElement | null>(null);
  const [documentType, setDocumentType] = React.useState<string>(parsed.type ?? 'V');

  const updateValueInput = React.useCallback((): void => {
    if (digitsInput === null || valueInput === null) {
      return;
    } else {
      const { value: digits } = digitsInput;
      if (digits.trim() === '') {
        valueInput.value = '';
      } else {
        valueInput.value = `${documentType}${digits.replace('-', '')}`;
      }
      // Manually dispatch change event
      valueInput.dispatchEvent(new Event('input', { bubbles: true, cancelable: false }));
    }
  }, [digitsInput, documentType, valueInput]);

  React.useEffect(updateValueInput, [updateValueInput, documentType]);

  const updateDocumentType = React.useCallback((event: SelectChangeEvent): void => {
    const { target } = event;
    setDocumentType(target.value);
  }, []);

  const preventNonNumericCharacters = React.useCallback((event: React.KeyboardEvent): void => {
    if (event.altKey || event.ctrlKey) {
      return;
    }

    if (!isAcceptableNumericKey(event.key)) {
      event.preventDefault();
    }
  }, []);

  const updateDigits = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target } = event;
      const { value } = target;

      const restore = save(target, '-');
      const clean = value.replace('-', '');
      if (clean.length > 8) {
        target.value = `${clean.slice(0, 8)}-${clean.slice(8, 9)}`;
        restore();
      } else {
        target.value = clean;
        restore();
      }

      updateValueInput();
    },
    [updateValueInput],
  );

  const tiposDocumento = React.useMemo((): readonly string[] => {
    if (variant === 'rif') {
      return ['V', 'E', 'P', 'J', 'G'];
    } else {
      return ['V', 'E', 'P'];
    }
  }, [variant]);

  return (
    <Box display="flex" position="relative" gap={1}>
      <input
        name={name}
        autoComplete={autoComplete}
        ref={setValueInput}
        type="hidden"
        aria-hidden={true}
        {...data}
      />

      <Select sx={dropdownStyle} value={documentType} onChange={updateDocumentType}>
        {tiposDocumento.map(
          (tipo: string): React.ReactElement => (
            <MenuItem key={tipo} value={tipo}>
              {tipo}
            </MenuItem>
          ),
        )}
      </Select>
      <OutlinedInput
        autoComplete="identificador"
        placeholder={'ej. 01234567-8'}
        inputRef={setDigitsInput}
        autoFocus={autoFocus}
        fullWidth={true}
        defaultValue={parsed.text}
        onKeyDown={preventNonNumericCharacters}
        onChange={updateDigits}
      />
    </Box>
  );
};

export default RIFInput;

const dropdownStyle = {
  maxWidth: 60,
  minWidth: 60,
};

const save = (target: HTMLInputElement, extraCharacter: string): VoidFunction => {
  const { value, selectionStart } = target;

  const caretPosition = selectionStart;
  if (caretPosition === null) {
    return noop;
  }

  return (): void => {
    const { value: current } = target;
    const previousChunk = value.slice(0, caretPosition);
    const currentChunk = current.slice(0, caretPosition);
    const computedPosition = caretPosition + (currentChunk.length - previousChunk.length);

    if (current[computedPosition - 1] === extraCharacter) {
      target.setSelectionRange(computedPosition + 1, computedPosition + 1);
    } else {
      target.setSelectionRange(computedPosition, computedPosition);
    }
  };
};

const parse = (rif: string | undefined): RIF => {
  if (rif) {
    return {
      type: rif.slice(0, 1),
      text: `${rif.slice(1, 9)}-${rif.slice(-1)}`,
    };
  } else {
    return {
      type: 'V',
      text: '',
    };
  }
};
