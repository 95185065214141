import * as yup from 'yup';
import { TestContext, ValidationError } from 'yup';

export interface SolicitanteJuridicoObjetoForm {
  readonly objetos: SolicitanteJuridicoObjeto | readonly SolicitanteJuridicoObjeto[];
}

export interface SolicitanteJuridicoObjeto {
  readonly id: number;
}

export default yup.mixed().test(function (
  this: TestContext<any>,
  value: any,
): ValidationError | boolean {
  const itemSchema = yup.object().shape({
    id: yup.string().required(),
  });

  if (Array.isArray(value)) {
    if (value.length === 0) {
      return this.createError({
        path: 'objetos',
        message: 'Debe seleccionar al menos un objeto',
      });
    }
    for (const item of value) {
      if (!itemSchema.isValidSync(item)) {
        return this.createError({
          path: 'objetos',
          message: 'Esto no debe ocurrir, pero uno de los elementos es inválido',
        });
      }
    }

    return true;
  } else {
    if (!itemSchema.isValidSync(value)) {
      return this.createError({
        path: 'objetos',
        message: 'Esto no debe ocurrir, pero el elemento encontrado es inválido',
      });
    }

    return true;
  }
});
