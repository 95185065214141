import { Box, SxProps, Theme } from '@mui/material';
import schema, {
  SolicitanteJuridicoDocumentosForm,
} from 'components/CompleteRegistration/forms/PasosComunes/JuridicoDocumentos/schema';
import FileInput from 'components/FileInput';
import { MimeTypes } from 'components/FileInput/types';
import Form from 'components/Form';
import { StepProps } from 'components/Stepper/stepProps';
import React from 'react';
import { FieldError } from 'types/fieldError';

interface OwnProps {}

type Props = StepProps<OwnProps>;

const DocumentosJuridico: React.FC<Props> = (props: Props): React.ReactElement => {
  const [errors, setErrors] = React.useState<
    Partial<Record<keyof SolicitanteJuridicoDocumentosForm, FieldError>>
  >({});
  const { name, onSubmit } = props;

  const handleClearErrors = React.useCallback((): void => {
    setErrors({});
  }, []);

  const handleSubmission = React.useCallback(
    (data: SolicitanteJuridicoDocumentosForm): void => {
      onSubmit?.(name, data);
    },
    [name, onSubmit],
  );

  return (
    <Form<SolicitanteJuridicoDocumentosForm>
      id={name}
      schema={schema}
      errors={errors}
      autoComplete={false}
      onSubmit={handleSubmission}
      onClearErrors={handleClearErrors}
      onError={setErrors}
    >
      <Box width="100%">
        <FileInput
          label="Registro de Información Fiscal"
          name="rif"
          mimeTypes={[MimeTypes.pdf]}
          error={errors['rif'] ?? errors['rifFechaVencimiento']}
          expires={true}
          uploadPrefix="solicitanteJuridico.rif"
          onChange={handleClearErrors}
        />
        <Box sx={dividerStyle} />
        <FileInput
          label="Acta Constitutiva"
          name="acta"
          mimeTypes={[MimeTypes.pdf]}
          error={errors['acta'] ?? errors['actaFechaVencimiento']}
          expires={true}
          uploadPrefix="solicitanteJuridico.acta"
          onChange={handleClearErrors}
        />
      </Box>
    </Form>
  );
};

const dividerStyle: SxProps<Theme> = {
  width: '100%',
  height: '1px',
  color: 'secondary.main',
  borderBottom: '1px dashed',
  display: 'flex',
  alignItems: 'center',
  m: 1,
  p: 1,
};

export default DocumentosJuridico;
