import { Box, CircularProgress } from '@mui/material';
import { useCheckNonStaticParent } from 'hooks/useCheckNonStaticParent';
import React from 'react';

interface Props {
  readonly spinning: boolean;
  readonly size?: number;
  readonly thickness?: number;
  readonly color?: 'primary' | 'secondary' | 'inherit';
}

const Spinner: React.FC<Props> = (props: Props): React.ReactElement => {
  const { spinning, size = 32, thickness = 4, color = 'inherit' } = props;
  const ref = useCheckNonStaticParent();

  if (!spinning) {
    return <Box ref={ref} />;
  }

  return (
    <Box sx={spinnerStyle} ref={ref}>
      <CircularProgress size={size} thickness={thickness} color={color} />
    </Box>
  );
};

export default Spinner;

const spinnerStyle = {
  display: 'flex',
  position: 'absolute',
  inset: 0,
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(255, 255, 255, 0.75)',
  borderRadius: 1,
  pointerEvents: 'none',
};
