import { CalendarMonth } from '@mui/icons-material';
import { IconButton, OutlinedInput, Popover, PopoverOrigin } from '@mui/material';
import Calendar from 'components/Calendar';
import { ControlData } from 'components/RequestLayout/components/RequestComponentRenderer/selector';
import React, { useEffect, useState } from 'react';
interface BaseProps {
  readonly name?: string;
  readonly data?: ControlData;
}

interface ControlledProps extends BaseProps {
  readonly value: Date | null;
  readonly onChange: (value: Date) => void;
}

interface UncontrolledProps extends BaseProps {
  readonly defaultValue?: Date | null;
}

type Props = ControlledProps | UncontrolledProps;

const DateFieldInput: React.FC<Props> = (props: Props): React.ReactElement => {
  const { name, data } = props;

  const isComponentControlled = isControlled(props);
  const { defaultValue } = props as UncontrolledProps;

  const [open, setOpen] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(
    isComponentControlled ? (props.value ?? null) : (defaultValue ?? null),
  );

  useEffect(() => {
    if (isComponentControlled) {
      const controlledProps = props as ControlledProps;
      setDate(controlledProps.value);
    }
  }, [isComponentControlled, props]);

  const anchorRef = React.useRef<HTMLInputElement>(null);
  const { current: element } = anchorRef;

  const handleChange = (value: Date): void => {
    if (isComponentControlled && props.onChange) {
      props.onChange(value);
    } else {
      setDate(value);
    }
  };

  const closeCalendar = (): void => setOpen(false);

  const endAdornment = (
    <IconButton onClick={() => setOpen(!open)}>
      <CalendarMonth />
    </IconButton>
  );

  const valueAsString = formatValue(date);

  const size = element?.offsetWidth ?? 0;
  const slotProps = { paper: { sx: { width: size } } };
  const currentDate = date ?? new Date();

  return (
    <>
      {isUncontrolled(props) && <input type="hidden" name={name} value={valueAsString} {...data} />}
      <OutlinedInput
        ref={anchorRef}
        value={valueAsString}
        endAdornment={endAdornment}
        fullWidth={true}
      />
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        slotProps={slotProps}
        anchorOrigin={anchorOrigin}
        onClose={closeCalendar}
      >
        <Calendar date={currentDate} onChange={handleChange} />
      </Popover>
    </>
  );
};

export default DateFieldInput;

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const formatter = new Intl.DateTimeFormat('es-VE', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

const isControlled = (props: Props): props is ControlledProps => {
  return 'onChange' in props && props.onChange !== undefined;
};

const isUncontrolled = (props: Props): props is UncontrolledProps => {
  return 'defaultValue' in props;
};

const formatValue = (value: Date | null): string => {
  return value ? formatter.format(value) : '';
};
